import Dexie, { Table } from 'dexie'
import { store } from '@/store'
import { getCookieValue } from '@/helpers/tracking'

export type Statistics = {
  id?: number
  type: string
  uid: string
  ipaddress: string
  referrer: string
  userAgent: string
  meta: any
  timestamp: number
  cookieAllowLevel: string | null
}

export type RouterLog = {
  fullPath: string
  name: string
  params?: string
  query?: string
  isRekion: boolean
  pid?: string
  libraryName: string | null
  builtinLoginUserId?: string
  builtinLoginTypeCode?: string
  builtinLoginTypeName: string | null
  patronTypeCode?: string
  patronTypeName: string | null
  libraryTypeCode: string | null
  libraryTypeName: string | null
}

export type ActionLog = {
  pid: string
  uniquePid: string | null
  isRekion: boolean
  isViewable: boolean | null
  level?: string
  rightsCode?: string
  publishStatus?: string
  permissionRule?: string
  collectionsId: string[] | null
  collectionsName: string[] | null
  parentCollectionId: string | null
  parentCollectionName: string | null
  builtinLoginUserId?: string
  builtinLoginTypeCode?: string
  builtinLoginTypeName: string | null
  patronTypeCode?: string
  patronTypeName: string | null
  libraryTypeCode: string | null
  libraryTypeName: string | null
  libraryName: string | null
  title?: string
  volumeTitle?: string
  volume?: string
  creator?: string
  publisher?: string
  publicationYear?: string
  publicationIssue?: string
  publicationNumber?: string
  publicationName?: string
  publicationVolume?: string
  provider?: string
  ndcCategory?: string
  ndc8Category?: string
  ndc9Category?: string
  ndc10Category?: string
  bibliographicId?: string
  acquisitionBasis?: string
  itemDownloadCount: number
  pdfDownloadCount: number
  pdfBulkDownloadCount: number
  pictureDownloadCount: number
  accessCount: number
  copyCount: number
}

//
// Declare Database
//
class StatisticsDatabase extends Dexie {
  public statistics!: Table<Statistics, number>; // id is number in this case

  public constructor () {
    super('StatisticsDatabase')
    this.version(1).stores({
      statistics: '++id,type,uid,ipaddress,referrer,userAgent,meta,timestamp',
    })
  }
}

const db = new StatisticsDatabase()

class StatisticsLogService {
  public static findAll (): Promise<Statistics[]> {
    return db.statistics.orderBy('id').limit(50).toArray()
  }

  public static clean (id: number): Promise<number> {
    return db.statistics
      .where('id').belowOrEqual(id)
      .delete()
  }

  public static add (statistics: Statistics): Promise<number> {
    return db.transaction('rw', db.statistics, () => db.statistics.add({ ...statistics }))
  }

  public static async bulkAdd (statistics: Statistics[]): Promise<number[]> {
    const promises = statistics.map((log) => db.statistics.add({ ...log }))
    return Promise.all(promises)
  }
}

export { StatisticsLogService }

/**
 * ユーザが任意に入力した文字を含むクエリから、統計に含めるべきクエリだけを抽出する
 * @param routeQuery route.query
 * @returns アプリで想定しているものだけを含むquery
 */
const filterQuery = (routeQuery: any) => {
  if (!routeQuery) return null
  const queryFieldList = [
    'keyword', 'title', 'tableOfContents', 'creator', 'publisher', 'accessRestrictions', 'eraType', 'publicationFrom', 'publicationTo', 'ndlc', 'callNumber', 'identifierItem', 'identifier', 'includeVolumeNum', // 汎用的な項目
    'publicationPlace', 'ndc', 'subject', 'musicType', 'pid', 'productNumber', 'includeVolumeNum', 'provenance', 'series', 'subjectKanpoList', 'volumeSubtitle', 'partTitleKanpo', 'federalRegisterTypeList', 'volumeFederalRegisterItemType', 'volumeFederalRegister', 'partTitleKanpoNumber', 'subCollection', 'kotenSubject', 'classicMaterialTypeList', 'classicManuscriptionList', 'provider', // コレクションごとの項目1
    'publicationName', 'publicationVolume', 'bibliographicLevel', 'collectionEraType', 'collectionFrom', 'collectionTo', 'description', // コレクションごとの項目2
    'availableInternetFrom', 'availableInternetTo', 'availableFrom', 'availableTo', 'genre', 'bibId', 'releaseNumber', // コレクションごとの項目3
    'fromYear', 'fromMonth', 'fromDay', 'toYear', 'toMonth', 'toDay', // 日付の中間データ。念のため。
    'collection', 'pageNum', 'fullText', 'pageSize', 'sortKey', 'displayMode', 'order', // デフォルトのクエリ
    'image', 'excludes_pid_list', // 画像検索
    'tagId', 'keywords', // mycollection
    'authorId', 'page', 'size', 'sortOrder', 'status', // 外部提供者画面
    'cardId', 'password', // ログインツール
    'searchWord', 'contentNo', 'JP_NUM', 'itemId', 'categoryCode', 'viewRestricted', 'pageNo', // DD2.0対応関係。念のため。
    'collection_facet', 'permission_facet', 'ndc_facet', 'rekion_facet', 'basis_facet', 'levelCode_facet', 'official_facet', 'article_facet', 'institution_facet', 'subject_facet', 'language_facet', 'classic_facet', 'ghq_facet', 'itemToSearch_facet', 'fromYear_facet', 'toYear_facet', 'color_facet',
  ]
  const validQuery: any = {}
  queryFieldList.forEach(q => {
    if (q in routeQuery) validQuery[q] = routeQuery[q]
  })
  return validQuery
}

//
// Router Statistics log
//
const createToParameter = (router: any, cardId: string | null): RouterLog => {
  const builtinLoginTypeCode = getBuiltinLoginTypeCode(store)
  const patronTypeCode = getPatronTypeCode(store)
  const libraryTypeCode = getLibraryTypeCode(store, cardId)
  const parameter = {
    fullPath: router.fullPath,
    name: router.name,
    params: router.params ?? null,
    query: filterQuery(router.query),
    isRekion: router.meta.isRekion != null,
    pid: router.params?.pid && 'info:ndljp/pid/' + router.params.pid,
    libraryName: getLibraryName(libraryTypeCode),
    builtinLoginUserId: getBuiltinLoginUserId(store),
    builtinLoginTypeCode: builtinLoginTypeCode,
    builtinLoginTypeName: getBuiltinLoginTypeName(builtinLoginTypeCode),
    patronTypeCode: patronTypeCode,
    patronTypeName: getPatronTypeName(patronTypeCode),
    libraryTypeCode: libraryTypeCode,
    libraryTypeName: getLibraryTypeName(libraryTypeCode),
  }
  return parameter
}

const createFromParameter = (router: any, cardId: string | null): RouterLog => {
  const builtinLoginTypeCode = getBuiltinLoginTypeCode(store)
  const patronTypeCode = getPatronTypeCode(store)
  const libraryTypeCode = getLibraryTypeCode(store, cardId)
  const parameter = {
    fullPath: router.fullPath,
    name: router.name,
    isRekion: router.meta.isRekion != null,
    libraryName: getLibraryName(libraryTypeCode),
    builtinLoginUserId: getBuiltinLoginUserId(store),
    builtinLoginTypeCode: builtinLoginTypeCode,
    builtinLoginTypeName: getBuiltinLoginTypeName(builtinLoginTypeCode),
    patronTypeCode: patronTypeCode,
    patronTypeName: getPatronTypeName(patronTypeCode),
    libraryTypeCode: libraryTypeCode,
    libraryTypeName: getLibraryTypeName(libraryTypeCode),
  }
  return parameter
}

const getCardId = (store: any): string => {
  return store.getters.patron?.cardId ?? null
}

const getLibraryName = (libraryTypeCode: string | null): string | null => {
  if (libraryTypeCode == null) {
    return null
  } else if (libraryTypeCode === 'P') {
    return '図書館送信(個人向け)'
  }
  return store.getters.patron?.name ?? null
}

const getBuiltinLoginUserId = (store: any): string => {
  return store.getters.loggedInUser?.cardId?.replace('-read1', '') ?? null
}

const getBuiltinLoginTypeCode = (store: any): string => {
  return store.getters.loggedInUser?.type ?? null
}

const getBuiltinLoginTypeName = (builtinLoginTypeCode: string | null): string | null => {
  const patronTypeList: { [key: string]: string } = {
    Tokubetsu: '特別利用者',
    Kannnai: '館内確認用',
    Enkaku: '録音資料印刷用',
    RekionAdmin: 'れきおん参加館（管理者）',
    RekionReader: 'れきおん参加館',
    Package: 'パッケージ系電子出版物',
  }
  return builtinLoginTypeCode ? patronTypeList[builtinLoginTypeCode] : null
}

const getPatronTypeCode = (store: any): string => {
  return store.getters.patron?.type ?? null
}

const getPatronTypeName = (patronTypeCode: string): string | null => {
  const patronTypeList: { [key: string]: string } = {
    DIET_MEMBER: '国会議員',
    DIET_STAFF: '国会職員',
    BRANCHLIB: '支部図書館',
    LIBRARY: '各種図書館',
    BRAILLE: '点字図書館',
    REGISTERED: '登録利用者',
    ONEDAY: '当日利用者',
    NDL_STAFF: 'NDL職員',
    PHOTO: '複写代行入力',
    TEMPORARY_DUMMY: '臨時',
    LIBTRANS: '図書館送信',
    PCUSE_DUMMY: '端末利用',
  }
  return patronTypeCode ? patronTypeList[patronTypeCode] : null
}

const getLibraryTypeCode = (store: any, cardId: string | null): string | null => {
  if (cardId == null) return null
  return store.getters.patron?.libraryType ?? 'P'
}

const getLibraryTypeName = (libraryTypeCode: string | null): string | null => {
  const LibraryTypeList: { [key: string]: string } = {
    PUBLIC: '都道府県立・政令指定都市立・市町村立図書館',
    PUBLIC_UNIV: '国公立大学図書館',
    PRIVATE_UNIV: '私立大学図書館',
    SPECIAL: '専門図書館',
    FOREIGN: '海外図書館',
    SCHOOL: '学校図書館',
    LIBTYPE_BRANCHLIB: '支部図書館',
    LIBTYPE_BRAILLE: '点字図書館',
    P: '図書館送信(個人向け)',
  }
  return libraryTypeCode ? LibraryTypeList[libraryTypeCode] : null
}

//
// Action Statistics log
//
const createMetaParameter = (pid: string, contentMeta: any, permissionRule: string, mutation: any, store: any, cardId: string | null) => {
  const collectionsId = contentMeta.collections ?? null
  const builtinLoginTypeCode = getBuiltinLoginTypeCode(store)
  const patronTypeCode = getPatronTypeCode(store)
  const libraryTypeCode = getLibraryTypeCode(store, cardId)
  const uid = getCookieValue('uid')
  const metaParameter: ActionLog = {
    pid: pid,
    uniquePid: pid + uid,
    isRekion: mutation.payload.isRekion,
    isViewable: getIsViewable(mutation),
    level: contentMeta.level ?? null,
    rightsCode: getRightsCode(contentMeta),
    publishStatus: getPublishStatus(contentMeta),
    permissionRule: permissionRule,
    collectionsId: JSON.parse(JSON.stringify(collectionsId)),
    collectionsName: getCollectionsName(collectionsId),
    parentCollectionId: getParentCollectionId(collectionsId),
    parentCollectionName: getParentCollectionName(collectionsId),
    patronTypeCode: patronTypeCode,
    patronTypeName: getPatronTypeName(patronTypeCode),
    libraryTypeCode: libraryTypeCode,
    libraryTypeName: getLibraryTypeName(libraryTypeCode),
    libraryName: getLibraryName(libraryTypeCode),
    builtinLoginUserId: getBuiltinLoginUserId(store),
    builtinLoginTypeCode: getBuiltinLoginTypeCode(store),
    builtinLoginTypeName: getBuiltinLoginTypeName(builtinLoginTypeCode),
    title: getMeta(contentMeta, '0001Dtct'),
    volumeTitle: getMeta(contentMeta, '0006Dtct'),
    volume: getMeta(contentMeta, '0007Dtct'),
    creator: getMeta(contentMeta, '0010Dtct'),
    publisher: getMeta(contentMeta, '0020Dtct'),
    publicationYear: getMeta(contentMeta, '0059Dk'),
    publicationIssue: getMeta(contentMeta, '0269Dod'),
    publicationNumber: getMeta(contentMeta, '0270Dt'),
    publicationName: getMeta(contentMeta, '0271Dt'),
    publicationVolume: getMeta(contentMeta, '0272Dt'),
    provider: getMeta(contentMeta, '0360Dk'),
    ndcCategory: getMeta(contentMeta, '0026Dkck'),
    ndc8Category: getMeta(contentMeta, '0027Dkck'),
    ndc9Category: getMeta(contentMeta, '0028Dkck'),
    ndc10Category: getMeta(contentMeta, '0487Dkck'),
    bibliographicId: getMeta(contentMeta, '0352Dkck'),
    acquisitionBasis: getMeta(contentMeta, '0417Dt'),
    itemDownloadCount: mutation.type === 'ITEM_DOWNLOAD_URL' ? 1 : 0,
    pdfDownloadCount: mutation.type === 'DOWNLOAD_OTHER_FILE' ? 1 : 0,
    pdfBulkDownloadCount: mutation.type === 'DOWNLOAD_OTHERS_URL' ? 1 : 0,
    pictureDownloadCount: mutation.type === 'PICTURE_DOWNLOAD_URL' ? 1 : 0,
    accessCount: mutation.type === 'GET_META_CONTENTS' ? 1 : 0,
    copyCount: getCopyCount(mutation),
  }
  return metaParameter
}

const getPid = (mutation: any): string => {
  return mutation.type === 'PICTURE_DOWNLOAD_URL' ? mutation.payload.pictureMeta.picture.pid : mutation.payload.contentMeta.pid
}

const getIsViewable = (mutation: any): boolean | null => {
  if (mutation.type !== 'GET_META_CONTENTS') return null
  const isAllowed = store.getters.isAllowed
  const isDisable = store.getters.isDisable
  const isBundlePermission = store.getters.isBundleAllowed
  const isContentPermission = store.getters.isContentAllowed
  const isFileBundle = store.getters.isFileBundle
  return !(!isAllowed || isDisable || !isBundlePermission || (!isContentPermission && !isFileBundle))
}

const getContentMeta = (mutation: any): any => {
  return mutation.type === 'PICTURE_DOWNLOAD_URL' ? mutation.payload.pictureMeta.picture.item : mutation.payload.contentMeta
}

const getPermissionRule = (contentMeta: any): string => {
  return contentMeta.permission?.rule ?? null
}

const getRightsCode = (contentMeta: any): string => {
  return contentMeta.rights?.code ?? null
}

const getPublishStatus = (contentMeta: any): string => {
  return contentMeta.publishStatus ?? null
}

const getCollectionsName = (collectionsId: string[] | null): string[] | null => {
  if (collectionsId == null) return null
  const collectionMaster = store.getters.CollectionMaster
  const collectionsName = []
  for (const i in collectionsId) {
    collectionsName.push(collectionMaster[collectionsId[i]].content.name.ja)
  }
  return collectionsName
}

const getParentCollectionId = (collectionsId: string[] | null): string | null => {
  if (collectionsId == null) return null
  const getParentCollection = store.getters.CollectionFamily(collectionsId[0])
  return getParentCollection[1]?.collectionId
}

const getParentCollectionName = (collectionsId: string[] | null): string | null => {
  if (collectionsId == null) return null
  const getParentCollection = store.getters.CollectionFamily(collectionsId[0])
  return getParentCollection[1]?.title?.ja
}

const getMeta = (contentMeta: any, metaCode: string): string => {
  return contentMeta?.meta?.[metaCode] ? contentMeta.meta[metaCode].join('') : null
}

const getCopyCount = (mutation: any): number => {
  const targetMutationArray = ['ITEM_DOWNLOAD_URL', 'DOWNLOAD_OTHERS_URL', 'DOWNLOAD_OTHER_FILE', 'PICTURE_DOWNLOAD_URL']
  return targetMutationArray.includes(mutation.type) ? 1 : 0
}

export { createToParameter, createFromParameter, getCardId, createMetaParameter, getPid, getContentMeta, getPermissionRule }
