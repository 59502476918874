interface State {
  isMasterFetched: boolean
  isLoginInitialized: boolean
  showLoginModal: boolean
  showPrintModal: boolean
  showKssPrintModal: boolean
  showInternetPrintModal: boolean
}

const state: State = {
  isMasterFetched: false,
  isLoginInitialized: false,
  showLoginModal: false,
  showPrintModal: false,
  showKssPrintModal: false,
  showInternetPrintModal: false,
}
const getters = {
  IsMasterFetched (state: State): boolean {
    return state.isMasterFetched
  },
  isLoginInitialized (state: State): boolean {
    return state.isLoginInitialized
  },
  showLoginModal (state: State): boolean {
    return state.showLoginModal
  },
  showPrintModal (state: State): boolean {
    return state.showPrintModal
  },
  showKssPrintModal (state: State): boolean {
    return state.showKssPrintModal
  },
  showInternetPrintModal (state: State): boolean {
    return state.showInternetPrintModal
  },
}
const mutations = {
  COMPLETE_FETCH_MASTER (state: State): void {
    state.isMasterFetched = true
  },
  SET_LOGIN_INITIALIZED (state: State, isLoginInitialized: boolean): void {
    state.isLoginInitialized = isLoginInitialized
  },
  SET_SHOW_LOGIN_MODAL (state: State, showLoginModal: boolean): void {
    state.showLoginModal = showLoginModal
  },
  SET_SHOW_PRINT_MODAL (state: State, showPrintModal: boolean): void {
    state.showPrintModal = showPrintModal
  },
  SET_SHOW_KSS_PRINT_MODAL (state: State, showKssPrintModal: boolean): void {
    state.showKssPrintModal = showKssPrintModal
  },
  SET_SHOW_INTERNET_PRINT_MODAL (state: State, showInternetPrintModal: boolean): void {
    state.showInternetPrintModal = showInternetPrintModal
  },
}

const actions = {}
export default {
  state,
  getters,
  mutations,
  actions,
}
