import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d89efb3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-viewer-footer has-background-contents shadow" }
const _hoisted_2 = { class: "image-viewer-footer-frame-index" }
const _hoisted_3 = { class: "image-viewer-footer-frame-index-value level-center is-mobile" }
const _hoisted_4 = { id: "image-viewer-footer-frame-current-index" }
const _hoisted_5 = {
  class: "has-text-modest",
  id: "image-viewer-footer-frame-total-index"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInputRange = _resolveComponent("AppInputRange")!
  const _component_BookMarkButton = _resolveComponent("BookMarkButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppInputRange, {
        modelValue: _ctx.currentFrameIndex,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentFrameIndex) = $event)),
        min: 1,
        max: _ctx.currentBundleContents.length,
        label: _ctx.$t('label.frameNumber'),
        class: _normalizeClass({ rtlRange: _ctx.isRtlDirection }),
        id: "image-viewer-footer-frame-input-range"
      }, null, 8, ["modelValue", "max", "label", "class"]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isKn && !_ctx.isRekion)
          ? (_openBlock(), _createBlock(_component_BookMarkButton, {
              key: 0,
              pid: _ctx.itemPid,
              bid: _ctx.currentBid,
              cid: _ctx.currentCid,
              id: "image-viewer-footer-bookmark-button"
            }, null, 8, ["pid", "bid", "cid"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentFrameIndex), 1),
        _createElementVNode("span", _hoisted_5, " /" + _toDisplayString(_ctx.currentBundleContents.length), 1)
      ])
    ])
  ]))
}