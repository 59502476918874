/* eslint-disable */
import { IVError } from './IVError'
import {
  csspx2dpx,
  mm2px,
  px2mm,
  PhysicalPosition,
  PhysicalSize,
  RectMM,
  Vec2,
  PhysicalRect,
  DeviceInfo,
  KeyPair
} from './ImageViewerCommon'
import { Binding, ContentStructure, CoverPageMode } from './ContentStructure'
import { Layouter } from './Layouter'
import { PointerManager, Pointer } from './PointerManager'
import { DLProgress, TileDrawer } from './TileDrawer'
import { DrawResult, VisualElement } from './VisualElement'
import { PrintCroppingLayer } from './PrintCroppingLayer'
import { UserInputManager } from './UserInputManager'
import { InertiaControll } from './InertiaControll'
import { AccessToken } from '@/store/modules/AccessToken'
import { PageElement } from './PageElement'
import { KomaElement } from './KomaElement'

/**
 * 開発モードフラグ
 */
const __dev__ = process.env.VUE_APP_ENV !== 'production'

/**
 * 見開きモード
 */
const ViewMode = {

  /**
   * 単ページ表示
  */
  SINGLE: 1,

  /**
   * １コマ表示
  */
  KOMA: 2,

  /**
   * 2in1表示
  */
  TOW_IN_ONE: 3,

  SCROLL_SINGLE_V: 11,
  SCROLL_SINGLE_H: 12,
  SCROLL_KOMA_V: 13,
  SCROLL_KOMA_H: 14,
  SCROLL_TOW_IN_ONE_V: 15,
  SCROLL_TOW_IN_ONE_H: 16

} as const

type ViewMode = typeof ViewMode[keyof typeof ViewMode]

/**
 * 描画領域クリック位置列挙体
 */
const ClickAreas = {
  /**
   * クリックされていない
   */
  None: 0,
  /**
   * 領域中央＝メニュー表示
   */
  Center: 1,
  /**
   * 領域左端＝ページめくり
   */
  Left: 2,
  /**
   * 領域右端＝ページめくり
   */
  Right: 3
}

type ClickAreas = typeof ClickAreas[keyof typeof ClickAreas]

/**
 * 拡大縮小ステップ情報クラス
 */
class ScaleStep {
  /**
   * ビューア内部のスケール値
   */
  public innerScale = 1.0

  /*
   * UIに表示するスケール値（1.0 = フィット表示時の倍率）
   */
  public displayScale = 1.0
}

/**
 * 拡大縮小ステップ情報更新通知クラス
 */
class ScaleStepNotice {
  /**
   * スケールステップ配列
   */
  public scaleSteps = Array<ScaleStep>(1)

  /**
   * フィット表示したときに使用するscaleSteps内のインデックス（常に 9）
   */
  public fitScaleIndex = -1

  /**
   * 通知発行時の内部スケール値 noticeCurrentDrawingScaleと同じ値
   */
  public currentInnerScale = 1.0

  public copy (src: ScaleStepNotice): void {
    this.fitScaleIndex = src.fitScaleIndex
    this.currentInnerScale = src.currentInnerScale

    this.scaleSteps.length = 0
    src.scaleSteps.forEach(ss => {
      const s = new ScaleStep()
      s.displayScale = ss.displayScale
      s.innerScale = ss.innerScale
      this.scaleSteps.push(s)
    })
  }
}

/**
 * 初期切り抜き指定
 */
class InitialCropping {
  /**
   * 切り抜き範囲の左位置 ミリメートル
   */
  public left = 0
  /**
   * 切り抜き範囲の上位置 ミリメートル
   */
  public top = 0
  /**
   * 切り抜き範囲の幅 ミリメートル
   */
  public width = 0
  /**
   * 切り抜き範囲の高さ ミリメートル
   */
  public height = 0
  /**
   * 範囲指定モードに入った際に表示するラベル文字列（A4など）
   */
  public label = ''
  /**
   * 旧APIとの互換のため残していますが、
   * DPIは komainfo.json から取得するため将来的にこのプロパティは廃止されます。
   * @deprecated
   */
  public dpi = 400
}

/**
 * 画像ビューア起動オプションクラス
 */
class ImageViewerOption {
  /**
   * 初期表示ページインデックス（整数）
   * デフォルト値は0
   *
   * 負数、コンテンツのページ数（表示モードによってページ数が変わる）以上の値を指定した場合、init()は成功するが、後続の非同期のコンテンツ初期化処理で reject され例外が発生する
   */
  public initialPageIndex = 0

  /**
   * 【予約】初期表示モード 片ページ、見開きページ、2in1
   * デフォルト値はViewMode.USER_MIHIRAKI
   */
  public initialViewMode: ViewMode = ViewMode.KOMA

  /**
   * 初期切り抜き範囲指定
   */
  public initalCropping?: InitialCropping

  /**
   * タイル情報取得・復号用キーペア
   */
  public keyPair?: KeyPair
}

/**
 * 画像ビューアクラス
 */
class ImageViewer {
  /**
   * 最大拡大倍率
   */
  public static get MAX_SCALE (): number {
    return 6.0
  }

  /**
   * 画面端クリック・タップによるページ遷移に応答する領域幅
   * 描画エリア(MainCanvas)に対する割合
   */
  public static get EDGE_PAGING_AREA (): number {
    return 0.2
  }

  /**
   * 画面端クリック開始時と終了時のポインター座標に差分があった場合、画面端クリックが動作する差分の範囲
   */
  public static get DETECT_CLICK_MARGIN (): number {
    return 4
  }

  /**
   * コマ画像取得失敗時に表示するダミーコマ画像
   */
  public static DUMMY_KOMA?: any = undefined

  /**
   * ログを出力する
   * isError が偽の場合、プロダクトモードでは出力しない。
   * @param text 表示するテキストもしくはオブジェクト
   * @param isError エラー出力か否か 偽の場合は標準出力に出力し、真の場合は標準エラー出力に出力する
   */
  private static Log (text: unknown, isError = false): void {
    if (isError) {
      console.error(text)
    } else if (__dev__) {
      console.log(text)
    }
  }

  private static currentInstance: ImageViewer | undefined = undefined

  /**
   * ImageViewerインスタンスを返す
   * @returns 現在展開されている画像ビューアインスタンス
   */
  public static getInstance (): ImageViewer {
    if (ImageViewer.currentInstance) {
      return ImageViewer.currentInstance
    }

    ImageViewer.currentInstance = new ImageViewer()

    return ImageViewer.currentInstance
  }

  /**
   * 明示的に現在のImageViewerインスタンスを破棄する
   */
  public static disposeInstance (): void{
    if (ImageViewer.currentInstance) {
      ImageViewer.currentInstance.dispose()
      // ImageViewer.currentInstance = undefined
    }
  }

  /**
   * 現在のタイル情報復号用キーペア
   */
  private static currentKeyPair?: KeyPair
  /**
   * publicで習得できてよいのか
   * @returns
   */
  public static getKeyPair (): KeyPair | undefined {
    return ImageViewer.currentKeyPair
  }

  private static NS_SVG = 'http://www.w3.org/2000/svg'

  /**
   * ピンチによる描画スケール変更係数
   */
  private static PINCH_SCALE_FACTOR = 0.005

  /**
   * ホイールによる描画スケール変更係数
   */
  private static WHEEL_SCALE_FACTOR = -0.0004

  /**
   * 最後の発生したエラーコード
   */
  private lastError_: IVError = IVError.NONE;

  /**
   * 最後の発生したエラーコード
   */
  public get lastError (): IVError {
    return this.lastError_
  }

  private disposed_ = false
  /**
   * このビューアインスタンスが破棄済みか否か
   */
  public get disposed (): boolean {
    return this.disposed_
  }

  /**
   * ビューア挿入先クライアント要素
   */
  private clientDiv_?: HTMLDivElement;

  /**
   * 描画先Canvas要素
   */
  private mainCanvas_?: HTMLCanvasElement;

  /**
   * ビューアオプション
   */
  private viewerOption_?: ImageViewerOption;

  /**
   * 閲覧中のコンテンツ構造情報
   */
  private content_?: ContentStructure = undefined;

  /**
   * 描画対象のContext2D
   */
  private context_?: CanvasRenderingContext2D;

  /**
   * 点線描画先Canvas要素
   */
  private gridLineCanvas_?: HTMLCanvasElement;

  /**
   * 点線描画対象のContext2D
   */
  private gridLineContext_?: CanvasRenderingContext2D;

  /**
   * ルーラー表示先要素
   */
  private rulerCanvas_?: HTMLCanvasElement;

  /**
    * ルーラー表示先要素
    */
  private rulerContext_?: CanvasRenderingContext2D;

  /**
   * メインキャンバスのリサイズ監視
   */
  // private mainCanvasResizeObserver_?: ResizeObserver;
  private resizeEvent?: (ev: UIEvent)=>void = undefined

  /**
   * 現在のページインデックス
   * 単ページ表示の場合：ページIDと等しい
   * 見開き表示の場合：見開き状態のインデックス 2ページで1インデックス
   * 2in1表示の場合：2in1表示状態のインデックス 4ページで1インデックス
   */
  private currentPageIndex_ = 0;
  /**
   * 現在のページインデックス
   * 単ページ表示の場合：ページIDと等しい
   * 見開き表示の場合：見開き状態のインデックス 2ページで1インデックス
   * 2in1表示の場合：2in1表示状態のインデックス 4ページで1インデックス
   */
  public get currentPageIndex (): number {
    return this.currentPageIndex_
  }

  /**
   * 現在の描画対象ページ
   * 単ページなら currentPages_[0] のみ
   * 見開き表示状態なら currentPages_[0] のみ
   * 2in1表示の場合：currentPages_[0] に前方ページ、currentPages_[1] に後方ページを格納する
   */
  private currentPages_ = new Array<VisualElement | undefined>(2);

  /**
   * 単ページ・見開き・2in1モード
   */
  private currentViewMode_: ViewMode = ViewMode.KOMA;

  /**
   * 描画キャンバスのサイズ
   */
  private canvasSize_ = new PhysicalSize();

  /**
   * 描画管理
   */
  private layouter_ = new Layouter();

  /**
   * クリックされた描画領域の判定
   */
  private clickArea_: ClickAreas = ClickAreas.None

  /**
   * 現在の描画スケールステップ
   */
  private currentScaleSteps_ = new ScaleStepNotice()
  private currentScaleStepsBuf_ = new ScaleStepNotice()

  private svgFilterRoot_: SVGFilterElement | undefined = undefined
  private svgGammaFilter_: SVGFEComponentTransferElement | undefined = undefined
  private svgContrastFilter_: SVGFEComponentTransferElement | undefined = undefined
  private svgBrightnessFilter_: SVGFEComponentTransferElement | undefined = undefined
  private svgSharpnessFilter_: SVGFEConvolveMatrixElement | undefined = undefined
  private svgGrayscaleFilter_: SVGFEColorMatrixElement | undefined = undefined
  /**
   * ページインデックス変更を通知するコールバック関数配列
   */
  private noticeChangedPage_ = Array<((pageindex: number, komaID: number) => void)>();

  /**
   * 描画スケール変更を通知するコールバック関数配列
   */
  private noticeDrawingScale_ = Array<((scale: number) => void)>()

  /**
   * 描画スケールステップが変更されたときに新しい描画スケールステップを通知するコールバック関数配列
   */
  private noticeNewScaleSteps_ = Array<((stepNotice:ScaleStepNotice) => void)>()

  /**
   * 画面中央のシングルタップ・クリックを通知するコールバック関数配列
   */
  private noticeTapTheCenter_ = Array<(() => void)>()

  /**
   * 印刷範囲指定完了を通知するコールバック関数配列
   */
  private noticeChangePrintCropping_ = Array<((RectMM:RectMM) => void)>()

  /**
   * 印刷用切り抜き操作レイヤー
   */
  private printCroppingLayer_?: PrintCroppingLayer

  /**
   * 印刷用切り抜きフレームの座標
   */
  private printCroppingFrameRect_ = new RectMM()

  private printDPI_ = 400

  /**
   * ユーザー入力管理オブジェクト
   */
  private inputManager_ = UserInputManager.getInstance()

  /**
   * フィット表示状態を維持しているか否か
   */
  private hasBeenFit_ = false

  private constructor () {
    this.currentPageIndex_ = 0
  }

  /**
   * 画面を分割して印刷する場合の水平方向の指定分割数
   */
  private printDivisionsHorizontal_ = 0

  /**
   * 画面を分割して印刷する場合の垂直方向の指定分割数
   */
  private printDivisionsvertical_ = 0

  /**
   * 画面を分割するか否か
   */
  private printDivisionsFlag_ = false

  /**
   * ルーラーを表示するか否か
   */
  private showRuler_ = false

  /**
   * 慣性スクロール制御
   */
  private inertialControll_ = new InertiaControll()

  /**
   * ページ切り替え遅延処理用Timer Handler
   */
  private changePageQueueHandler_ = 0
  /**
   * ページ切り替え遅延タイムアウト時間 ms
   */
  private changePageTimeout_ = 100

  /**
   * 画像が描画されなかったときのための再描画要求
   */
  private redrawRequestHandler_ = 0
  /**
   * 画像が描画されなかったときのための再描画要求のタイムアウト
   */
  private redrawRequestTimeout_ = 100

  /**
   * currentPages_ が参照しているコマID
   */
  private currentKomaIDs_ = new Array<number>()

  private resizeInitiated_ = false
  private resizeInitiationHandler_ = 0

  /**
   * 現在のダーク・ライトモードの各種色情報
   */
  private currentStyleModeColor = {
    rulerBackgroundColor: '',
    rulerColor: ''
  }

  /**
   * 画像ドラッグの際の描画を間引く為のカウンター
   */
  private moveEventCancelCounter_ = 0

  private __debugModifyContent (content: any): void {
    const pages = content.Pages as Array<any>

    // content.BindingDirection = 'rtl'

    if (content.BindingDirection === 'ltr') {
      content.PagesLTR = pages

      content.PagesRTL = new Array<any>()
      for (let i = 0; i < pages.length; ++i) {
        const newPage = pages[i]// new Object() as any
        /*
        newPage.AntiTilt = pages[i].AntiTilt
        newPage.Area = pages[i].Area
        newPage.Clipping = pages[i].Clipping
        newPage.Koma = pages[i].Koma
        */
        if (pages.length % 2 !== 0 && i === pages.length - 1) {
          content.PagesRTL[i] = newPage
        } else if ((i % 2) === 0) {
          // newPage.PageID = i+1
          content.PagesRTL[i + 1] = newPage
        } else {
          // newPage.PageID = i-1
          content.PagesRTL[i - 1] = newPage
        }
      }
    } else {
      content.PagesRTL = pages

      content.PagesLTR = new Array<any>()
      for (let i = 0; i < pages.length; ++i) {
        const newPage = pages[i]// new Object() as any
        /*
        newPage.AntiTilt = pages[i].AntiTilt
        newPage.Area = pages[i].Area
        newPage.Clipping = pages[i].Clipping
        newPage.Koma = pages[i].Koma
        */
        if (pages.length % 2 !== 0 && i === pages.length - 1) {
          content.PagesLTR[i] = newPage
        } else if ((i % 2) === 0) {
          // newPage.PageID = i+1
          content.PagesLTR[i + 1] = newPage
        } else {
          // newPage.PageID = i-1
          content.PagesLTR[i - 1] = newPage
        }
      }
    }

    // entire page test
    let entirePage = content.PagesLTR[10]
    entirePage.Area.Left = 0
    entirePage.Area.Top = 0
    entirePage.Area.Right = 1.0
    entirePage.Area.Bottom = 1.0
    content.PagesLTR.splice(11, 1)

    entirePage = content.PagesRTL[10]
    entirePage.Area.Left = 0
    entirePage.Area.Top = 0
    entirePage.Area.Right = 1.0
    entirePage.Area.Bottom = 1.0
    content.PagesRTL.splice(11, 1)

    delete content.Pages

    /*
    pages[1].Area.Left = 0.5454545454545455

    const p = new JCSPage()
    p.PageID = 2
    p.Koma = 1
    p.AntiTilt = 10
    p.Area = new JCSArea()
    p.Area.Right = 0.5454545454545455
    p.Area.Top = 0
    p.Area.Left = 0.0
    p.Area.Bottom = 1.0
    pages.splice(2, 0, p)

    pages[3].PageID = 3

    */

    /*
    const komas = content.Komas as Array<any>
    komas[0].Clipping = { Left: 0.1, Top: 0.1, Right: 0.9, Bottom: 0.9 }
    komas[0].AntiTilt = 20

    const TinOs = content.TwoInOnePairs as Array<any>
    let t = TinOs[0] = {} as any
    t.KomaPair = [0, 1]
    t = TinOs[1] = {} as any
    t.KomaPair = [2]
    */
  }

  /**
   * 主にスクロール表示で現在描画対象となってるコマIDの範囲
   * [0] = 最も若いID
   * [1] = 最も老いたID
   */
  private currentVisibledKomaIDRange_ = new Array<number>()

  private __debug (ops?: ImageViewerOption): string {
    // const f = () => {
    //   console.log('debug')
    // }
    // setTimeout(f, 2000)

    // if (ops) {
    //   ops.keyPair = generateKeyPair()
    // }

    return 'hoge'
  }

  private __debug2 (): string {
    if (!this.content_) {
      return ''
    }
    const ep = this.layouter_.getElementPlacement(this.currentPages_, this.content_.bindingDirection, this.layouter_.currentPageScale)

    if (this.context_) {
      this.context_.save()

      this.context_.fillStyle = 'rgba(0, 0, 255, 0.2)'

      this.context_.fillRect(ep.left, ep.top, ep.width, ep.height)
      /*
      this.context_.fillStyle = 'rgba(0, 255, 0, 0.2)'

      const safeMargin = 0// 10 * devicePixelRatio

      this.context_.fillRect(
        this.layouter_.pagePosition.x, this.layouter_.pagePosition.y,
        ep.coreWidth, ep.coreHeight
      )

      this.context_.fillStyle = 'green'
      this.context_.fillRect(this.layouter_.pagePosition.x, this.layouter_.pagePosition.y, 2, ep.coreHeight)
      this.context_.fillStyle = 'red'
      this.context_.fillRect(this.layouter_.pagePosition.x, this.layouter_.pagePosition.y, ep.coreWidth, 2)
        */
      this.context_.restore()
    }

    return 'hoge'
  }

  private __debug3 (left: number, top: number, width: number, height: number):void{
    if (this.context_) {
      this.context_.save()

      this.context_.fillStyle = 'rgba(0, 0, 255, 0.2)'
      this.context_.fillRect(left, top, width, height)

      this.context_.restore()
    }
  }

  /**
   *
   * @returns はみ出しを処理した場合 true
   */
  private backToScreen (): boolean {
    if (!this.content_) {
      return false
    }

    if (this.currentPages_[0]) {
      const elementSize = this.layouter_.getElementPlacement([this.currentPages_[0]], this.content_.bindingDirection, this.layouter_.currentPageScale)
      if (this.currentPages_[1]) {
        const s = this.layouter_.getElementPlacement([this.currentPages_[1]], this.content_.bindingDirection, this.layouter_.currentPageScale)
        elementSize.width += s.width
        if (elementSize.height < s.height) {
          elementSize.height = s.height
        }
      }

      const elementBoundingBox = this.layouter_.getElementPlacement(this.currentPages_, this.content_.bindingDirection, this.layouter_.currentPageScale)
      const thresholdWidth = this.canvasSize_.width * 0.1
      const thresholdHeight = this.canvasSize_.height * 0.1

      const movePos = new PhysicalPosition(this.layouter_.pagePosition.x, this.layouter_.pagePosition.y)
      let needDrawing = false

      if (elementBoundingBox.right < thresholdWidth) {
        movePos.x = -(elementBoundingBox.width - thresholdWidth) - elementBoundingBox.offset.left
        needDrawing = true
      } else if ((this.canvasSize_.width - thresholdWidth) < elementBoundingBox.left) {
        movePos.x = (this.canvasSize_.width - thresholdWidth) - elementBoundingBox.offset.left
        needDrawing = true
      }

      if (elementBoundingBox.bottom < thresholdHeight) {
        movePos.y = -(elementBoundingBox.height - thresholdHeight) - elementBoundingBox.offset.top
        needDrawing = true
      } else if ((this.canvasSize_.height - thresholdHeight) < elementBoundingBox.top) {
        movePos.y = (this.canvasSize_.height - thresholdHeight) - elementBoundingBox.offset.top
        needDrawing = true
      }

      if (needDrawing) {
        this.layouter_.setPagePositon(this.currentPages_, movePos)
        this.draw()
        return true
      }
    }

    return false
  }

  /**
   * 画像ビューアを初期化して起動し、指定したコンテンツを指定する
   * @param client Canvas挿入先Div要素
   * @param contentJsonObj content.json オブジェクト
   * @param option 起動オプション
   * @returns エラーコード 成功した場合は IVError.None を返す
   */
  public init (client: HTMLDivElement, contentJsonObj: unknown, option: ImageViewerOption): IVError {
    // 開発中のみグローバルにビューアインスタンスを曝露させる
    if (__dev__) {
      (window as any).__iv = this // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    this.disposed_ = false

    // this.__debugModifyContent(contentJsonObj)
    // this.__debug(option)

    this.lastError_ = IVError.NONE

    this.clientDiv_ = client
    const cover = this.clientDiv_.querySelector('.to-cover-viewer') as HTMLDivElement
    if (cover) {
      this.inputManager_.pointerManager.setToCoverViewer(cover)
    }

    if (option) {
      ImageViewer.currentKeyPair = option.keyPair
      option.keyPair = undefined

      this.viewerOption_ = option
    }

    this.initElements()
    if (this.lastError_ !== IVError.NONE) {
      return this.lastError_
    }

    this.initEvents()

    if (this.layouter_.initLayout() !== 0) {
      return (this.lastError_ = IVError.FAILED_TO_INIT_COMPONENT)
    }

    // コンポーネントサイズの取得
    this.onResize()

    // content.json オブジェクトからの起動
    if (contentJsonObj) {
      this.setupFromContentJsonObj(contentJsonObj)
    }

    this.initSVGFilter()

    this.initPrintCroppingLayer()

    /*
    // ルーラーキャンバスを選択範囲レイヤーの上に移動させる
    if (this.rulerCanvas_) {
      this.clientDiv_.appendChild(this.rulerCanvas_)
    }
    */

    this.inertialControll_.setCallbackFuntion(mov => {
      if (mov.x === 0 && mov.y === 0) {
        this.draw()
        return true
      }
      return this.movePagePositionByInertiaScroll(mov.x, mov.y)
    })

    return (this.lastError_ = IVError.NONE)
  }

  /**
   *
   * @param keepEventListener 登録済みイベントリスナーを初期化せずに残すか否か
   */
  private dispose (): void {
    // if (this.mainCanvasResizeObserver_) {
    //   this.mainCanvasResizeObserver_.disconnect()
    //   this.mainCanvasResizeObserver_ = undefined
    // }
    if (this.resizeEvent) {
      window.removeEventListener('resize', this.resizeEvent)
      this.resizeEvent = undefined
    }

    this.content_ = undefined
    this.clientDiv_ = undefined
    this.mainCanvas_ = undefined
    this.viewerOption_ = undefined
    this.content_ = undefined
    this.gridLineCanvas_ = undefined
    this.gridLineContext_ = undefined
    this.rulerCanvas_ = undefined
    this.rulerContext_ = undefined
    this.currentPageIndex_ = 0
    this.currentPages_ = new Array<VisualElement | undefined>(2)
    this.currentViewMode_ = ViewMode.KOMA
    this.inputManager_.resetPointerManager()
    this.canvasSize_ = new PhysicalSize()
    this.layouter_ = new Layouter()
    this.currentScaleSteps_ = new ScaleStepNotice()
    this.svgFilterRoot_ = undefined
    this.svgGammaFilter_ = undefined
    this.svgContrastFilter_ = undefined
    this.svgBrightnessFilter_ = undefined
    this.svgGrayscaleFilter_ = undefined
    if (this.printCroppingLayer_) {
      this.printCroppingLayer_.dispose()
    }
    this.printCroppingLayer_ = undefined

    this.noticeChangedPage_.length = 0
    this.noticeDrawingScale_.length = 0
    this.noticeNewScaleSteps_.length = 0
    this.noticeTapTheCenter_.length = 0
    this.noticeChangePrintCropping_.length = 0

    if (this.changePageQueueHandler_ !== 0) {
      clearTimeout(this.changePageQueueHandler_)
      this.changePageQueueHandler_ = 0
    }

    this.resizeInitiated_ = false

    this.disposed_ = true
  }

  /**
   * 常にtrueを返すダミー関数
   */
  private dummyFunction (): boolean {
    return true
  }

  /*************************************************/

  /*************************************************/

  public getEventHanlders (): {
    onClick: (event: MouseEvent) => void;
    onPointerDown: (event: MouseEvent | TouchEvent) => void;
    onPointerMove: (event: MouseEvent | TouchEvent) => void;
    onPointerUp: (event: MouseEvent | TouchEvent) => void;
    onWheel: (event: WheelEvent) => void;
    onMouseLeave: (event: MouseEvent) => void;
    onDblClick: (event: MouseEvent) => void;
    } {
    const onClick = (event: MouseEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onPointerDown = (event: MouseEvent | TouchEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onPointerMove = (event: MouseEvent | TouchEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onPointerUp = (event: MouseEvent | TouchEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onWheel = (event: WheelEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onMouseLeave = (event :MouseEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }
    const onDblClick = (event: MouseEvent) => {
      this.inputManager_.dispatch(event, this.mainCanvas_)
    }

    return {
      onClick,
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onWheel,
      onMouseLeave,
      onDblClick
    }
  }

  /**
   * 一番最初にタッチした指のID
   */
  private touchStartIdentifier_ = 0

  /**
   * タッチ時の指の数
   */
  private touchPointCounter_ = 0

  public onClick (event: MouseEvent, pointerManager: PointerManager): boolean {
    if (!this.content_) {
      return false
    }

    if (this.printCroppingLayer_) {
      if (!this.printCroppingLayer_.frameIsDrawn && this.printCroppingLayer_.enabled) {
        if (this.gridLineContext_ && this.gridLineCanvas_) {
          this.gridLineContext_.clearRect(
            0,
            0,
            this.gridLineCanvas_.width,
            this.gridLineCanvas_.height
          )
        }
      }
    }
    event.preventDefault()

    // 画面端クリックページめくり
    // クリック領域の判定
    const ps = pointerManager.convertEventToPointer(event)
    const pt = pointerManager.getPointer(PointerManager.MOUSE_IDENTIFIER)
    if (ps?.length > 0 && pt && !this.printCroppingLayer_?.enabled) {
      const p = ps[0]
      if (Math.abs(pt.startPosition.x - pt.prevPosition.x) < ImageViewer.DETECT_CLICK_MARGIN &&
            Math.abs(pt.startPosition.y - pt.prevPosition.y) < ImageViewer.DETECT_CLICK_MARGIN) {
        // 画面端クリックページめくり
        if (p.position.x <= (this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) &&
              this.clickArea_ === ClickAreas.Left) {
          if (this.content_.bindingDirection === Binding.rtl) {
            this.goToNextPage()
          } else {
            this.goToPrevPage()
          }
        } else if ((this.canvasSize_.width - this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) <= p.position.x &&
                      this.clickArea_ === ClickAreas.Right) {
          if (this.content_.bindingDirection === Binding.rtl) {
            this.goToPrevPage()
          } else {
            this.goToNextPage()
          }
        } else if ((this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) < p.position.x &&
                      p.position.x < (this.canvasSize_.width - this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) &&
                      this.clickArea_ === ClickAreas.Center) {
          // メニュー表示イベントを発火する
          if (this.noticeTapTheCenter_.length > 0) {
            this.noticeTapTheCenter_.forEach(f => {
              f()
            })
          }
        }
      }
    }

    return false
  }

  public onPointerDown (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    this.inertialControll_.stop()

    if (event instanceof MouseEvent) {
      if (this.mainCanvas_) {
        pointerManager.setToCoverViewerCursor('grabbing')
      }
    } else {
      this.touchStartIdentifier_ = event.changedTouches[0].identifier
      this.touchPointCounter_ = event.targetTouches.length
    }

    // クリック領域の判定準備
    const p = pointerManager.getPrimaryPointer()
    this.clickArea_ = ClickAreas.None
    if (p) {
      if (p.position.x <= (this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA)) {
        this.clickArea_ = ClickAreas.Left
      } else if ((this.canvasSize_.width - this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) <= p.position.x) {
        this.clickArea_ = ClickAreas.Right
      } else if ((this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) < p.position.x && p.position.x < (this.canvasSize_.width - this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA)) {
        this.clickArea_ = ClickAreas.Center
      }
    }

    return false
  }

  private movePagePositionByPointer (pointer: Pointer): void {
    if (!this.content_) {
      return
    }

    const moveAmount = new PhysicalPosition()
    moveAmount.copy(pointer.differenceOfPosition)

    if (this.hasBeenFit_ && this.layouter_.isFitScale(this.currentPages_, this.content_.bindingDirection)) {
      switch (this.currentViewMode_) {
        case ViewMode.SCROLL_SINGLE_V:
        case ViewMode.SCROLL_KOMA_V:
        case ViewMode.SCROLL_TOW_IN_ONE_V:
          moveAmount.x = 0
          break
        case ViewMode.SCROLL_SINGLE_H:
        case ViewMode.SCROLL_KOMA_H:
        case ViewMode.SCROLL_TOW_IN_ONE_H:
          moveAmount.y = 0
          break
      }
    }

    this.layouter_.movePagePosition(
      this.currentPages_,
      moveAmount
    )

    this.draw()
  }

  /**
   * 慣性スクロールでページ表示位置を移動する
   * @param x
   * @param y
   * @returns ページ表示位置を移動できない場合は false を返す
   */
  private movePagePositionByInertiaScroll (x: number, y: number): boolean {
    if (!this.content_) {
      return false
    }

    const moveAmount = new PhysicalPosition(x, y)

    if (this.hasBeenFit_ && this.layouter_.isFitScale(this.currentPages_, this.content_.bindingDirection)) {
      switch (this.currentViewMode_) {
        case ViewMode.SCROLL_SINGLE_V:
        case ViewMode.SCROLL_KOMA_V:
        case ViewMode.SCROLL_TOW_IN_ONE_V:
          moveAmount.x = 0
          break
        case ViewMode.SCROLL_SINGLE_H:
        case ViewMode.SCROLL_KOMA_H:
        case ViewMode.SCROLL_TOW_IN_ONE_H:
          moveAmount.y = 0
          break
      }
    }

    this.layouter_.movePagePosition(
      this.currentPages_,
      moveAmount
    )

    if (this.backToScreen()) {
      return false
    }

    this.draw()
    return true
  }

  
  public onPointerMove (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    event.preventDefault()
    
    this.moveEventCancelCounter_++

    if (this.mainCanvas_) {
      pointerManager.setToCoverViewerCursor('grab')
    }

    const pointerCount = pointerManager.eneblPointCount
    // ページ描画位置の変更
    if (pointerCount === 1) {
      if (event instanceof MouseEvent) {
        const pp = pointerManager.getPrimaryPointer()
        if (pp) {
          this.movePagePositionByPointer(pp)
          return false
        }
        if (this.mainCanvas_) {
          pointerManager.setToCoverViewerCursor('grabbing')
        }
      } else {
        // TouchEventで
        // スクロールモードの場合
        if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
          const pp = pointerManager.getPrimaryPointer()
          if (pp) {
            this.movePagePositionByPointer(pp)
            this.inertialControll_.setMovementStrength(pp.differenceOfPosition, 1.0)
            return false
          }
        }
      }
    } else if (pointerCount >= 2) {
      let zoomed = false
      if (Math.abs(pointerManager.differenceOfDistance) > 5 * DeviceInfo.DPR &&
          (Math.abs(pointerManager.differenceOfCenterPosition.x) < 7 * DeviceInfo.DPR && Math.abs(pointerManager.differenceOfCenterPosition.y) < 7 * DeviceInfo.DPR)
      ) {
        this.hasBeenFit_ = false
        this.layouter_.changePageScale(
          this.currentPages_,
          pointerManager.differenceOfDistance * ImageViewer.PINCH_SCALE_FACTOR / DeviceInfo.DPR,
          this.currentScaleSteps_.scaleSteps[this.currentScaleSteps_.scaleSteps.length - 1].innerScale,
          this.currentScaleSteps_.scaleSteps[0].innerScale,
          pointerManager.centerPosition
        )
        zoomed = true
      }

      if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
        this.layouter_.keepFitScale = false
      }

      if (!zoomed) {
        this.layouter_.movePagePosition(
          this.currentPages_,
          pointerManager.differenceOfCenterPosition
        )
      }

      if (this.noticeDrawingScale_.length > 0) {
        this.noticeDrawingScale_.forEach(f => {
          f(this.layouter_.currentPageScale)
        })
      }
    }

    if(this.moveEventCancelCounter_ < 2){
      return false
    }
    this.moveEventCancelCounter_ = 0
    if (pointerManager.isPressing()) this.draw()

    return false
  }

  public onPointerUp (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    if (event instanceof MouseEvent) {
      if (this.mainCanvas_) {
        pointerManager.setToCoverViewerCursor('grab')
        // マウスは排除しなくてよい
        // pointerManager.removePointerByID(PointerManager.MOUSE_IDENTIFIER)
      }
    } else if (event instanceof TouchEvent) {
      if (this.currentViewMode_ < ViewMode.SCROLL_SINGLE_V && this.printCroppingLayer_ && !this.printCroppingLayer_.enabled) {
        const p = pointerManager.getPointer(this.touchStartIdentifier_)

        for (let i = 0; i < event.changedTouches.length; ++i) {
          const currentP = pointerManager.getPointer(event.changedTouches[i].identifier)
          if (currentP) {
            pointerManager.removePointer(currentP)
          }
        }

        if (this.mainCanvas_ && p && this.content_) {
          const end = p.position
          const start = p.startPosition
          const touchPointer = this.touchPointCounter_
          const thresholdPosition = DeviceInfo.DPR * 50
          if (touchPointer === 1 && Math.abs(end.x - start.x) > Math.abs(end.y - start.y)) {
            if (thresholdPosition < (end.x - start.x)) {
              if (this.content_.bindingDirection === Binding.rtl) {
                this.goToNextPage()
              } else {
                this.goToPrevPage()
              }
              return false
            } else if (-thresholdPosition > (end.x - start.x)) {
              if (this.content_.bindingDirection === Binding.rtl) {
                this.goToPrevPage()
              } else {
                this.goToNextPage()
              }
              return false
            }
          }
        }
      }

      if (this.currentViewMode_ > ViewMode.SCROLL_SINGLE_V) {
        // 慣性スクロール
        if (pointerManager.getPressingPointerCount() === 0) {
          // 最後の指が離されたときのみ処理する
          this.inertialControll_.begin()
        }
      }
    }

    if (pointerManager.eneblPointCount <= 0) {
      this.draw()
    }

    this.backToScreen()

    return false
  }

  public onWheel (event: WheelEvent, pointerManager: PointerManager): boolean {
    event.preventDefault()

    if (!this.content_) {
      return false
    }
    
    this.hasBeenFit_ = false
    this.layouter_.changePageScale(
      this.currentPages_,
      event.deltaY * ImageViewer.WHEEL_SCALE_FACTOR,
      this.currentScaleSteps_.scaleSteps[this.currentScaleSteps_.scaleSteps.length - 1].innerScale,
      this.currentScaleSteps_.scaleSteps[0].innerScale,
      new PhysicalPosition(csspx2dpx(event.offsetX), csspx2dpx(event.offsetY))
    )

    if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
      this.layouter_.keepFitScale = false
    }

    if (this.noticeDrawingScale_.length > 0) {
      this.noticeDrawingScale_.forEach(f => {
        f(this.layouter_.currentPageScale)
      })
    }

    this.draw()

    return false
  }

  public onMouseLeave (event: MouseEvent, pointerManager: PointerManager): boolean {
    event.preventDefault()
    this.backToScreen()
    pointerManager.disabledPoint()

    return false
  }

  public onDblClick (event: MouseEvent, pointerManager: PointerManager): boolean {
    if (this.content_) {
      event.preventDefault()
      const ps = pointerManager.convertEventToPointer(event)
      if (ps?.length > 0) {
        const p = ps[0]
        if (p.position.x >= (this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) &&
        (this.canvasSize_.width - this.canvasSize_.width * ImageViewer.EDGE_PAGING_AREA) >= p.position.x) {
          const fitScale = this.layouter_.getFitScale(this.currentPages_, this.content_.bindingDirection)
          if (Math.abs(this.layouter_.currentPageScale - fitScale) < 0.0001) {
            this.hasBeenFit_ = false
            const targetIndex = Math.floor(this.currentScaleSteps_.fitScaleIndex / 2)
            this.layouter_.changePageScale(
              this.currentPages_,
              this.currentScaleSteps_.scaleSteps[targetIndex].innerScale,
              this.currentScaleSteps_.scaleSteps[this.currentScaleSteps_.scaleSteps.length - 1].innerScale,
              this.currentScaleSteps_.scaleSteps[0].innerScale,
              pointerManager.prevPosition)

            if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
              this.layouter_.keepFitScale = false
            }

            this.draw()
          } else {
            this.showFit()

            if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
              this.layouter_.keepFitScale = true
            }
          }

          if (this.noticeDrawingScale_.length > 0) {
            this.noticeDrawingScale_.forEach(f => {
              f(this.layouter_.currentPageScale)
            })
          }
        }
      }
    }

    return false
  }

  private onResize (): void {
    if (this.clientDiv_ && this.mainCanvas_ && this.gridLineCanvas_ && this.rulerCanvas_ && this.content_ && this.currentPages_[0]) {
      const w = csspx2dpx(this.clientDiv_.offsetWidth)
      const h = csspx2dpx(this.clientDiv_.offsetHeight)
      this.canvasSize_.width = this.mainCanvas_.width = this.gridLineCanvas_.width = this.rulerCanvas_.width = w
      this.canvasSize_.height = this.mainCanvas_.height = this.gridLineCanvas_.height = this.rulerCanvas_.height = h

      this.layouter_.setCanvasSize(w, h)

      if (!this.resizeInitiated_) {
        if (this.resizeInitiationHandler_ !== 0) {
          clearTimeout(this.resizeInitiationHandler_)
          this.resizeInitiationHandler_ = 0
        }

        this.showFit()
        this.resizeInitiated_ = true
      } else {
        this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
        this.draw()
      }

      this.updateScaleSteps()

      if (this.printCroppingLayer_) {
        this.printCroppingLayer_.resize()
      }
    } else if (!this.resizeInitiated_) {
      if (this.resizeInitiationHandler_ !== 0) {
        clearTimeout(this.resizeInitiationHandler_)
        this.resizeInitiationHandler_ = 0
      }
      this.resizeInitiationHandler_ = setTimeout(() => { this.onResize() }, 500)
    }
  }

  /*************************************************/

  /**
   * 公開APIを返す
   * @returns
   */
  public getPublicMethods (): {
    changePage: (a: number) => boolean;
    toNextPage: () => boolean;
    toPrevPage: () => boolean;
    goToNextPage: () => boolean;
    goToPrevPage: () => boolean;
    changeDirection: (direction: Binding) => boolean;
    changeRotation: (angle: number) => boolean;
    changeViewMode: (mode: ViewMode) => boolean,
    showFit: () => number,
    scaleUp: () => void;
    scaleDown: () => void;
    getScaleStep: () => ScaleStepNotice;
    getMaxScale: () => number | undefined;
    getMinScale: () => number | undefined;
    changeScale: (scale: number) => void;
    changeScaleByDisplayScale: (dipsScale: number) => void;
    setDivision: (horizontal?: number, vertical?:number) =>void;
    enableAutoClipping: () => void;
    disableAutoClipping: () => void;
    execPrintCroppingMode: () => void;
    exitPrintCroppingMode: () => void;
    setPrintCroppingArea: (left: number, top: number, width: number, height: number, label?:string, centering?:boolean, dpi?: number) => boolean
    cropArea: (left: number, top: number, width: number, height: number, label?:string, dpi?:number) =>boolean
    cropWithCurrentArea: () => boolean;
    getCroppingAreaBitmap: () => string;
    disableCropping: () => void;
    // showRuler :() => void;
    // hideRuler: () =>void;
    getImageData: (size?: number) => ImageData | undefined;
    updateToken: (newToken: Array<AccessToken>) => void;
    updateStyleMode: () => void;
    viewerSizeChanged: () => void;
    changeCoverPageMode: (mode: CoverPageMode) => void;
    } {
    const changePage = (a: number) => {
      return this.changePage(a)
    }
    const toNextPage = () => {
      return this.toNextPage()
    }
    const toPrevPage = () => {
      return this.toPrevPage()
    }
    const goToNextPage = () => {
      return this.toNextPage()
    }
    const goToPrevPage = () => {
      return this.toPrevPage()
    }
    const changeDirection = (direction: Binding) => {
      return this.changeDirection(direction)
    }

    const changeRotation = (angle: number) => {
      return this.changeRotation(angle)
    }

    const changeViewMode = (mode: ViewMode) => {
      return this.changeViewMode(mode)
    }

    const showFit = () => {
      return this.showFit()
    }

    const scaleUp = () => {
      this.scaleUp()
    }
    const scaleDown = () => {
      this.scaleDown()
    }
    const getScaleStep = () => {
      return this.getScaleStep()
    }
    const getMaxScale = () => {
      return this.getMaxScale()
    }
    const getMinScale = () => {
      return this.getMinScale()
    }
    const changeScale = (scale: number) => {
      this.changeScale(scale)
    }
    const changeScaleByDisplayScale = (dispScale: number) => {
      this.changeScaleByDisplayScale(dispScale)
    }

    const setDivision = (horizontal?: number, vertical?:number) => {
      return this.setDivision(horizontal, vertical)
    }

    const enableAutoClipping = () => {
      this.enableAutoClipping()
    }

    const disableAutoClipping = () => {
      this.disableAutoClipping()
    }

    const execPrintCroppingMode = () => {
      this.execPrintCroppingMode()
    }

    const exitPrintCroppingMode = () => {
      this.exitPrintCroppingMode()
    }

    const setPrintCroppingArea = (left: number, top: number, width: number, height: number, label?:string, centering?:boolean, dpi?: number) => {
      return this.setPrintCroppingArea(left, top, width, height, label, centering, dpi)
    }

    const cropArea = (left: number, top: number, width: number, height: number, label?:string, dpi?: number) => {
      return this.cropArea(left, top, width, height, label, dpi)
    }

    const cropWithCurrentArea = () => {
      return this.cropWithCurrentArea()
    }

    const getCroppingAreaBitmap = () => {
      return this.getCroppingAreaBitmap()
    }

    const disableCropping = () => {
      this.disableCropping()
    }

    // const showRuler = () => {
    //   return this.showRuler()
    // }

    // const hideRuler = () => {
    //   return this.hideRuler()
    // }
    const getImageData = (size?: number) => {
      return this.getImageData(size)
    }

    const updateToken = (newToken: Array<AccessToken>) => {
      this.updateToken(newToken)
    }

    const updateStyleMode = () => {
      this.updateStyleMode()
    }

    const viewerSizeChanged = () => {
      this.viewerSizeChanged()
    }

    const changeCoverPageMode = (mode: CoverPageMode) => {
      this.changeCoverPageMode(mode)
    }

    return {
      changePage,
      toNextPage,
      toPrevPage,
      goToNextPage,
      goToPrevPage,
      changeDirection,
      changeRotation,
      changeViewMode,
      showFit,
      scaleUp,
      scaleDown,
      getScaleStep,
      getMaxScale,
      getMinScale,
      changeScale,
      changeScaleByDisplayScale,
      setDivision,
      enableAutoClipping,
      disableAutoClipping,
      execPrintCroppingMode,
      exitPrintCroppingMode,
      setPrintCroppingArea,
      cropArea,
      cropWithCurrentArea,
      getCroppingAreaBitmap,
      disableCropping,
      // showRuler,
      // hideRuler,
      getImageData,
      updateToken,
      updateStyleMode,
      viewerSizeChanged,
      changeCoverPageMode
    }
  }

  /**
   * 指定したページへ遷移する
   * @param pageIndex 遷移先のページインデックス
      0～最大ページインデックスの範囲
      最大ページインデックスは現在の表示モードによって変わる
      ・片ページの場合は、コンテンツ構造情報の Pages 配列の要素数
      ・見開き（コマ表示）の場合は、コンテンツ構造情報の Komas 配列の要素数
      ・2in1の場合は、コンテンツ構造情報の ceil(TwoInOnePairs 配列の要素数 / 2) の範囲
   * @returns ページ遷移に成功した場合は true, 失敗した場合は false を返す
   */
  public changePage (pageIndex: number): boolean {
    if (!this.content_) {
      return false
    }

    if (this.changePageIndex(pageIndex)) {
      if (this.currentViewMode_ < ViewMode.SCROLL_SINGLE_V) {
        this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
      }
      return true
    }
    return false
  }

  public toNextPage (): boolean {
    return this.goToNextPage()
  }

  public toPrevPage (): boolean {
    return this.goToPrevPage()
  }
  /**
   * 現在の次のページへ遷移する。綴じ方向によって遷移方向が変わる。
   * @returns ページ遷移に成功した場合は true, 失敗した場合は false を返す
   */

  public goToNextPage (): boolean {
    if (!this.content_) {
      return false
    }

    if (this.changePageIndex(this.currentPageIndex_ + 1)) {
      this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
      return true
    }
    return false
  }

  /**
   * 現在の前のページへ遷移する。綴じ方向によって遷移方向が変わる。
   * @returns ページ遷移に成功した場合は true, 失敗した場合は false を返す
   */
  public goToPrevPage (): boolean {
    if (!this.content_) {
      return false
    }

    if (this.changePageIndex(this.currentPageIndex_ - 1)) {
      this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
      return true
    }
    return false
  }

  /**
   * ページめくり方向を変更する
   * @param direction
   * @returns 処理の実行ができたか否か
   */
  public changeDirection (direction: Binding): boolean {
    if (this.content_) {
      if (this.content_.bindingDirection === direction) {
        return false
      }

      if (this.content_.changeBindingDirection(direction)) {
        this.layouter_.currentPageBinding = this.content_.bindingDirection

        if (this.currentViewMode_ === ViewMode.SINGLE || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V) {
          // 片ページの順を入れ替える
          const pageIndex = this.content_.getPageIndex(this.currentPages_[0] as PageElement)
          this.changePageIndex(pageIndex)
        }
        this.draw()
        return true
      }
    }
    return false
  }

  /**
   * ユーザーによるコマの回転角度を変更する
   * @param deg
   * @returns
   */
  public changeRotation (deg: number): boolean {
    if (!this.layouter_.changeRotation(deg)) {
      return false
    }

    this.draw()
    this.updateScaleSteps()

    return true
  }

  public enableAutoClipping (): void {
    this.layouter_.enableAutoClipping = true
    this.draw()
  }

  public disableAutoClipping (): void {
    this.layouter_.enableAutoClipping = false
    this.draw()
  }

  public showFit (): number {
    if (!this.content_) {
      return this.layouter_.currentPageScale
    }
    this.layouter_.centering(this.currentPages_, this.content_.bindingDirection)
    const res = this.layouter_.currentPageScale
    this.hasBeenFit_ = true
    this.draw()
    return res
  }

  /**
   * 指定した拡大率が属するスケールステップ配列のインデックス（配列）を返す
   * @param scale 検索対象の拡大率
   * @param direction スケールアップ方向 +1, スケールダウン方向 -1
   * @returns res[0]=指定した拡大率の上側ステップインデックス、res[1]=指定した拡大率の下側ステップインデックス
   */
  public detectScaleStepBy (scale: number, direction: number): Array<number> | undefined {
    if (!this.currentScaleSteps_) {
      return undefined
    }

    const res = [-1, -1]

    if (direction > 0) {
      for (let i = 0; i < this.currentScaleSteps_.scaleSteps.length; ++i) {
        if (this.currentScaleSteps_.scaleSteps[i].innerScale <= scale) {
          res[1] = i
          break
        }
      }
    } else {
      for (let i = 0; i < this.currentScaleSteps_.scaleSteps.length; ++i) {
        if (this.currentScaleSteps_.scaleSteps[i].innerScale < scale) {
          res[1] = i
          break
        }
      }
    }

    if (res[1] < 0) {
      // 最小拡大率未満の場合
      res[0] = res[1] = this.currentScaleSteps_.scaleSteps.length - 1
    } else if (res[1] === 0) {
      // 最大拡大率以上
      res[0] = res[1]
    } else {
      res[0] = res[1] - 1
    }

    return res
  }

  /**
   * 一段階拡大する
   */
  public scaleUp (): void{
    if (this.content_) {
      const currentSS = this.detectScaleStepBy(this.layouter_.currentPageScale, 1)
      if (!currentSS) {
        return
      }

      let index = currentSS[0]
      if (index === (this.currentScaleSteps_.scaleSteps.length - 1)) {
        --index
      }

      this.layouter_.setPageScale(this.currentPages_, this.currentScaleSteps_.scaleSteps[index].innerScale)

      if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
        if (this.currentScaleSteps_.scaleSteps[index].displayScale === 1.0) {
          this.layouter_.keepFitScale = true
        } else {
          this.layouter_.keepFitScale = false
        }
      }

      this.hasBeenFit_ = false
      this.draw()

      if (this.noticeDrawingScale_.length > 0) {
        this.noticeDrawingScale_.forEach(f => {
          f(this.layouter_.currentPageScale)
        })
      }
    }
  }

  /**
   * 一段階縮小する
   */
  public scaleDown (): void{
    if (this.content_) {
      const currentSS = this.detectScaleStepBy(this.layouter_.currentPageScale, -1)
      if (!currentSS) {
        return
      }

      let index = currentSS[1]
      if (index === 0) {
        ++index
      }
      this.layouter_.setPageScale(this.currentPages_, this.currentScaleSteps_.scaleSteps[index].innerScale)

      if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
        if (this.currentScaleSteps_.scaleSteps[index].displayScale === 1.0) {
          this.layouter_.keepFitScale = true
        } else {
          this.layouter_.keepFitScale = false
        }
      }

      this.hasBeenFit_ = false
      this.draw()

      if (this.noticeDrawingScale_.length > 0) {
        this.noticeDrawingScale_.forEach(f => {
          f(this.layouter_.currentPageScale)
        })
      }
    }
  }

  /**
   * 現在のスケールステップを返す
   * @returns 現在のスケールステップ
   */
  public getScaleStep (): ScaleStepNotice {
    this.currentScaleSteps_.currentInnerScale = this.layouter_.currentPageScale

    this.currentScaleStepsBuf_.copy(this.currentScaleSteps_)
    return this.currentScaleStepsBuf_
  }

  /**
   * 現在の最大拡大倍率を返す
   * @returns 現在の最大拡大倍率
   */
  public getMaxScale (): number | undefined {
    if (this.currentScaleSteps_.scaleSteps?.length > 0 && this.currentScaleSteps_.scaleSteps[0]) {
      return this.currentScaleSteps_.scaleSteps[0].innerScale
    }
    return undefined
  }

  /**
   * 現在の最小縮小倍率を返す
   * @returns 現在の最小縮小倍率
   */
  public getMinScale (): number | undefined {
    if (this.currentScaleSteps_.scaleSteps.length > 0 && this.currentScaleSteps_.scaleSteps[this.currentScaleSteps_.scaleSteps.length - 1]) {
      return this.currentScaleSteps_.scaleSteps[this.currentScaleSteps_.scaleSteps.length - 1].innerScale
    }
    return undefined
  }

  /**
   * 内部倍率を指定して画像の拡大率を変更する
   * @param scale 新しい内部倍率
   */
  public changeScale (scale: number): void {
    const ss = this.currentScaleSteps_.scaleSteps
    if (ss.length <= 0) {
      return
    }

    this.hasBeenFit_ = false

    if (ss[ss.length - 1].innerScale <= scale && scale <= ss[0].innerScale) {
      this.layouter_.setPageScale(this.currentPages_, scale)
      this.draw()
      if (this.noticeDrawingScale_.length > 0) {
        this.noticeDrawingScale_.forEach(f => {
          f(this.layouter_.currentPageScale)
        })
      }
    }
  }

  /**
   * 表示倍率を指定して画像の拡大率を
   * @param dipsScale 新しい表示倍率
   */
  public changeScaleByDisplayScale (dipsScale: number): void {
    const ss = this.currentScaleSteps_.scaleSteps
    if (ss.length <= 0) {
      return
    }

    this.hasBeenFit_ = false

    if (ss[ss.length - 1].displayScale <= dipsScale && dipsScale <= ss[0].displayScale) {
      const f = ss[0].innerScale / ss[0].displayScale
      this.layouter_.setPageScale(this.currentPages_, dipsScale * f)
      this.draw()
      if (this.noticeDrawingScale_.length > 0) {
        this.noticeDrawingScale_.forEach(f => {
          f(this.layouter_.currentPageScale)
        })
      }
    }
  }

  /**
   * 印刷時の画像の分割数を指定する
   * horizontal もしくは vertical のどちらかが 0、あるいは、両方 0 の場合は 分割しない（分割解除）とする。
   * horizontal と vertical の両方が 1 の場合は 分割しない（分割解除）とする。
   * @param horizontal 水平方向の分割数
   * @param vertical 垂直方向の分割数
   */
  public setDivision (horizontal = 0, vertical = 0): void{
    if (horizontal === 0 || vertical === 0 || (horizontal === 1 && vertical === 1)) {
      this.printDivisionsFlag_ = false
      if (this.gridLineContext_ && this.gridLineCanvas_) {
        this.gridLineContext_.clearRect(
          0,
          0,
          this.gridLineCanvas_.width,
          this.gridLineCanvas_.height
        )
      }
      this.printDivisionsHorizontal_ = 0
      this.printDivisionsvertical_ = 0
    } else {
      this.printDivisionsFlag_ = true
      this.printDivisionsHorizontal_ = horizontal
      this.printDivisionsvertical_ = vertical
    }
    this.draw()
  }

  /**
   * ガンマフィルターを適用する
   * @param value 変更値 0.05 <= value <= 5.0
   */
  public applyGammaFilter (value: number): void {
    const minValue = 0.05
    const maxValue = 5.0
    if (minValue > value || value > maxValue) {
      return
    }
    if (!this.clientDiv_ || !this.mainCanvas_ || !this.svgFilterRoot_ || !this.svgGammaFilter_) { return }

    // value値をそのままexponentに適用すると現行ビューアと異なる結果になるので調整する
    // valueはGamma値ではなく「概念的な明るさ」を表しているので、小さい値のほうが暗くなる
    // 現行ビューアのexponentの範囲は、 暗 10 >= exponent >= 0.2 明
    const exp = 1.0 / value

    for (let i = 0; i < this.svgGammaFilter_.childElementCount; ++i) {
      (this.svgGammaFilter_.children[i] as SVGFEFuncRElement).setAttribute('exponent', '' + exp)
    }

    if (this.mainCanvas_ && this.changePageQueueHandler_ === 0) {
      this.mainCanvas_.width = this.mainCanvas_.width - 1
      this.mainCanvas_.width = this.mainCanvas_.width + 1
      this.draw()
    }
  }

  /**
   * グレースケールフィルターを適用する
   * @param value 変更値 true, false
   */
  public applyGrayscaleFilter (value: boolean): void {

    if (!this.svgFilterRoot_ || !this.svgGrayscaleFilter_) { return }

    this.svgFilterRoot_.append(this.svgGrayscaleFilter_)

    const grayscaleMatrix = value ? '0.2126 0.7152 0.0722 0 0  0.2126 0.7152 0.0722 0 0  0.2126 0.7152 0.0722 0 0  0 0 0 1 0' : '1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'
    this.svgGrayscaleFilter_.setAttribute('values', grayscaleMatrix)
    
    if (this.mainCanvas_ && this.changePageQueueHandler_ === 0) {
      this.mainCanvas_.width = this.mainCanvas_.width - 1
      this.mainCanvas_.width = this.mainCanvas_.width + 1
      this.draw()
    }
  }

  /**
   * コントラストフィルターを適用する
   * @param value 変更値 -50 <= value <= 100
   */
  public applyContrastFilter (value: number): void {
    if (value < -50 || value > 100) {
      return
    }
    if (!this.clientDiv_ || !this.mainCanvas_ || !this.svgFilterRoot_ || !this.svgContrastFilter_) { return }

    // コントラストは 0% ～ 200%
    if (value < 0) {
      value *= 2
    }

    value += 100
    const v = value / 100

    for (let i = 0; i < this.svgContrastFilter_.childElementCount; ++i) {
      (this.svgContrastFilter_.children[i] as SVGFEFuncRElement).setAttribute('slope', '' + v);
      (this.svgContrastFilter_.children[i] as SVGFEFuncRElement).setAttribute('intercept', '' + (-(0.5 * v) + 0.5))
    }
  }

  /**
   * 明度フィルターを適用する
   * @param value 変更値 -100 <= value <= 100
   */
  public applyBrightnessFilter (value: number): void {
    if (value < -100 || value > 100) {
      return
    }
    if (!this.clientDiv_ || !this.mainCanvas_ || !this.svgFilterRoot_ || !this.svgBrightnessFilter_) { return }

    value += 100
    const v = value / 100
    for (let i = 0; i < this.svgBrightnessFilter_.childElementCount; ++i) {
      (this.svgBrightnessFilter_.children[i] as SVGFEFuncRElement).setAttribute('slope', '' + v)
    }

    if (this.mainCanvas_ && this.changePageQueueHandler_ === 0) {
      this.mainCanvas_.width = this.mainCanvas_.width - 1
      this.mainCanvas_.width = this.mainCanvas_.width + 1
      this.draw()
    }
  }

  /**
   * シャープネスフィルターを適用する
   * @param value シャープネスの強度 0 <= value <= 100 0=フィルターを外す
   * @returns
   */
  public applySharpnessFilter (value: number): void {
    if (value < 0 || value > 100) {
      return
    }
    if (!this.svgFilterRoot_ || !this.svgSharpnessFilter_) { return }

    if (value > 0) {
      // かかり具合は用検討
      const v = value / 100 * 10

      this.svgFilterRoot_.append(this.svgSharpnessFilter_)
      const order = 3
      this.svgSharpnessFilter_.setAttribute('order', `${order} ${order}`)
      const lowValue = -1 * v
      const hightValue = 1 + 4 * v
      const kernelMatrix = `0 , ${lowValue}, 0, ${lowValue}, ${hightValue}, ${lowValue},0, ${lowValue}, 0`
      this.svgSharpnessFilter_.setAttribute('kernelMatrix', kernelMatrix)

      if (this.mainCanvas_) {
        this.mainCanvas_.width = this.mainCanvas_.width - 1
        this.mainCanvas_.width = this.mainCanvas_.width + 1
        this.draw()
      }
    } else {
      try {
        this.svgFilterRoot_.removeChild(this.svgSharpnessFilter_)
      } catch (error) {
        this.dummyFunction()
      }
    }
  }

  /**
   * 印刷用の切り抜き範囲を指定し、同時に切り抜きモードに入る
   * @param left 切り抜きフレームのコマ画像の左上を原点とした左座標 mm
   * @param top 切り抜きフレームのコマ画像の左上を原点とした上座標 mm
   * @param width 印刷横幅 mm centeringが真且つ、heightと共に0の場合は自動サイズとする
   * @param height 印刷高さ mm centeringが真且つ、widthと共に0の場合は自動サイズとする
   * @param label フレーム中央に表示する文字列 A4, B5 などの判型表示用
   * @param centering 切り抜きフレームをセンタリングするかいなか trueの場合は left,top の値は無視される
   * @param dpi コマ画像のdpi
   * @returns 印刷範囲切り抜きモードに入れたか否か
   */
  public setPrintCroppingArea (left: number, top: number, width: number, height: number, label = '', centering = false, dpi = 400): boolean {
    if(!this.content_){
      return false
    }
    if (!this.printCroppingLayer_) {
      return false
    }

    this.printDPI_ = dpi

    this.printCroppingFrameRect_.left = left
    this.printCroppingFrameRect_.top = top

    if(width === 0 && height === 0){
      const adjust = this.layouter_.currentPageScale > 1.0 ? 1.0 / this.layouter_.currentPageScale : 1.0 / this.layouter_.currentPageScale
      width = px2mm(this.canvasSize_.width, this.printDPI_) * adjust * 0.5
      height = px2mm(this.canvasSize_.height, this.printDPI_) * adjust * 0.5
    }


    if (centering) {
      const imageSizeInMM = new Vec2()
      if (this.currentPages_[0]?.koma) {
        imageSizeInMM.x = this.currentPages_[0].koma.originWidth
        imageSizeInMM.y = this.currentPages_[0].koma.originHeight
      }
      if (this.currentPages_[1]?.koma) {
        imageSizeInMM.x += this.currentPages_[1].koma.originWidth
        //imageSizeInMM.y = this.currentPages_[1].koma.originHeight
      }
      if (imageSizeInMM.x <= 0 || imageSizeInMM.y <= 0) {
        return false
      }

      const centerInCanvas = this.layouter_.currentElementCoordinatesOnCenterOfClientArea;
      imageSizeInMM.x = px2mm(imageSizeInMM.x * centerInCanvas.x, this.printDPI_)
      imageSizeInMM.y = px2mm(imageSizeInMM.y * centerInCanvas.y, this.printDPI_)

      
      this.printCroppingFrameRect_.left = (imageSizeInMM.x - width * 0.5)
      this.printCroppingFrameRect_.top = (imageSizeInMM.y - height * 0.5)
    }

    this.printCroppingFrameRect_.right = this.printCroppingFrameRect_.left + width
    this.printCroppingFrameRect_.bottom = this.printCroppingFrameRect_.top + height

    const widthPx = mm2px(this.printCroppingFrameRect_.width, this.printDPI_) * this.layouter_.currentPageScale
    const heightPx = mm2px(this.printCroppingFrameRect_.height, this.printDPI_) * this.layouter_.currentPageScale
    const leftPx = mm2px(this.printCroppingFrameRect_.left, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.x
    const topPx = mm2px(this.printCroppingFrameRect_.top, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.y

    if (!this.printCroppingLayer_.setFrameCoordinate(leftPx, topPx, leftPx + widthPx, topPx + heightPx)) { //, this.printDPI_, this.layouter_.currentPageScale)){
      return false
    }

    this.printCroppingLayer_.setFrameLabel(label)

    this.printCroppingLayer_.frameIsDrawn = true

    this.printCroppingLayer_.enabled = true

    this.disableCropping()

    const onfcl = (rect: PhysicalRect) => {
      const left = px2mm((rect.left - this.layouter_.pagePosition.x) / this.layouter_.currentPageScale, this.printDPI_)
      const top = px2mm((rect.top - this.layouter_.pagePosition.y) / this.layouter_.currentPageScale, this.printDPI_)
      const right = px2mm((rect.right - this.layouter_.pagePosition.x) / this.layouter_.currentPageScale, this.printDPI_)
      const bottom = px2mm((rect.bottom - this.layouter_.pagePosition.y) / this.layouter_.currentPageScale, this.printDPI_)

      this.printCroppingFrameRect_.left = left
      this.printCroppingFrameRect_.top = top
      this.printCroppingFrameRect_.right = right
      this.printCroppingFrameRect_.bottom = bottom

      if (this.noticeChangePrintCropping_.length) {
        this.noticeChangePrintCropping_.forEach(f => {
          f(this.printCroppingFrameRect_)
        })
      }

      this.draw()
    }
    this.printCroppingLayer_.setOnFrameChnageListner(onfcl)
    if (this.printDivisionsFlag_) {
      this.drawAGridLine()
    }

    if (this.noticeChangePrintCropping_.length) {
      this.noticeChangePrintCropping_.forEach(f => {
        f(this.printCroppingFrameRect_)
      })
    }

    this.showRuler()

    return true
  }

  private updatePrintCroppingFrame (): void {
    if (!this.printCroppingLayer_) {
      return
    }
    const widthPx = mm2px(this.printCroppingFrameRect_.width, this.printDPI_) * this.layouter_.currentPageScale
    const heightPx = mm2px(this.printCroppingFrameRect_.height, this.printDPI_) * this.layouter_.currentPageScale
    const leftPx = mm2px(this.printCroppingFrameRect_.left, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.x
    const topPx = mm2px(this.printCroppingFrameRect_.top, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.y

    this.printCroppingLayer_.setFrameCoordinate(leftPx, topPx, leftPx + widthPx, topPx + heightPx)
    // これ以降 printCroppingFrameRect_ を操作してはならない
  }

  /**
   * 印刷切り抜きモードに入る
   */
  public execPrintCroppingMode (): void {
    if (!this.printCroppingLayer_) {
      return
    }

    this.printCroppingLayer_.enabled = true

    if (this.printCroppingFrameRect_.width > 0 && this.printCroppingFrameRect_.height > 0) {
      const widthPx = mm2px(this.printCroppingFrameRect_.width, this.printDPI_) * this.layouter_.currentPageScale
      const heightPx = mm2px(this.printCroppingFrameRect_.height, this.printDPI_) * this.layouter_.currentPageScale
      const leftPx = mm2px(this.printCroppingFrameRect_.left, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.x
      const topPx = mm2px(this.printCroppingFrameRect_.top, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.y

      this.printCroppingLayer_.setFrameCoordinate(leftPx, topPx, leftPx + widthPx, topPx + heightPx)
      this.printCroppingLayer_.frameIsDrawn = true
    }

    this.disableCropping()

    const onfcl = (rect: PhysicalRect) => {
      const left = px2mm((rect.left - this.layouter_.pagePosition.x) / this.layouter_.currentPageScale, this.printDPI_)
      const top = px2mm((rect.top - this.layouter_.pagePosition.y) / this.layouter_.currentPageScale, this.printDPI_)
      const right = px2mm((rect.right - this.layouter_.pagePosition.x) / this.layouter_.currentPageScale, this.printDPI_)
      const bottom = px2mm((rect.bottom - this.layouter_.pagePosition.y) / this.layouter_.currentPageScale, this.printDPI_)

      this.printCroppingFrameRect_.left = left
      this.printCroppingFrameRect_.top = top
      this.printCroppingFrameRect_.right = right
      this.printCroppingFrameRect_.bottom = bottom

      if (this.noticeChangePrintCropping_.length) {
        this.noticeChangePrintCropping_.forEach(f => {
          f(this.printCroppingFrameRect_)
        })
      }

      this.draw()
    }
    this.printCroppingLayer_.setOnFrameChnageListner(onfcl)

    this.showRuler()
  }

  /**
   * 印刷切り抜きモードから抜ける
   */
  public exitPrintCroppingMode (): void {
    if (!this.printCroppingLayer_) {
      return
    }

    this.printCroppingLayer_.enabled = false
    this.printCroppingLayer_.frameIsDrawn = false
    this.printCroppingLayer_.removeOnFrameChangeListener()
    // this.draw()
    this.hideRuler()
  }

  /**
   * 切り抜きモードを介さず、直接範囲指定と切り抜き実行を行う
   * @param left 切り抜きフレームのコマ画像の左上を原点とした左座標 mm
   * @param top 切り抜きフレームのコマ画像の左上を原点とした上座標 mm
   * @param width 印刷横幅 mm
   * @param height 印刷高さ mm
   * @param label フレーム中央に表示する文字列 A4, B5 などの判型表示用
   * @param dpi コマ画像のdpi
   * @returns 画像が切り抜けたか否か
   */
  public cropArea (left: number, top: number, width: number, height: number, label = '', dpi = 400): boolean {
    this.printDPI_ = dpi

    if (width > 0 && height > 0) {
      this.printCroppingFrameRect_.left = left
      this.printCroppingFrameRect_.top = top
      this.printCroppingFrameRect_.right = this.printCroppingFrameRect_.left + width
      this.printCroppingFrameRect_.bottom = this.printCroppingFrameRect_.top + height

      this.layouter_.setCroppingArea(
        this.printCroppingFrameRect_.left,
        this.printCroppingFrameRect_.top,
        this.printCroppingFrameRect_.width,
        this.printCroppingFrameRect_.height
      )

      if (this.printCroppingLayer_) {
        this.printCroppingLayer_.setFrameLabel(label)
      }

      this.exitPrintCroppingMode()
      this.draw()
      return true
    }

    return false
  }

  /**
   * 現在の選択範囲で切り抜き、印刷切り抜きモードから抜ける
   * @returns 選択範囲がある場合はtrue 選択範囲がない場合はfalse
   */
  public cropWithCurrentArea (): boolean {
    if (this.printCroppingFrameRect_.width > 0 && this.printCroppingFrameRect_.height > 0) {
      this.layouter_.setCroppingArea(
        this.printCroppingFrameRect_.left,
        this.printCroppingFrameRect_.top,
        this.printCroppingFrameRect_.width,
        this.printCroppingFrameRect_.height
      )
      this.exitPrintCroppingMode()
      this.draw()
      return true
    }

    return false
  }

  /**
   * 現在の選択範囲切り抜き（クロッピング）を無効にする。
   * 無効にしても、選択範囲自体は記録される。
   */
  public disableCropping (): void{
    this.layouter_.setCroppingArea()
    this.draw()
  }

  /**
   * 切り抜き範囲のビットマップを dataURLで返す
   * @returns 有効な選択範囲が存在する場合はその領域をPNG化したdataURL, ビットマップが取得できない場合は空文字
   */
  public getCroppingAreaBitmap (): string {
    if (this.printCroppingLayer_ && this.printCroppingLayer_.enabled && this.mainCanvas_) {
      const widthPx = mm2px(this.printCroppingFrameRect_.width, this.printDPI_) * this.layouter_.currentPageScale
      const heightPx = mm2px(this.printCroppingFrameRect_.height, this.printDPI_) * this.layouter_.currentPageScale
      const leftPx = mm2px(this.printCroppingFrameRect_.left, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.x
      const topPx = mm2px(this.printCroppingFrameRect_.top, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.y

      if (widthPx > 0 && heightPx > 0) {
        const cropCanvas = document.createElement('canvas')
        cropCanvas.width = widthPx
        cropCanvas.height = heightPx
        const ccc = cropCanvas.getContext('2d')
        if (ccc) {
          ccc.drawImage(this.mainCanvas_, leftPx, topPx, widthPx, heightPx, 0, 0, widthPx, heightPx)
          return cropCanvas.toDataURL('image/png')
        } else {
          return ''
        }
      } else {
        return ''
      }
    }

    return ''
  }

  /**
   * 画面上にルーラーを表示する
   */
  private showRuler (): void{
    this.showRuler_ = true
    this.draw()
  }

  /**
   * 画面上のルーラーを非表示にする
   */
  private hideRuler ():void {
    this.showRuler_ = false
    if (this.rulerCanvas_ && this.rulerContext_) {
      this.rulerContext_.clearRect(
        0,
        0,
        this.rulerCanvas_.width,
        this.rulerCanvas_.height
      )
    }
    this.draw()
  }

  /**
   * 画像上に点線を描画する
   */
  private drawAGridLine (): void {
    if (!this.content_) {
      return
    }

    const ep = this.layouter_.getElementPlacement(this.currentPages_, this.content_.bindingDirection, this.layouter_.currentPageScale)
    if (this.gridLineContext_ && this.gridLineCanvas_) {
      this.gridLineContext_.clearRect(
        0,
        0,
        this.gridLineCanvas_.width,
        this.gridLineCanvas_.height
      )
      this.gridLineContext_.setLineDash([5, 10])
      this.gridLineContext_.lineWidth = 2
      this.gridLineContext_.strokeStyle = '#3F8CD6'
      if (this.printCroppingLayer_ && !this.printCroppingLayer_.enabled && !this.printCroppingLayer_.frameIsDrawn) {
        this.gridLineContext_.strokeRect(ep.left, ep.top, ep.width, ep.height)
        // 垂直方向の線
        for (let i = 1; i < this.printDivisionsvertical_; i++) {
          this.gridLineContext_.beginPath()
          this.gridLineContext_.moveTo(ep.left, ep.height * i / this.printDivisionsvertical_ + ep.top)
          this.gridLineContext_.lineTo(ep.left + ep.width, ep.height * i / this.printDivisionsvertical_ + ep.top)
          this.gridLineContext_.closePath()
          this.gridLineContext_.stroke()
        }
        // 水平方向の線
        for (let i = 1; i < this.printDivisionsHorizontal_; i++) {
          this.gridLineContext_.beginPath()
          this.gridLineContext_.moveTo(ep.width * i / this.printDivisionsHorizontal_ + ep.left, ep.top)
          this.gridLineContext_.lineTo(ep.width * i / this.printDivisionsHorizontal_ + ep.left, ep.height + ep.top)
          this.gridLineContext_.closePath()
          this.gridLineContext_.stroke()
        }
      } else if (this.printCroppingLayer_ && this.printCroppingLayer_.enabled && this.printCroppingLayer_.frameIsDrawn) {
        // 画像の切り抜き範囲が指定されていて、フレームが描画されている場合
        const widthPx = mm2px(this.printCroppingFrameRect_.width, this.printDPI_) * this.layouter_.currentPageScale
        const heightPx = mm2px(this.printCroppingFrameRect_.height, this.printDPI_) * this.layouter_.currentPageScale
        const leftPx = mm2px(this.printCroppingFrameRect_.left, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.x
        const topPx = mm2px(this.printCroppingFrameRect_.top, this.printDPI_) * this.layouter_.currentPageScale + this.layouter_.pagePosition.y
        this.gridLineContext_.strokeRect(leftPx, topPx, widthPx, heightPx)
        // 垂直方向の線
        for (let i = 1; i < this.printDivisionsvertical_; i++) {
          this.gridLineContext_.beginPath()
          this.gridLineContext_.moveTo(leftPx, heightPx * i / this.printDivisionsvertical_ + topPx)
          this.gridLineContext_.lineTo(leftPx + widthPx, heightPx * i / this.printDivisionsvertical_ + topPx)
          this.gridLineContext_.closePath()
          this.gridLineContext_.stroke()
        }
        // 水平方向の線
        for (let i = 1; i < this.printDivisionsHorizontal_; i++) {
          this.gridLineContext_.beginPath()
          this.gridLineContext_.moveTo(widthPx * i / this.printDivisionsHorizontal_ + leftPx, topPx)
          this.gridLineContext_.lineTo(widthPx * i / this.printDivisionsHorizontal_ + leftPx, heightPx + topPx)
          this.gridLineContext_.closePath()
          this.gridLineContext_.stroke()
        }
      }
    }
  }

  /**
   * 画像上にルーラーを描画する
   */
  private drawRuler (): void {
    if (!this.content_) {
      return
    }
    const ep = this.layouter_.getElementPlacement(this.currentPages_, this.content_.bindingDirection, this.layouter_.currentPageScale)
    const ep1 = this.layouter_.getElementPlacement(this.currentPages_, this.content_.bindingDirection, 1)
    if (this.rulerContext_ && this.rulerCanvas_) {
      this.rulerContext_.clearRect(
        0,
        0,
        this.rulerCanvas_.width,
        this.rulerCanvas_.height
      )

      this.rulerContext_.lineWidth = 40
      this.rulerContext_.strokeStyle = this.currentStyleModeColor.rulerBackgroundColor
      this.rulerContext_.beginPath()
      this.rulerContext_.moveTo(0, 0)
      this.rulerContext_.lineTo(this.rulerCanvas_.width, 0)
      this.rulerContext_.lineTo(0, 0)
      this.rulerContext_.lineTo(0, this.rulerCanvas_.height)
      this.rulerContext_.closePath()
      this.rulerContext_.stroke()

      const cmw = ep1.width / mm2px(10, this.printDPI_)
      const cmh = ep1.height / mm2px(10, this.printDPI_)
      const mm2w = ep1.width / mm2px(2, this.printDPI_)
      const mm2h = ep1.height / mm2px(2, this.printDPI_)
      const mmw = ep1.width / mm2px(1, this.printDPI_)
      const mmh = ep1.height / mm2px(1, this.printDPI_)
      const eptop = ep.top
      const epleft = ep.left
      const epheight = ep.height
      const epwidth = ep.width

      this.rulerContext_.lineWidth = 2
      this.rulerContext_.strokeStyle = this.currentStyleModeColor.rulerColor

      // 目盛り（cm）
      for (let i = 0; i < 500; i++) {
        this.rulerContext_.moveTo(0, (epheight * i / cmh) + eptop)
        this.rulerContext_.lineTo(20, (epheight * i / cmh) + eptop)

        this.rulerContext_.moveTo(0, (-epheight * i / cmh) + eptop)
        this.rulerContext_.lineTo(20, (-epheight * i / cmh) + eptop)

        this.rulerContext_.moveTo((epwidth * i / cmw) + epleft, 0)
        this.rulerContext_.lineTo((epwidth * i / cmw) + epleft, 20)

        this.rulerContext_.moveTo(-epwidth * i / cmw + epleft, 0)
        this.rulerContext_.lineTo(-epwidth * i / cmw + epleft, 20)
      }

      this.rulerContext_.font = '10pt Arial'
      this.rulerContext_.fillStyle = this.currentStyleModeColor.rulerColor

      if (this.layouter_.currentPageScale > 0.2) {
        for (let i = 0; i < 500; i++) {
          this.rulerContext_.fillText(`${i}`, 5, (epheight * i / cmh) + eptop + 1, mmw)
          this.rulerContext_.fillText(`${-i}`, 5, (-epheight * i / cmh) + eptop + 1, mmw)
          this.rulerContext_.fillText(`${i}`, (epwidth * i / cmw) + epleft + 5, 20, mmw)
          this.rulerContext_.fillText(`${-i}`, (-epwidth * i / cmw) + epleft + 5, 20, mmw)
        }
      }

      this.rulerContext_.closePath()
      this.rulerContext_.stroke()

      this.rulerContext_.lineWidth = 1

      if (this.layouter_.currentPageScale > 0.3) {
        if (this.layouter_.currentPageScale > 0.6) {
          for (let i = 0; i < 5000; ++i) {
            this.rulerContext_.moveTo(10, (epheight * i / mmh) + eptop)
            this.rulerContext_.lineTo(20, (epheight * i / mmh) + eptop)

            this.rulerContext_.moveTo(10, (-epheight * i / mmh) + eptop)
            this.rulerContext_.lineTo(20, (-epheight * i / mmh) + eptop)

            this.rulerContext_.moveTo(epwidth * i / mmw + ep.left, 10)
            this.rulerContext_.lineTo(epwidth * i / mmw + ep.left, 20)

            this.rulerContext_.moveTo(-epwidth * i / mmw + epleft, 10)
            this.rulerContext_.lineTo(-epwidth * i / mmw + epleft, 20)
          }
        } else if (this.layouter_.currentPageScale < 0.6 && this.layouter_.currentPageScale > 0.3) {
          for (let i = 0; i < 5000; i++) {
            this.rulerContext_.moveTo(10, (epheight * i / mm2h) + eptop)
            this.rulerContext_.lineTo(20, (epheight * i / mm2h) + eptop)

            this.rulerContext_.moveTo(10, (-epheight * i / mm2h) + eptop)
            this.rulerContext_.lineTo(20, (-epheight * i / mm2h) + eptop)

            this.rulerContext_.moveTo(epwidth * i / mm2w + epleft, 10)
            this.rulerContext_.lineTo(epwidth * i / mm2w + epleft, 20)

            this.rulerContext_.moveTo(-epwidth * i / mm2w + epleft, 10)
            this.rulerContext_.lineTo(-epwidth * i / mm2w + epleft, 20)
          }
        }
      }

      this.rulerContext_.closePath()
      this.rulerContext_.stroke()

      this.rulerContext_.beginPath()
      this.rulerContext_.rect(0, 0, 20, 20)
      this.rulerContext_.fillStyle = this.currentStyleModeColor.rulerBackgroundColor
      this.rulerContext_.fill()
      this.rulerContext_.stroke()
    }
  }

  /*************************************************/

  /**
   * 現在のページが変更された時に、新しいページインデックス通知を受け取るイベントリスナーを登録する
   * @param listener
   */
  public addNoticeCurrentPageIndexListener (listener: (pageIndex: number, komaID: number) => void): void {
    let cancel = false
    this.noticeChangedPage_.some(f => {
      if (f === listener) {
        cancel = true
        return true
      }
    })

    if (!cancel) {
      this.noticeChangedPage_.push(listener)
    }
  }

  /**
   * 登録済み NoticeCurrentPageIndexListener を削除する
   * @param listener 削除するイベントリスナー
   */
  public removeNoticeCurrentPageIndexListener (listener: (pageIndex: number, komaID: number) => void): void {
    let index = -1

    for (let i = 0; i < this.noticeChangedPage_.length; ++i) {
      if (this.noticeChangedPage_[i] === listener) {
        index = i
        break
      }
    }

    if (index >= 0) {
      this.noticeChangedPage_.splice(index, 1)
    }
  }

  /**
   * 描画スケールが変更されたときに新しい描画スケール通知を受け取るイベントリスナーを登録する
   * @param listener
   */
  public addNoticeCurrentDrawingScaleListener (listener: (scale: number) => void): void {
    let cancel = false
    this.noticeDrawingScale_.some(f => {
      if (f === listener) {
        cancel = true
        return true
      }
    })

    if (!cancel) {
      this.noticeDrawingScale_.push(listener)
    }
  }

  /**
   * 登録済み NoticeCurrentDrawingScaleListener を削除する
   * @param listener 削除するイベントリスナー
   */
  public removeNoticeCurrentDrawingScaleListener (listener: (scale: number) => void): void {
    let index = -1

    for (let i = 0; i < this.noticeDrawingScale_.length; ++i) {
      if (this.noticeDrawingScale_[i] === listener) {
        index = i
        break
      }
    }

    if (index >= 0) {
      this.noticeDrawingScale_.splice(index, 1)
    }
  }

  /**
   * 描画スケールステップが変更されたときに新しい描画スケールステップ通知を受け取るイベントリスナーを登録する
   * @param listener
   */
  public addNoticeNewScaleStepsListener (listener: (stepNotice: ScaleStepNotice) => void): void {
    let cancel = false
    this.noticeNewScaleSteps_.some(f => {
      if (f === listener) {
        cancel = true
        return true
      }
    })

    if (!cancel) {
      this.noticeNewScaleSteps_.push(listener)
    }
  }

  /**
   * 登録済み NoticeNewScaleStepsListener を削除する
   * @param listener 削除するイベントリスナー
   */
  public removeNoticeNewScaleStepsListener (listener: (stepNotice: ScaleStepNotice) => void): void {
    let index = -1

    for (let i = 0; i < this.noticeNewScaleSteps_.length; ++i) {
      if (this.noticeNewScaleSteps_[i] === listener) {
        index = i
        break
      }
    }

    if (index >= 0) {
      this.noticeNewScaleSteps_.splice(index, 1)
    }
  }

  /**
   * 画面中央をタップ／クリックされたときに通知を受け取るイベントリスナーを登録する
   * @param listener
   */
  public addNoticeTapTheCenterListener (listener: () => void): void {
    let cancel = false
    this.noticeTapTheCenter_.some(f => {
      if (f === listener) {
        cancel = true
        return true
      }
    })

    if (!cancel) {
      this.noticeTapTheCenter_.push(listener)
    }
  }

  /**
   * 登録済み NoticeTapTheCenterListener を削除する
   * @param listener 削除するイベントリスナー
   */
  public removeNoticeTapTheCenterListener (listener: () => void): void {
    let index = -1

    for (let i = 0; i < this.noticeTapTheCenter_.length; ++i) {
      if (this.noticeTapTheCenter_[i] === listener) {
        index = i
        break
      }
    }

    if (index >= 0) {
      this.noticeTapTheCenter_.splice(index, 1)
    }
  }

  /**
   * 印刷範囲変更を通知するイベントリスナーを登録する
   * @param listener
   */
  public addNoticeChangePrintCroppingListener (listener: (rectMM: RectMM) => void): void {
    let cancel = false
    this.noticeChangePrintCropping_.some(f => {
      if (f === listener) {
        cancel = true
        return true
      }
    })

    if (!cancel) {
      this.noticeChangePrintCropping_.push(listener)
    }
  }

  /**
   * 登録済み NoticeChangePrintCroppingListener を削除する
   * @param listener 削除するイベントリスナー
   */
  public removeNoticeChangePrintCroppingListener (listener: () => void): void {
    let index = -1

    for (let i = 0; i < this.noticeChangePrintCropping_.length; ++i) {
      if (this.noticeChangePrintCropping_[i] === listener) {
        index = i
        break
      }
    }

    if (index >= 0) {
      this.noticeChangePrintCropping_.splice(index, 1)
    }
  }

  /*************************************************/

  private initSVGFilter (): boolean {
    if (!this.mainCanvas_) { return false }

    this.svgFilterRoot_ = undefined

    let elem = document.getElementById('iv_svg_root') as unknown
    if (!elem) { return false }

    elem = document.getElementById('iv_mainfilter') as unknown
    if (!elem) { return false }

    this.svgFilterRoot_ = elem as SVGFilterElement

    this.svgGammaFilter_ = this.svgFilterRoot_.children[0] as SVGFEComponentTransferElement
    this.svgContrastFilter_ = this.svgFilterRoot_.children[1] as SVGFEComponentTransferElement
    this.svgBrightnessFilter_ = this.svgFilterRoot_.children[2] as SVGFEComponentTransferElement
    this.svgGrayscaleFilter_ = this.svgFilterRoot_.children[3] as SVGFEColorMatrixElement

    if (this.svgFilterRoot_.children[4] !== undefined) {
      this.svgSharpnessFilter_ = this.svgFilterRoot_.children[4] as SVGFEConvolveMatrixElement
      try {
        this.svgFilterRoot_.removeChild(this.svgSharpnessFilter_)
      } catch (error) {
        this.dummyFunction()
      }
    }

    this.mainCanvas_.style.filter = "url('#iv_mainfilter')"

    return true
  }

  private initPrintCroppingLayer (): void {
    this.printCroppingLayer_ = new PrintCroppingLayer(this.inputManager_)

    if (this.clientDiv_) {
      this.printCroppingLayer_.injectTo(this.clientDiv_, this.rulerCanvas_)
    }
  }

  /**
   * エラーを表示する
   */
  private showError (): void {
    ImageViewer.Log('Error: ' + this.lastError_, true)
  }

  /**
   * HTML要素を初期化する
   * @returns エラーコード
   */
  private initElements (): IVError {
    this.setupMainCanvas()

    if (this.lastError_ !== IVError.NONE) {
      return this.lastError_
    }

    // 起動時のスタイルモードを取得する
    this.updateStyleMode()

    return (this.lastError_ = IVError.NONE)
  }

  private initEvents (): void {
    const layerIndex = 0
    this.inputManager_.attachEventListener(layerIndex, 'click', (event, pm) => { return this.onClick(event as MouseEvent, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'mousedown', (event, pm) => { return this.onPointerDown(event, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'touchstart', (event, pm) => { return this.onPointerDown(event, pm) })

    this.inputManager_.attachEventListener(layerIndex, 'mousemove', (event, pm) => { return this.onPointerMove(event, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'touchmove', (event, pm) => { return this.onPointerMove(event, pm) })

    this.inputManager_.attachEventListener(layerIndex, 'mouseup', (event, pm) => { return this.onPointerUp(event, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'touchend', (event, pm) => { return this.onPointerUp(event, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'wheel', (event, pm) => { return this.onWheel(event as WheelEvent, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'mouseleave', (event, pm) => { return this.onMouseLeave(event as MouseEvent, pm) })
    this.inputManager_.attachEventListener(layerIndex, 'dblclick', (event, pm) => { return this.onDblClick(event as MouseEvent, pm) })
  }

  private setupMainCanvas (): IVError {
    if (this.clientDiv_) {
      this.mainCanvas_ = document.createElement('canvas') as HTMLCanvasElement
      this.mainCanvas_.oncontextmenu = function () { return false }
      const cStyle = this.mainCanvas_.style
      cStyle.margin = '0'
      cStyle.padding = '0'
      cStyle.width = '100%'
      cStyle.height = '100%'
      cStyle.position = 'absolute'
      cStyle.left = '0'
      cStyle.top = '0'
      cStyle.cursor = 'grab'
      const c = this.mainCanvas_.getContext('2d')

      this.gridLineCanvas_ = document.createElement('canvas') as HTMLCanvasElement
      const gcStyle = this.gridLineCanvas_.style
      gcStyle.margin = '0'
      gcStyle.padding = '0'
      gcStyle.width = '100%'
      gcStyle.height = '100%'
      gcStyle.position = 'absolute'
      gcStyle.left = '0'
      gcStyle.top = '0'
      gcStyle.cursor = 'grab'

      this.gridLineCanvas_.oncontextmenu = function () { return false }
      const gc = this.gridLineCanvas_.getContext('2d')

      this.rulerCanvas_ = document.createElement('canvas') as HTMLCanvasElement
      this.rulerCanvas_.className = 'ruler'
      const rcStyle = this.rulerCanvas_.style
      rcStyle.margin = '0'
      rcStyle.padding = '0'
      rcStyle.width = '100%'
      rcStyle.height = '100%'
      rcStyle.position = 'absolute'
      rcStyle.left = '0'
      rcStyle.top = '0'
      rcStyle.cursor = 'grab'
      rcStyle.zIndex = '1'
      this.rulerCanvas_.oncontextmenu = function () { return false }
      const rc = this.rulerCanvas_.getContext('2d')

      if (c) {
        this.context_ = c
      } else {
        return (this.lastError_ = IVError.FAILED_TO_INIT_COMPONENT)
      }

      if (gc) {
        this.gridLineContext_ = gc
      }

      if (rc) {
        this.rulerContext_ = rc
      }

      this.clientDiv_.insertBefore(this.mainCanvas_, this.inputManager_.pointerManager.toCoverMainCanvas as Node)
      this.clientDiv_.insertBefore(this.gridLineCanvas_, this.inputManager_.pointerManager.toCoverMainCanvas as Node)

      this.clientDiv_.insertBefore(this.rulerCanvas_, this.inputManager_.pointerManager.toCoverMainCanvas as Node)
      /*
      this.mainCanvasResizeObserver_ = new ResizeObserver(() => {
        this.onResize()
      })
      this.mainCanvasResizeObserver_.observe(this.clientDiv_)
      } */
      this.resizeEvent = (ev) => {
        this.onResize()
      }
      window.addEventListener('resize', this.resizeEvent)

      return (this.lastError_ = IVError.NONE)
    }

    return (this.lastError_ = IVError.FAILED_TO_INIT_COMPONENT)
  }

  /**
   * 単ページ・見開き表示の切り替えを処理する
   * @param newViewMode 新しく設定するビューモード
   */
  public changeViewMode (newViewMode: ViewMode): boolean {
    if (!this.content_) {
      return false
    }
    if (this.currentViewMode_ === newViewMode) {
      return false
    }
    if (this.currentViewMode_ === ViewMode.SINGLE || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H) {
      if (newViewMode === ViewMode.KOMA || newViewMode === ViewMode.SCROLL_KOMA_V || newViewMode === ViewMode.SCROLL_KOMA_H) {
        // 片ページから１コマ表示へ変更
        if (this.content_) {
          this.currentPageIndex_ = this.content_.convertKomaToKomaIndex(this.currentPages_[0]?.koma)

          this.currentPages_[0] = this.currentPages_[0]?.koma
          this.currentPages_[1] = undefined

          this.currentViewMode_ = newViewMode
          this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
        } else {
          return false
        }
      } else if (newViewMode === ViewMode.TOW_IN_ONE || newViewMode === ViewMode.SCROLL_TOW_IN_ONE_V || newViewMode === ViewMode.SCROLL_TOW_IN_ONE_H) {
        // 片ページから2in1
        if (this.content_) {
          const pair = this.content_.convertElementToTwoInOne(this.currentPages_[0])
          if (pair) {
            this.currentPages_[0] = pair.komaPairs[0]
            this.currentPages_[1] = pair.komaPairs[1]

            this.currentPageIndex_ = pair.twoInOneIndex// this.content_.getTwoInOnePairIndex(pair)
            this.currentViewMode_ = newViewMode
            this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
          } else {
            return false
          }
        }
      } else {
        this.currentViewMode_ = newViewMode
      }
    } else if (this.currentViewMode_ === ViewMode.KOMA || this.currentViewMode_ === ViewMode.SCROLL_KOMA_V || this.currentViewMode_ === ViewMode.SCROLL_KOMA_H) {
      if (newViewMode === ViewMode.SINGLE || newViewMode === ViewMode.SCROLL_SINGLE_V || newViewMode === ViewMode.SCROLL_SINGLE_H) {
        // 1コマ表示から単ページ表示へ変更
        this.currentPageIndex_ = ~~Math.floor(this.currentPageIndex_ * 2)
        if (this.currentPageIndex_ < 0) {
          this.currentPageIndex_ = 0
        }

        if (this.content_) {
          const pageID = this.content_.convertKomaToPageID(this.currentPages_[0] as KomaElement)
          if (pageID < 0) {
            return false
          }
          this.currentPages_[0] = this.content_.getPageByPageID(pageID)
          this.currentPages_[1] = undefined

          this.currentViewMode_ = newViewMode
          this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
        } else {
          return false
        }
      } else if (newViewMode === ViewMode.TOW_IN_ONE || newViewMode === ViewMode.SCROLL_TOW_IN_ONE_V || newViewMode === ViewMode.SCROLL_TOW_IN_ONE_H) {
        // 1コマ表示ページから2in1
        if (this.content_) {
          const pair = this.content_.convertElementToTwoInOne(this.currentPages_[0])
          if (pair) {
            this.currentPages_[0] = pair.komaPairs[0]
            this.currentPages_[1] = pair.komaPairs[1]

            this.currentPageIndex_ = pair.twoInOneIndex// this.content_.getTwoInOnePairIndex(pair)
            this.currentViewMode_ = newViewMode
            this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
          } else {
            return false
          }
        }
      } else {
        this.currentViewMode_ = newViewMode
      }
    } else if (this.currentViewMode_ === ViewMode.TOW_IN_ONE || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_V || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_H) {
      if (newViewMode === ViewMode.SINGLE || newViewMode === ViewMode.SCROLL_SINGLE_V || newViewMode === ViewMode.SCROLL_SINGLE_H) {
        // 2in1表示から片ページ表示へ変更
        if (this.content_) {
          const pages = this.content_.getAllPagesThatReferToTheKoma(this.currentPages_[0]?.koma)
          if (pages) {
            this.currentPages_[0] = pages[0]
            this.currentPages_[1] = undefined

            this.currentPageIndex_ = this.content_.getPageIndex(pages[0])
            this.currentViewMode_ = newViewMode
            this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
          } else {
            return false
          }
        }
      } else if (newViewMode === ViewMode.KOMA || newViewMode === ViewMode.SCROLL_KOMA_V || newViewMode === ViewMode.SCROLL_KOMA_H) {
        // 2in1表示から１コマ表示へ変更
        if (this.content_) {
          this.currentPages_[1] = undefined

          this.currentPageIndex_ = this.content_.convertKomaToKomaIndex(this.currentPages_[0]?.koma)
          this.currentViewMode_ = newViewMode
          this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))
        } else {
          return false
        }
      } else {
        this.currentViewMode_ = newViewMode
      }
    }

    if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
      this.layouter_.centering(this.currentPages_, this.content_.bindingDirection)
      this.layouter_.keepFitScale = true
    }

    this.updateCurrentKomaIDs()

    this.disableCropping()

    this.draw()

    this.updateScaleSteps()

    this.preloadKoma()

    return true
  }

  private updateCurrentKomaIDs (): void {
    this.currentKomaIDs_[0] = this.currentKomaIDs_[0] = -1
    if (this.currentPages_[0] && this.currentPages_[0].koma) {
      this.currentKomaIDs_[0] = this.currentPages_[0].koma.komaID
    }
    if (this.currentPages_[1] && this.currentPages_[1].koma) {
      this.currentKomaIDs_[1] = this.currentPages_[1].koma.komaID
    }
  }

  /**
   * 現在表示中の画像の縮小版 ImageData を返す
   * @param size 取得する ImageData の長辺ピクセル数 デフォルト値は 128
   * @returns 成功した場合、指定した size の ImageData を、失敗した場合は undefined を返す
   */
  public getImageData (size = 128): ImageData | undefined {
    if (!this.content_ || (this.currentPages_[0] === undefined && this.currentPages_[1] === undefined)) {
      return undefined
    }

    const bb = this.layouter_.getElementBoundingBox(this.currentPages_, this.layouter_.currentPageScale, this.layouter_.currentCropping, true, this.layouter_.enableAutoClipping)
    const whRatio = bb.height / bb.width
    const canvas = document.createElement('canvas')
    if (bb.width > bb.height) {
      canvas.width = size
      canvas.height = size * whRatio
    } else {
      canvas.height = size
      canvas.width = size / whRatio
    }

    const ctx = canvas.getContext('2d')

    if (ctx) {
      const layouter = new Layouter()
      layouter.initLayout()
      layouter.setCanvasSize(canvas.width, canvas.height)
      layouter.copyStateFrom(this.layouter_)
      layouter.backGroundColor = 'transparent'
      layouter.centering(this.currentPages_, this.content_.bindingDirection)

      if (layouter.draw(ctx, this.currentPages_, undefined, true, undefined, undefined, true) !== DrawResult.Successed) {
        return undefined
      }
    }

    return ctx?.getImageData(0, 0, canvas.width, canvas.height)
  }

  /**
   * 閲覧制限用トークンを更新する
   * @param newToken 新しく設定しなおすトークンの配列
   */
  public updateToken (newToken: Array<AccessToken>): void {
    if (this.content_) {
      this.content_.updateToken(newToken)
    }
  }

  /**
   * 再描画要求をポストする
   * @param retry 描画が成功するまでリトライする
   */
  private postRedrawRequest (retry = true): void {
    if (this.redrawRequestHandler_ === 0) {
      this.redrawRequestHandler_ = setTimeout(() => {
        const state = this.draw()
        if (state === DrawResult.Retry && retry) {
          this.redrawRequestHandler_ = 0
          this.postRedrawRequest()
        } else {
          this.redrawRequestHandler_ = 0
        }
      },
      this.redrawRequestTimeout_)
    }
  }

  /**
   * 現在のページを描画する
   */
  private draw (clear = false, onlyLowTile = false): DrawResult {
    if (this.changePageQueueHandler_ !== 0) {
      return DrawResult.Retry
    }

    if (this.layouter_.currentPageScale <= 0) {
      if (this.context_) {
        this.layouter_.clearCanvas(this.context_)
      }
      return DrawResult.Failed
    }

    if (this.content_ && this.mainCanvas_ && this.context_) {
      if (clear) {
        this.context_.clearRect(
          0,
          0,
          this.mainCanvas_.width,
          this.mainCanvas_.height
        )
      }

      // 描画処理自体はLayouterに任せる
      const dr = this.layouter_.draw(
        this.context_,
        this.currentPages_,
        () => this.draw(clear, onlyLowTile),
        true,
        undefined,
        undefined,
        onlyLowTile//! (this.pointerManager_.eneblPointCount <= 0)
      )

      if (dr === DrawResult.Retry) {
        return dr
      }

      this.currentVisibledKomaIDRange_[0] = this.currentKomaIDs_[0]
      this.currentVisibledKomaIDRange_[1] = this.currentKomaIDs_[1]

      // スクロールモードの場合は前後画像を描画する
      if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
        const safeMargin = 10 * DeviceInfo.DPR
        const offset = new PhysicalPosition()
        const currentPageFitScale = this.layouter_.getFitScale(this.currentPages_, this.content_.bindingDirection)
        let changePageIndex = -1
        let chnagePagePosX = 0
        let chnagePagePosY = 0
        let changePageDirectScale = 0

        const currentElementBB = this.layouter_.getElementBoundingBox(this.currentPages_, this.layouter_.currentPageScale, this.layouter_.currentCropping, true, this.layouter_.enableAutoClipping)

        const centerX = this.layouter_.pagePosition.x + currentElementBB.width * 0.5 + currentElementBB.offset.left

        // ページスクロールの場合
        if (this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
          // 前のページ
          if (this.currentPageIndex_ > 0) {
            switch (this.currentViewMode_) {
              case ViewMode.SCROLL_SINGLE_V:
              case ViewMode.SCROLL_KOMA_V:
              case ViewMode.SCROLL_TOW_IN_ONE_V:
                offset.y += currentElementBB.offset.top
                break

              case ViewMode.SCROLL_SINGLE_H:
              case ViewMode.SCROLL_KOMA_H:
              case ViewMode.SCROLL_TOW_IN_ONE_H:
                if (this.content_.bindingDirection === Binding.ltr) {
                  offset.x += currentElementBB.offset.left
                } else {
                  offset.x += currentElementBB.width + currentElementBB.offset.left
                }
                offset.y = currentElementBB.offset.top
                break
            }

            // 最大8ページ
            for (let i = 1; i <= 8; ++i) {
              let elements
              if (this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H) {
                elements = []
                elements[0] = this.content_.getPageByPageIndex(this.currentPageIndex_ - i)
              } else if (this.currentViewMode_ === ViewMode.SCROLL_KOMA_V || this.currentViewMode_ === ViewMode.SCROLL_KOMA_H) {
                elements = []
                elements[0] = this.content_.getKomaElementByIndex(this.currentPageIndex_ - i)
              } else {
                const pair = this.content_.getTwoInOnePair(this.currentPageIndex_ - i)
                if (pair) {
                  elements = pair.komaPairs
                }
              }
              if (elements) {
                let directScale = this.layouter_.getFitScale(elements, this.content_.bindingDirection)
                const sizeDiff = directScale / currentPageFitScale
                directScale = (this.layouter_.currentPageScale * sizeDiff)
                const scrollElemSize = this.layouter_.getElementBoundingBox(elements, directScale, this.layouter_.currentCropping, true, this.layouter_.enableAutoClipping)

                const scrollCenterX = this.layouter_.pagePosition.x + scrollElemSize.width * 0.5 + scrollElemSize.offset.left

                switch (this.currentViewMode_) {
                  case ViewMode.SCROLL_SINGLE_V:
                  case ViewMode.SCROLL_KOMA_V:
                  case ViewMode.SCROLL_TOW_IN_ONE_V:
                    offset.x += centerX - scrollCenterX
                    offset.y -= (scrollElemSize.height + scrollElemSize.offset.top)
                    break
                  case ViewMode.SCROLL_SINGLE_H:
                  case ViewMode.SCROLL_KOMA_H:
                  case ViewMode.SCROLL_TOW_IN_ONE_H:
                    if (this.content_.bindingDirection === Binding.ltr) {
                      offset.x -= (scrollElemSize.width + scrollElemSize.offset.left)
                    } else {
                      offset.x -= scrollElemSize.offset.left
                    }
                    offset.y += (currentElementBB.height - scrollElemSize.height) * 0.5 - scrollElemSize.offset.top
                    break
                }

                switch (this.currentViewMode_) {
                  case ViewMode.SCROLL_SINGLE_V:
                  case ViewMode.SCROLL_KOMA_V:
                  case ViewMode.SCROLL_TOW_IN_ONE_V:

                    if ((this.layouter_.pagePosition.y + offset.y + scrollElemSize.offset.top + safeMargin) < this.canvasSize_.height * 0.5 &&
                      (this.layouter_.pagePosition.y + offset.y + scrollElemSize.offset.top + scrollElemSize.height - safeMargin * 2) > this.canvasSize_.height * 0.5) {
                      const x = offset.x + this.layouter_.pagePosition.x
                      const y = offset.y + this.layouter_.pagePosition.y
                      const index = this.currentPageIndex_ - i
                      if (!this.inertialControll_.isRunning) {
                        requestAnimationFrame(() => {
                          this.layouter_.setPagePositon(this.currentPages_, new PhysicalPosition(x, y))
                          this.changePageIndex(index, directScale, true, true)
                        })
                        return DrawResult.Successed
                      } else {
                        changePageIndex = index
                        chnagePagePosX = x
                        chnagePagePosY = y
                        changePageDirectScale = directScale
                      }
                    }

                    break
                  case ViewMode.SCROLL_SINGLE_H:
                  case ViewMode.SCROLL_KOMA_H:
                  case ViewMode.SCROLL_TOW_IN_ONE_H:

                    if ((this.layouter_.pagePosition.x + offset.x + scrollElemSize.offset.left + safeMargin) < this.canvasSize_.width * 0.5 &&
                      (this.layouter_.pagePosition.x + offset.x + scrollElemSize.offset.left + scrollElemSize.width - safeMargin * 2) > this.canvasSize_.width * 0.5) {
                      const x = offset.x + this.layouter_.pagePosition.x
                      const y = offset.y + this.layouter_.pagePosition.y
                      const index = this.currentPageIndex_ - i
                      if (!this.inertialControll_.isRunning) {
                        requestAnimationFrame(() => {
                          this.layouter_.setPagePositon(this.currentPages_, new PhysicalPosition(x, y))
                          this.changePageIndex(index, directScale, true, true)
                        })
                        return DrawResult.Successed
                      } else {
                        changePageIndex = index
                        chnagePagePosX = x
                        chnagePagePosY = y
                        changePageDirectScale = directScale
                      }
                    }

                    break
                }

                if (DrawResult.Failed === this.layouter_.draw(
                  this.context_,
                  elements,
                  () => this.draw(clear, onlyLowTile),
                  false,
                  directScale,
                  offset,
                  onlyLowTile
                )) {
                  break
                }

                switch (this.currentViewMode_) {
                  case ViewMode.SCROLL_SINGLE_V:
                  case ViewMode.SCROLL_KOMA_V:
                  case ViewMode.SCROLL_TOW_IN_ONE_V:
                    offset.x -= centerX - scrollCenterX
                    offset.y += scrollElemSize.offset.top
                    break
                  case ViewMode.SCROLL_SINGLE_H:
                  case ViewMode.SCROLL_KOMA_H:
                  case ViewMode.SCROLL_TOW_IN_ONE_H:
                    if (this.content_.bindingDirection === Binding.ltr) {
                      offset.x += scrollElemSize.offset.left
                    } else {
                      offset.x += scrollElemSize.width + scrollElemSize.offset.left
                    }
                    offset.y += scrollElemSize.offset.top
                    break
                }
              } else {
                break
              }

              this.currentVisibledKomaIDRange_[0] = this.currentPageIndex_ - i
            }
          }
          // 次のページ
          if (this.currentPageIndex_ < this.content_.getPageCount(this.currentViewMode_)) {
            const offset = new PhysicalPosition()

            switch (this.currentViewMode_) {
              case ViewMode.SCROLL_SINGLE_V:
              case ViewMode.SCROLL_KOMA_V:
              case ViewMode.SCROLL_TOW_IN_ONE_V:
                offset.y += (currentElementBB.height + currentElementBB.offset.top)
                break
              case ViewMode.SCROLL_SINGLE_H:
              case ViewMode.SCROLL_KOMA_H:
              case ViewMode.SCROLL_TOW_IN_ONE_H:
                if (this.content_.bindingDirection === Binding.ltr) {
                  offset.x = currentElementBB.width + currentElementBB.offset.left
                } else {
                  offset.x += currentElementBB.offset.left
                }
                offset.y = currentElementBB.offset.top
                break
            }

            // 最大8ページ
            for (let i = 1; i <= 8; ++i) {
              let elements
              if (this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H) {
                elements = []
                elements[0] = this.content_.getPageByPageIndex(this.currentPageIndex_ + i)
              } else if (this.currentViewMode_ === ViewMode.SCROLL_KOMA_V || this.currentViewMode_ === ViewMode.SCROLL_KOMA_H) {
                elements = []
                elements[0] = this.content_.getKomaElementByIndex(this.currentPageIndex_ + i)
              } else {
                const pair = this.content_.getTwoInOnePair(this.currentPageIndex_ + i)
                if (pair) {
                  elements = pair.komaPairs
                }
              }

              if (elements) {
                let directScale = this.layouter_.getFitScale(elements, this.content_.bindingDirection)
                const sizeDiff = directScale / currentPageFitScale
                directScale = (this.layouter_.currentPageScale * sizeDiff)

                const scrollElemSize = this.layouter_.getElementBoundingBox(elements, directScale, this.layouter_.currentCropping, true, this.layouter_.enableAutoClipping)

                const scrollCenterX = this.layouter_.pagePosition.x + scrollElemSize.width * 0.5 + scrollElemSize.offset.left

                switch (this.currentViewMode_) {
                  case ViewMode.SCROLL_SINGLE_V:
                  case ViewMode.SCROLL_KOMA_V:
                  case ViewMode.SCROLL_TOW_IN_ONE_V:
                    offset.x += centerX - scrollCenterX
                    offset.y -= scrollElemSize.offset.top

                    if ((this.layouter_.pagePosition.y + offset.y + scrollElemSize.offset.top + safeMargin) < this.canvasSize_.height * 0.5 &&
                      (this.layouter_.pagePosition.y + offset.y + scrollElemSize.offset.top + scrollElemSize.height - safeMargin * 2) > this.canvasSize_.height * 0.5) {
                      const x = offset.x + this.layouter_.pagePosition.x
                      const y = offset.y + this.layouter_.pagePosition.y
                      const index = this.currentPageIndex_ + i
                      if (!this.inertialControll_.isRunning) {
                        requestAnimationFrame(() => {
                          this.layouter_.setPagePositon(this.currentPages_, new PhysicalPosition(x, y))
                          this.changePageIndex(index, directScale, true, true)
                        })
                        return DrawResult.Successed
                      } else {
                        changePageIndex = index
                        chnagePagePosX = x
                        chnagePagePosY = y
                        changePageDirectScale = directScale
                      }
                    }

                    break
                  case ViewMode.SCROLL_SINGLE_H:
                  case ViewMode.SCROLL_KOMA_H:
                  case ViewMode.SCROLL_TOW_IN_ONE_H:
                    if (this.content_.bindingDirection === Binding.ltr) {
                      offset.x -= scrollElemSize.offset.left
                    } else {
                      offset.x -= (scrollElemSize.width + scrollElemSize.offset.left)
                    }
                    offset.y += (currentElementBB.height - scrollElemSize.height) * 0.5 - scrollElemSize.offset.top

                    if ((this.layouter_.pagePosition.x + offset.x + scrollElemSize.offset.left + safeMargin) < this.canvasSize_.width * 0.5 &&
                      (this.layouter_.pagePosition.x + offset.x + scrollElemSize.offset.left + scrollElemSize.width - safeMargin * 2) > this.canvasSize_.width * 0.5) {
                      const x = offset.x + this.layouter_.pagePosition.x
                      const y = offset.y + this.layouter_.pagePosition.y
                      const index = this.currentPageIndex_ + i
                      if (!this.inertialControll_.isRunning) {
                        requestAnimationFrame(() => {
                          this.layouter_.setPagePositon(this.currentPages_, new PhysicalPosition(x, y))
                          this.changePageIndex(index, directScale, true, true)
                        })
                        return DrawResult.Successed
                      } else {
                        changePageIndex = index
                        chnagePagePosX = x
                        chnagePagePosY = y
                        changePageDirectScale = directScale
                      }
                    }

                    break
                }

                if (DrawResult.Failed === this.layouter_.draw(
                  this.context_,
                  elements,
                  () => this.draw(clear, onlyLowTile),
                  false,
                  directScale,
                  offset,
                  onlyLowTile
                )) {
                  break
                }

                switch (this.currentViewMode_) {
                  case ViewMode.SCROLL_SINGLE_V:
                  case ViewMode.SCROLL_KOMA_V:
                  case ViewMode.SCROLL_TOW_IN_ONE_V:
                    offset.x -= centerX - scrollCenterX
                    offset.y += (currentElementBB.height + scrollElemSize.offset.top)
                    break
                  case ViewMode.SCROLL_SINGLE_H:
                  case ViewMode.SCROLL_KOMA_H:
                  case ViewMode.SCROLL_TOW_IN_ONE_H:
                    if (this.content_.bindingDirection === Binding.ltr) {
                      offset.x += (scrollElemSize.width + scrollElemSize.offset.left)
                    } else {
                      offset.x += scrollElemSize.offset.left
                    }
                    offset.y += scrollElemSize.offset.top
                    break
                }
              } else {
                break
              }

              this.currentVisibledKomaIDRange_[1] = this.currentPageIndex_ + i
            }
          }
        }

        if (changePageIndex >= 0) {
          this.layouter_.setPagePositon(this.currentPages_, new PhysicalPosition(chnagePagePosX, chnagePagePosY))
          this.changePageIndex(changePageIndex, changePageDirectScale, false, true)
        }
        // this.__debug3(centerX-1, 0, 2, this.canvasSize_.height)
      }

      if (this.printCroppingLayer_ && this.printCroppingLayer_.enabled && this.printCroppingLayer_.frameIsDrawn) {
        this.updatePrintCroppingFrame()
      }
      if (this.printDivisionsFlag_) {
        this.drawAGridLine()
      }
      if (this.showRuler_) {
        this.drawRuler()
      }
    }
    return DrawResult.Successed
  }

  /**
   * content.jsonオブジェクトからビューアを起動する
   * @param contentObj content.jsonオブジェクト
   * @returns
   */
  private async setupFromContentJsonObj (contentObj: any): Promise<boolean> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return new Promise<boolean>((resolve, reject) => {
      const cs = ContentStructure.fromJSONObject(contentObj)
      if (!cs) {
        this.lastError_ = IVError.INVALID_CONTENT
        this.showError()
        return reject(new Error('Invalid Content Structure'))
      }
      return this.setContent(cs)
        .catch((reason) => {
          // 特定のエラーコードが格納されていなければ、FAILED_TO_LOAD_CONTENT を代入する
          if (this.lastError_ === IVError.NONE) {
            this.lastError_ = IVError.FAILED_TO_LOAD_CONTENT
          }
          this.showError()
          reject(reason)
        })
    })
  }

  /**
   * コンテンツを初期化して表示可能な状態にする
   * @param cs
   */
  private setContent (cs: ContentStructure): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.content_ = cs
      this.content_
        .init()
        .then(() => {
          if (this.content_) {
            // しおりやページ指定があればここで処理する

            // 初期表示モード指定
            this.currentViewMode_ = ViewMode.KOMA
            if (this.viewerOption_) {
              this.currentViewMode_ = this.viewerOption_.initialViewMode

              // 初期切り抜き指定
              if (this.viewerOption_.initalCropping) {
                const ic = this.viewerOption_.initalCropping
                this.cropArea(ic.left, ic.top, ic.width, ic.height, ic.label, ic.dpi)
              }
            }

            this.currentPageIndex_ = 0
            // 初期ページが指定されたいたらそれを使用する
            if (this.viewerOption_) {
              if (this.viewerOption_.initialPageIndex >= 0) {
                if (this.viewerOption_.initialPageIndex < this.content_.getPageCount(this.currentViewMode_)) {
                  this.currentPageIndex_ = this.viewerOption_.initialPageIndex
                } else {
                  this.lastError_ = IVError.INVALID_PAGE_INDEX
                  reject(new Error('Invalid Page Index'))
                  return
                }
              } else {
                this.lastError_ = IVError.INVALID_PAGE_INDEX
                reject(new Error('Invalid Page Index'))
                return
              }
            }

            this.layouter_.currentPageBinding = this.content_.bindingDirection

            if (this.currentViewMode_ === ViewMode.KOMA) {
              const m = this.content_.getKomaElementByIndex(this.currentPageIndex_)
              if (m) {
                this.currentPages_[0] = m
                this.currentPages_[1] = undefined
              } else {
                this.lastError_ = IVError.INVALID_CONTENT
                this.showError()
              }
            } else if (this.currentViewMode_ === ViewMode.SINGLE) {
              this.currentPages_[0] = this.content_.getPageElementByIndex(
                this.currentPageIndex_
              )
              if (!this.currentPages_[0]) {
                this.lastError_ = IVError.INVALID_CONTENT
                this.showError()
              }
              this.currentPages_[1] = undefined
            } else if (this.currentViewMode_ === ViewMode.TOW_IN_ONE) {
              const pair = this.content_.getTwoInOnePair(this.currentPageIndex_)
              if (pair) {
                this.currentPages_[0] = pair.komaPairs[0]
                this.currentPages_[1] = pair.komaPairs[1]
              } else {
                this.lastError_ = IVError.INVALID_CONTENT
                this.showError()
              }
            }

            this.updateCurrentKomaIDs()

            if (this.currentPages_[0]) {
              const eachTileCallback = (success: boolean, tile: TileDrawer) => {
                if (success) {
                  // ダウンロード完了の場合のみ描画する
                  if (tile.dlProgress === DLProgress.Done) {
                    this.layouter_.centering(this.currentPages_, this.content_!.bindingDirection)
                    this.draw()
                    this.updateScaleSteps()

                    if (this.noticeDrawingScale_.length > 0) {
                      this.noticeDrawingScale_.forEach(f => {
                        f(this.layouter_.currentPageScale)
                      })
                    }

                    if (this.noticeChangedPage_.length) {
                      this.noticeChangedPage_.forEach(f => {
                        f(this.currentPageIndex_, this.currentKomaIDs_[0])
                      })
                    }
                  }
                  resolve(true)
                } else {
                  this.lastError_ = IVError.FAILED_TO_LOAD_RESOURCE
                  this.showError()
                  reject(new Error('Failed to init the content'))
                }
              }

              if (this.currentPages_[1]) {
                this.currentPages_[1].activate(() => this.content_!.updateDammyKomaResolution(), eachTileCallback)
              }

              this.currentPages_[0].activate(() => this.content_!.updateDammyKomaResolution(), eachTileCallback)
            }
          }

          this.preloadKoma()
        })
        .catch((reason) => {
          this.lastError_ = IVError.INVALID_CONTENT
          this.showError()
          reject(reason)
        })
    })
  }

  /**
   * コマ画像を先読みする
   */
  private preloadKoma (): void {
    if (!this.content_) return

    const dummyKomaResolutionUpdate = () => {
      return this.content_!.updateDammyKomaResolution()
    }

    const pageCount = this.content_.getPageCount(this.currentViewMode_)

    const callback = (success: boolean, tileDrawer: TileDrawer) => {
      if (this.currentVisibledKomaIDRange_[0] <= tileDrawer.komaID && tileDrawer.komaID <= this.currentVisibledKomaIDRange_[1]) {
        this.draw()
      }
    }

    // 先方・後方最小1コマ、最大4コマを先読みする
    if (this.currentViewMode_ === ViewMode.SINGLE) {
      // for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 4); ++i) {
      //   if (i >= pageCount) {
      //     break
      //   }

      if ((this.currentPageIndex_ + 1) < pageCount) {
        const p = this.content_.getPageByPageIndex(this.currentPageIndex_ + 1)
        if (p) {
          p.activate(() => this.content_!.updateDammyKomaResolution(), callback)
        }
      }
      if ((this.currentPageIndex_ - 1) >= 0) {
        const p = this.content_.getPageByPageIndex(this.currentPageIndex_ - 1)
        if (p) {
          p.activate(() => this.content_!.updateDammyKomaResolution(), callback)
        }
      }

      // }
    } else if (this.currentViewMode_ === ViewMode.KOMA) {
      // for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 2); ++i) {
      //   if (i >= pageCount) {
      //     break
      //   }

      if ((this.currentPageIndex_ + 1) < pageCount) {
        const m = this.content_.getKomaElementByIndex(this.currentPageIndex_ + 1)
        if (m) {
          m.activate(dummyKomaResolutionUpdate, callback)
        }
      }
      if ((this.currentPageIndex_ - 1) >= 0) {
        const m = this.content_.getKomaElementByIndex(this.currentPageIndex_ - 1)
        if (m) {
          m.activate(dummyKomaResolutionUpdate, callback)
        }
      }
      // }
    } else if (this.currentViewMode_ === ViewMode.TOW_IN_ONE) {
      // for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 2); ++i) {
      let tio = this.content_.getTwoInOnePair(this.currentPageIndex_ + 1)
      if (tio) {
        if (tio.komaPairs[0]) {
          tio.komaPairs[0].activate(dummyKomaResolutionUpdate, callback)
        }
        if (tio.komaPairs[1]) {
          tio.komaPairs[1].activate(dummyKomaResolutionUpdate, callback)
        }
      }

      tio = this.content_.getTwoInOnePair(this.currentPageIndex_ - 1)
      if (tio) {
        if (tio.komaPairs[0]) {
          tio.komaPairs[0].activate(dummyKomaResolutionUpdate, callback)
        }
        if (tio.komaPairs[1]) {
          tio.komaPairs[1].activate(dummyKomaResolutionUpdate, callback)
        }
      }
      // }
    } else if (this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H) {
      // 前後ページを先読みする
      for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 2); ++i) {
        if (i >= pageCount) {
          break
        }
        const p = this.content_.getPageByPageIndex(i)
        if (p) {
          p.activate(dummyKomaResolutionUpdate, callback)
        }
      }

      for (let i = (this.currentPageIndex_ - 1); i >= (this.currentPageIndex_ - 2); --i) {
        if (i < 0) {
          break
        }
        const p = this.content_.getPageByPageIndex(i)
        if (p) {
          p.activate(dummyKomaResolutionUpdate, callback)
        }
      }
    } else if (this.currentViewMode_ === ViewMode.SCROLL_KOMA_V || this.currentViewMode_ === ViewMode.SCROLL_KOMA_H) {
      // 前後ページを先読みする
      for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 4); ++i) {
        if (i >= pageCount) {
          break
        }
        const p = this.content_.getKomaElementByIndex(i)
        if (p) {
          p.activate(dummyKomaResolutionUpdate, callback)
        }
      }

      for (let i = (this.currentPageIndex_ - 1); i >= (this.currentPageIndex_ - 4); --i) {
        if (i < 0) {
          break
        }
        const p = this.content_.getKomaElementByIndex(i)
        if (p) {
          p.activate(dummyKomaResolutionUpdate, callback)
        }
      }
    } else if (this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_V || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_H) {
      // 前後ページを先読みする
      for (let i = (this.currentPageIndex_ + 1); i <= (this.currentPageIndex_ + 2); ++i) {
        const tio = this.content_.getTwoInOnePair(i)
        if (tio) {
          if (tio.komaPairs[0]) {
            tio.komaPairs[0].activate(dummyKomaResolutionUpdate, callback)
          }
          if (tio.komaPairs[1]) {
            tio.komaPairs[1].activate(dummyKomaResolutionUpdate, callback)
          }
        }
      }

      for (let i = (this.currentPageIndex_ - 1); i >= (this.currentPageIndex_ - 2); --i) {
        const tio = this.content_.getTwoInOnePair(i)
        if (tio) {
          if (tio.komaPairs[0]) {
            tio.komaPairs[0].activate(dummyKomaResolutionUpdate, callback)
          }
          if (tio.komaPairs[1]) {
            tio.komaPairs[1].activate(dummyKomaResolutionUpdate, callback)
          }
        }
      }
    }
  }

  private changePageIndex (pageIndex: number, scale?:number, draw = true, byScrolling = false): boolean {
    if (!this.content_) return false

    // ページ範囲のチェック
    const pageCount = this.content_.getPageCount(this.currentViewMode_)
    if (pageIndex >= pageCount) {
      return false
    }
    if (pageIndex < 0) {
      return false
    }

    if (this.currentViewMode_ === ViewMode.SINGLE || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_V || this.currentViewMode_ === ViewMode.SCROLL_SINGLE_H) {
      const a = this.content_.getPageByPageIndex(pageIndex)
      if (pageIndex === this.currentPageIndex_ && this.currentPages_[0] === a && this.currentPages_[1] === undefined) {
        return false
      }
      this.currentPages_[0] = a
      this.currentPages_[1] = undefined
    } else if (this.currentViewMode_ === ViewMode.KOMA || this.currentViewMode_ === ViewMode.SCROLL_KOMA_V || this.currentViewMode_ === ViewMode.SCROLL_KOMA_H) {
      const m = this.content_.getKomaElementByIndex(pageIndex)
      if (m) { // 最低限1ページは存在する
        if (pageIndex === this.currentPageIndex_ && this.currentPages_[0] === m && this.currentPages_[1] === undefined) {
          return false
        }
        this.currentPages_[0] = m
        this.currentPages_[1] = undefined
      } else {
        return false
      }
    } else if (this.currentViewMode_ === ViewMode.TOW_IN_ONE || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_V || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_H) {
      const p = this.content_.getTwoInOnePair(pageIndex)
      if (p) {
        if (pageIndex === this.currentPageIndex_ && this.currentPages_[0] === p.komaPairs[0] && this.currentPages_[1] === p.komaPairs[1]) {
          return false
        }

        this.currentPages_[0] = p.komaPairs[0]
        this.currentPages_[1] = p.komaPairs[1]
      } else {
        return false
      }
    }

    if (scale && this.currentViewMode_ >= ViewMode.SCROLL_SINGLE_V) {
      this.layouter_.currentPageScale = scale
    }
    this.currentPageIndex_ = pageIndex

    this.updateCurrentKomaIDs()

    // 連打抑止
    if (byScrolling) {
      this.executeChangePageDraw(draw)
    } else {
      if (this.changePageQueueHandler_ !== 0) {
        clearTimeout(this.changePageQueueHandler_)
        this.changePageQueueHandler_ = 0
      }

      this.changePageQueueHandler_ = setTimeout(() => {
        this.executeChangePageDraw(draw)
      },
      this.changePageTimeout_)
    }

    return true
  }

  private executeChangePageDraw (draw: boolean): void {
    this.changePageQueueHandler_ = 0
    this.updateScaleSteps()

    this.currentPages_.forEach((p) => {
      if (p) {
        p.activate(() => this.content_!.updateDammyKomaResolution(), (success, tile) => {
          if (draw && success) {
            if (tile.dlProgress === DLProgress.Done || tile.dlProgress === DLProgress.InProgress) {
              this.postRedrawRequest()
            }
          }
        })
      }
    })

    // コマ画像の先読み
    this.preloadKoma()

    if (this.noticeChangedPage_.length > 0) {
      this.noticeChangedPage_.forEach(f => {
        f(this.currentPageIndex_, this.currentKomaIDs_[0])
      })
    }
  }

  /**
   * スケールステップを更新する
   */
  private updateScaleSteps (): void{
    const defaultScaleDownSteps = 2
    const maxScale = 4.0

    if (this.content_) {
      this.currentScaleSteps_.scaleSteps.length = 0

      const fitScale = this.layouter_.getFitScale(this.currentPages_, this.content_.bindingDirection)
      if (fitScale <= 0) {
        return
      }

      // fitScaleがmaxScale以上の場合
      if (fitScale >= maxScale) {
        // アップ側 なし

        // フィット倍率
        const ss = new ScaleStep()
        ss.innerScale = fitScale
        ss.displayScale = 1.0
        this.currentScaleSteps_.fitScaleIndex = this.currentScaleSteps_.scaleSteps.length
        this.currentScaleSteps_.scaleSteps.push(ss)

        // ダウン側
        for (let i = 0; i < defaultScaleDownSteps; ++i) {
          const ss = new ScaleStep()
          ss.innerScale = fitScale / ((i + 1) * 2)
          ss.displayScale = 1.0 / ((i + 1) * 2)
          this.currentScaleSteps_.scaleSteps.push(ss)
        }
      } else {
        const baseStepScale = 0.36 // １ステップの拡大差分値の目安 拡大ステップ数を調整するときはここを変更する
        let steps = Math.ceil((maxScale - fitScale) / baseStepScale)
        if (steps === Infinity) {
          return
        }
        let microSteps = 0
        let microStepCutoff = 1
        if (fitScale < 1.0) {
          steps = Math.ceil((maxScale - 1.0) / baseStepScale)
          microSteps = 1.0 / fitScale
        }

        if (microSteps === Infinity) {
          return
        }

        if (microSteps > 10) {
          microStepCutoff = 0
        }

        microSteps = Math.ceil(microSteps / 2)

        steps += microSteps

        const s = maxScale - fitScale
        // アップ側
        for (let i = steps - 1; i >= microStepCutoff; --i) {
          const ss = new ScaleStep()
          ss.innerScale = s * (((i + 1) / steps) * ((i + 1) / steps)) + fitScale
          ss.displayScale = ss.innerScale / fitScale
          this.currentScaleSteps_.scaleSteps.push(ss)
        }

        // フィット倍率
        const ss = new ScaleStep()
        ss.innerScale = fitScale
        ss.displayScale = 1.0
        this.currentScaleSteps_.fitScaleIndex = this.currentScaleSteps_.scaleSteps.length
        this.currentScaleSteps_.scaleSteps.push(ss)

        // ダウン側
        for (let i = 0; i < defaultScaleDownSteps; ++i) {
          const ss = new ScaleStep()
          ss.innerScale = fitScale / ((i + 1) * 2)
          ss.displayScale = 1.0 / ((i + 1) * 2)
          this.currentScaleSteps_.scaleSteps.push(ss)
        }
      }

      if (this.noticeNewScaleSteps_.length > 0) {
        this.currentScaleSteps_.currentInnerScale = this.layouter_.currentPageScale
        this.currentScaleStepsBuf_.copy(this.currentScaleSteps_)
        this.noticeNewScaleSteps_.forEach(f => {
          f(this.currentScaleStepsBuf_)
        })
      }
    }
  }

  /**
   * ダーク・ライトモードの変更をビューアに通知する
   * @returns
   */
  private updateStyleMode (): void{
    if (!this.clientDiv_) {
      return
    }

    // ルーラー
    let sampler = this.clientDiv_.parentElement?.parentElement?.querySelector('#viewer-ruler-color-sampling-element')
    if (sampler) {
      const cs = getComputedStyle(sampler)
      this.currentStyleModeColor.rulerBackgroundColor = cs.backgroundColor
      this.currentStyleModeColor.rulerColor = cs.color
    }

    // Canvas背景
    sampler = this.clientDiv_.parentElement?.parentElement?.querySelector('#viewer-background-color-sampling-element')
    if (sampler) {
      const cs = getComputedStyle(sampler)
      this.layouter_.backGroundColor = cs.backgroundColor
    }

    this.draw()
  }

  /**
   * ビューアキャンバスサイズの変更をビューアに通知する
   */
  public viewerSizeChanged ():void{
    this.onResize()
  }

  /**
   * 2in1時の表紙の扱いを変更する
   * @param mode
   */
  public changeCoverPageMode (mode: CoverPageMode): void {
    if (this.content_) {
      this.content_.currentCoverPageMode = mode

      if (this.currentViewMode_ === ViewMode.TOW_IN_ONE || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_H || this.currentViewMode_ === ViewMode.SCROLL_TOW_IN_ONE_V) {
        // 新しい TwoInOne Pair を取得する
        const pair = this.content_.convertElementToTwoInOne(this.currentPages_[0])
        if (pair) {
          this.currentPages_[0] = pair.komaPairs[0]
          this.currentPages_[1] = pair.komaPairs[1]

          this.currentPageIndex_ = pair.twoInOneIndex// this.content_.getTwoInOnePairIndex(pair)
          this.layouter_.keepCenterPosition(this.currentPages_, this.content_.bindingDirection, this.layouter_.getCenterPosition(this.currentPages_))

          this.updateCurrentKomaIDs()

          this.draw()
        }
      }
    }
  }

  /**
 * 表示中の１コマをオリジナル解像度でDLする
 * 切り抜き範囲が適用されます
 * @returns 
 */
  private getFullImage(): void {
    if(!this.content_)
      return

    const koma = this.currentPages_[0]?.koma
    if(!koma)
      return

    const canvas = document.createElement("canvas")
    canvas.width = koma.originWidth
    canvas.height = koma.originHeight

    const ctx = canvas.getContext('2d')

    if (ctx) {
      const layouter = new Layouter()
      layouter.initLayout()
      layouter.setCanvasSize(canvas.width, canvas.height)
      layouter.copyStateFrom(this.layouter_)
      layouter.backGroundColor = 'transparent'
      layouter.centering(this.currentPages_, this.content_.bindingDirection)

      if (DrawResult.Successed !== layouter.draw(ctx, this.currentPages_, undefined, true, undefined, undefined, true)) {
        return
      }

      canvas.toBlob(blob => {
        if(!blob)
            return

        const a = document.createElement("a")
        a.download = "image.png"
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = ["image/png", a.download, a.href].join(":");
        a.click()

      })

      
    }
    return
  }

  /**
   * image_renderer.py 用 order.json をJSON文字列を返す
   * 注）KomaのSrcと、透かし文字列は決め打ちです。適宜変更してください。
   * @returns 
   */
  public getRendererOrder(): string {
    const res = new Object() as any

    const crop = this.layouter_.currentCropping
    if(crop.width > 0 && crop.height > 0){
      res.Frame = {
        Left: crop.left,
        Top: crop.top,
        Width: crop.width,
        Height: crop.height
      }
    }
    
    if(this.layouter_.currentPageBinding === Binding.ltr){
      res.Binding = "ltr"
    }
    else{
      res.Binding = "rtl"
    }


    let gamma = 1.0
    if(this.svgGammaFilter_){
      const v = (this.svgGammaFilter_.children[0] as SVGFEFuncRElement).getAttribute('exponent')
      if(v){
        gamma = Number.parseFloat(v)
        gamma = 1 / gamma
      }
    }

    let bright = 0.0
    if(this.svgBrightnessFilter_){
      let v = (this.svgBrightnessFilter_.children[0] as SVGFEFuncRElement).getAttribute('slope')
      if(v){
        bright = Number.parseFloat(v)
        bright *= 100
        bright -= 100
      }
    }

    let cont = 0.0
    if(this.svgContrastFilter_){
      const v = (this.svgContrastFilter_.children[0] as SVGFEFuncRElement).getAttribute('slope')
      if(v){
        cont = Number.parseFloat(v)
        cont *= 100
        cont -= 100
        if(cont < 0){
          cont /= 2
        }
      }
    }

    let sharp = 0.0
    if(this.svgSharpnessFilter_ && this.svgSharpnessFilter_.parentElement){
      const v = this.svgSharpnessFilter_.getAttribute('kernelMatrix')
      if(v){
        const kmv = v.replaceAll(' ', '').split(',')
        sharp = Number.parseFloat(kmv[1])
        sharp *= -1
        sharp *= 10
      }
    }

    res.Filter = {
      Gamma: gamma,
      Brightness: bright,
      Contrast: cont,
      Sharpness: sharp
    }

    res.RotationAngle = (this.layouter_ as any).currentRotation_.angle
    if((this.layouter_ as any).enableAutoClipping_){
      res.RotationAngle += this.currentPages_[0]!.antiTiltAngle
    }

    res.Division = {
      X: this.printDivisionsHorizontal_,
      Y: this.printDivisionsvertical_
    }

    let velem = this.currentPages_[0]
    let k = velem!.koma

    let area = {
      Left: velem!.usingKomaLeft,
      Top: velem!.usingKomaTop,
      Right: velem!.usingKomaRight,
      Bottom: velem!.usingKomaBottom
    }
    // 余白削除
    if((this.layouter_ as any).enableAutoClipping_){
      const p = this.currentPages_[0]

      const lt = p!.clippingArea!.vertices[0]
      if((k!.originWidth * area.Left) < lt.x){
        area.Left = lt.x / k!.originWidth
      }
      if((k!.originHeight * area.Top) < lt.y){
        area.Top = lt.y / k!.originHeight
      }

      const rb = p!.clippingArea!.vertices[2]
      if((k!.originWidth * area.Right) > rb.x){
        area.Right = rb.x / k!.originWidth
      }
      if((k!.originHeight * area.Bottom) > rb.y){
        area.Bottom = rb.y / k!.originHeight
      }

    }

    res.Komas = [
      {
        Src: "../rsrc/image01.png",
        SrcDPI: k?.dpi,
        UsingSrcArea: area
      }
    ]

    

    if(this.currentPages_[1]){

      velem = this.currentPages_[1]
      k = velem!.koma
      area = {
        Left: velem!.usingKomaLeft,
        Top: velem!.usingKomaTop,
        Right: velem!.usingKomaRight,
        Bottom: velem!.usingKomaBottom
      }
      // 余白削除
      if((this.layouter_ as any).enableAutoClipping_){
        const p = this.currentPages_[1]

        const lt = p!.clippingArea!.vertices[0]
        if((k!.originWidth * area.Left) < lt.x){
          area.Left = lt.x / k!.originWidth
        }
        if((k!.originHeight * area.Top) < lt.y){
          area.Top = lt.y / k!.originHeight
        }

        const rb = p!.clippingArea!.vertices[2]
        if((k!.originWidth * area.Right) > rb.x){
          area.Right = rb.x / k!.originWidth
        }
        if((k!.originHeight * area.Bottom) > rb.y){
          area.Bottom = rb.y / k!.originHeight
        }

      }


      res.Komas.push(
        {
          Src: "../rsrc/image02.png",
          SrcDPI: k?.dpi,
          UsingSrcArea: area
        }
      )
    }

    res.WatermarkHeader = "0123ABCDRF-カタカナ-漢字"
    res.WatermarkFooter = "あいうえお-ABCdefg/0123@図書"

    return JSON.stringify(res)
  }
}

export { ImageViewer, ImageViewerOption, ViewMode, ScaleStep, ScaleStepNotice, InitialCropping }
