import { Content } from '@/data/@types/Content'
import { ClippingData } from '@/data/@types/ClippingData'
import { Page } from '@/data/@types/Page'
import { PageObject } from '@/data/@types/PageObject'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'

/**
 * 2in1表示時の画像ビューアが持つコマ番号を取得
 * @param index アイテムが持つコマ番号
 * @param addCoverPageFlag 先頭が1コマのみの場合True
 * @return 2in1表示時に画像ビューアが持つコマ番号
 */
export const getTwoInOnePageIndex = (index: number, addCoverPageFlag = false): number => {
  return addCoverPageFlag ? Math.ceil(index / 2) : Math.floor(index / 2)
}

/**
 * 片ページ表示時の画像ビューアが持つコマ番号を取得
 * @param アイテムが持つコマ番号
 * @param 'router情報'
 * @param ページめくり方向
 * @param 片ページの情報
 * @return 画像ビューアが持つコマ番号
 */
export const getSingleModePageIndex = (contentIndex: number, route: any, bindingDirection: 'ltr' | 'rtl', pages: PageObject): number => {
  const pageSide = route.fullPath.split('?page=').pop()
  if (bindingDirection === 'ltr') {
    const targetPageIndex = pages.pagesDataLTR.findIndex(pages => pages.Koma === contentIndex)
    if (pageSide === 'right') {
      if (pages.pagesDataLTR?.[targetPageIndex + 1]?.Koma !== contentIndex) {
        return targetPageIndex // コマを分割していない場合
      } else return targetPageIndex + 1 // ページを分割している場合
    } else {
      return targetPageIndex
    }
  } else {
    const targetPageIndex = pages.pagesDataRTL.findIndex(pages => pages.Koma === contentIndex)
    if (pageSide === 'left') {
      if (pages.pagesDataRTL?.[targetPageIndex + 1]?.Koma !== contentIndex) {
        return targetPageIndex // コマを分割していない場合
      } else return targetPageIndex + 1 // ページを分割している場合
    } else {
      return targetPageIndex
    }
  }
}

/**
 * 片ページ表示時にルーターのパラメータに付与するページの対象情報の取得
 * @param ページの配列番号
 * @param コマ番号
 * @param ページめくり方向
 * @param 片ページの情報
 * @return パラメータに付与するページの対象情報
 */
export const getSingleModePageSideParam = (pageIndex: number, komaId: number, bindingDirection: 'ltr' | 'rtl', pages: PageObject) => {
  if (Number(localStorage.viewMode) === ViewMode.KOMA) return ''
  else if (bindingDirection === 'ltr') {
    if (pages.pagesDataLTR?.[pageIndex + 1]?.Koma === komaId) {
      // 次のページが同じコマのコンテンツの場合
      return '?page=left'
    } else return '?page=right'
  } else {
    if (pages.pagesDataRTL?.[pageIndex + 1]?.Koma === komaId) {
      // 次のページが同じコマのコンテンツの場合
      return '?page=right'
    } else return '?page=left'
  }
}

/**
 * コマが持つ余白情報を返す
 * @returns 余白情報
 */
export const getClippingData = (content: Content): ClippingData | null => {
  // TODO: 余白情報のフィールド名と値の形式が変わるため(pixel -> percent)、DMSの対応後に修正
  if (!content.extra?.layout?.main) return null
  const pixelData = content.extra.layout.main
  return {
    Left: Math.min(pixelData.x / 100, 1),
    Top: Math.min(pixelData.y / 100, 1),
    Right: Math.min((pixelData.w + pixelData.x) / 100, 1),
    Bottom: Math.min((pixelData.h + pixelData.y) / 100, 1),
  }
}

/**
 * 画像ビューアに渡す片ページの情報(PagesLTR PagesRTL)を返す
 * @returns 片ページの情報(PagesLTR PagesRTL)
 */
export const getPagesData = (contents: Array<Content>, bindingDirection: 'ltr' | 'rtl'): PageObject => {
  const pagesDataLTR: Array<Page> = [] // 左->右めくり情報
  const pagesDataRTL: Array<Page> = [] // 右->左めくり情報
  contents.map((content, i) => {
    const divideData = content?.extra?.layout?.divide
    const rightRotationData = content?.extra?.layout?.rightRotation?.degree
    const leftRotationData = content?.extra?.layout?.leftRotation?.degree

    const clippingData = getClippingData(content)
    const isNotSplit = divideData >= 1 || divideData < 0 || !divideData
    if (isNotSplit) {
      // 分割パラメータを持たないコンテンツ
      const page: Page = {
        PageID: i * 2,
        Koma: i,
        Area: {
          Left: 0,
          Top: 0,
          Right: 1.0,
          Bottom: 1.0,
        },
        AntiTilt: leftRotationData,
        Clipping: {
          Left: clippingData?.Left || 0,
          Top: clippingData?.Top || 0,
          Right: clippingData?.Right || 1.0,
          Bottom: clippingData?.Bottom || 1.0,
        },
      }
      pagesDataLTR.push(page)
      pagesDataRTL.push(page)
    } else {
      // 分割パラメータを持つコンテンツ
      const pageA: Page = {
        PageID: i * 2,
        Koma: i,
        Area: {
          Left: 0,
          Top: 0,
          Right: divideData,
          Bottom: 1.0,
        },
        AntiTilt: leftRotationData,
        Clipping: {
          Left: clippingData?.Left || 0,
          Top: clippingData?.Top || 0,
          Right: divideData,
          Bottom: clippingData?.Bottom || 1.0,
        },
      }
      const pageB: Page = {
        PageID: i * 2 + 1,
        Koma: i,
        Area: {
          Left: divideData,
          Top: 0,
          Right: 1.0,
          Bottom: 1.0,
        },
        AntiTilt: rightRotationData,
        Clipping: {
          Left: divideData,
          Top: clippingData?.Top || 0,
          Right: clippingData?.Right || 1.0,
          Bottom: clippingData?.Bottom || 1.0,
        },
      }
      pagesDataLTR.push(pageA) // PageRTL[先]と同じページ情報
      pagesDataLTR.push(pageB) // PageRTL[後]と同じページ情報
      pagesDataRTL.push(pageB) // PageLTR[後]と同じページ情報
      pagesDataRTL.push(pageA) // PageLTR[先]と同じページ情報
    }
  })
  return { pagesDataLTR, pagesDataRTL }
}
