
import { defineComponent, computed, reactive, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import AppImg from '@/components/atoms/AppImg.vue'
import CloserController from '../../molecules/CloserController.vue'
import BookMarkButton from '../../molecules/BookMarkButton.vue'
import { ContentsThumbnails } from '@/data/@types/ContentsThumbnails'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { generateThumbnailPath } from '@/domain/item/itemViewer/publicPath'
import { AccessToken, AccessTokenService } from '@/store/modules/AccessToken'
import FileContentsIndex from './FileContentsIndex.vue'
import IntersectionObserver from '../../molecules/IntersectionObserver.vue'
import { useRoute, useRouter } from 'vue-router'
import { useRouterExtention } from '@/helpers/hooks/useRouterExtention'
import { useI18n } from 'vue-i18n'
import { currentContentScrollTo } from '@/helpers/util/scrollUtil'
import { ruleToType, getPermission } from '@/data/Permission'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

export default defineComponent({
  components: {
    AppImg,
    BookMarkButton,
    CloserController,
    DssIcon,
    FileContentsIndex,
    IntersectionObserver,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    contentsBundle: {
      type: Object,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
    isPlayingBundle: {
      type: Boolean,
      default: false,
    },
    contentsBundleId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'is-small',
    },
  },
  setup (props, context) {
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const stateModule = store.getters
    const route = useRoute()
    const router = useRouter()
    const routerExtention = useRouterExtention(route, router)
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const state = reactive<{ showThumbnails: boolean; showTntersectTop: boolean, showIntersectBottom: boolean, thumbnails: ContentsThumbnails[] }>(
      {
        showThumbnails: false,
        showTntersectTop: false,
        showIntersectBottom: false,
        thumbnails: [],
      }
    )
    const selectedItem = computed({
      get: () => stateModule.contentNumber,
      set: (val) => {
        store.commit('updateCurrentContentIndex', {
          currentContentIndex: val,
        })
      },
    })
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
    const thumbnails: ContentsThumbnails[] = props.contentsBundle?.contents?.map(
      (item: any, i: number) => {
        const path: string = item.path || ''
        const bid = path.split(/[./]/).find((v, i, ary) => ary[i + 1] === item.id)

        if (props.contentsBundle.type === 'image') {
          const publicPath: string = item.publicPath
          const thumbnailPublicPath = generateThumbnailPath(publicPath)

          return {
            value: (i + 1).toString(),
            imageSrc: BASE_URL + thumbnailPublicPath,
            cid: item.id,
            bid,
          }
        } else {
          return {
            value: (i + 1).toString(),
            cid: item.id,
            bid,
          }
        }
      }
    ) || []

    //  パフォーマンス改善
    //  サムネイルを内容を一気に取得すると画面の初期表示に時間がかかるため、
    //  初期で取得するサムネイル件数を制限し、それ以降については、画面に表示領域が現れたタイミングで、段階的に取得する。
    //  可読性が低いため、修正時要注意

    /** 最初に取得する件数 */
    const INIT_MAX_COUNT = 30
    /** 追加で取得する件数 */
    const ADD_LOADING_COUNT = 20
    /** 画面に表示サムネイル情報のソート処理 */
    const sortThumbnailAscFunc = (a: ContentsThumbnails, b: ContentsThumbnails) => { return Number(a.value) - Number(b.value) }
    /** 全件表示済みかのフラグ */
    const isShowAll = computed(() => state.thumbnails.length === thumbnails.length)
    /** 画面に描画するサムネイル情報(逆読みも存在するので、stateのそのままbindするのではなくソート処理を挟む) */
    const sortThumbnails = computed(() => state.thumbnails.slice().sort(sortThumbnailAscFunc))
    /** 現在表示済みのサムネイルの最小index */
    const showThumbnailMinIndex = computed(() => sortThumbnails.value.length === 0 ? 0 : Number(sortThumbnails.value[0].value) - 1)
    /** 現在表示済みのサムネイルの最大index */
    const showThumbnailMaxIndex = computed(() => sortThumbnails.value.length === 0 ? 0 : Number(sortThumbnails.value[sortThumbnails.value.length - 1].value) - 1)
    /** 自身のバンドルが、現在有効なバンドルかのフラグ */
    const isActiveBundle = Number(props.contentsBundleId) === Number(stateModule.bundleNumber)
    /** 自身のバンドルが、現在有効なバンドルより若い場合。逆読み */
    const reverse = props.contentsBundleId < stateModule.bundleNumber
    /** ファムネイル情報を追加で取得する処理 */
    const fecthThumbnailFunc = async (fecthCount: number, bottomFecth: boolean) => {
      //  すべて表示済みの場合、何もしない
      if (isShowAll.value) return
      if (bottomFecth) {
        if (showThumbnailMaxIndex.value === thumbnails.length) {
          //  下のサムネイルがすべて取得済みの場合、何もしない
          return
        }
      } else {
        if (showThumbnailMinIndex.value === 0) {
          //  上のサムネイルがすべて取得済みの場合、何もしない
          return
        }
      }

      let startIndex: number
      const isFirstView = state.thumbnails.length === 0
      if (isFirstView) {
        if (reverse) {
          startIndex = thumbnails.length - 1 - fecthCount
          startIndex = startIndex < 0 ? 0 : startIndex
        } else {
          startIndex = isActiveBundle ? stateModule.contentNumber : 0
          //  指定されている箇所からstartIndexを指定すると、描画後に、すぐ取得のイベントが発生するため、マージンを確保する
          startIndex = startIndex - ADD_LOADING_COUNT < 0 ? 0 : startIndex - ADD_LOADING_COUNT
        }
      } else {
        if (bottomFecth) {
          //  下のサムネイルを読み込み
          startIndex = showThumbnailMaxIndex.value + 1
        } else {
          //  上のサムネイルを読み込み
          startIndex = showThumbnailMinIndex.value - fecthCount < 0 ? 0 : showThumbnailMinIndex.value - fecthCount
        }
      }
      const workThumbnails = thumbnails.sort(sortThumbnailAscFunc)
      const tokens = await AccessTokenService.findAll()
      workThumbnails.slice(startIndex, startIndex + fecthCount).map((thumbnail) => {
        const token = tokens.find((token) => token.cid === thumbnail.cid)
        const enable = token && Date.now() < token.timestamp
        return {
          ...thumbnail,
          enable,
          imageSrc: enable
            ? `${thumbnail.imageSrc}?token=${AccessTokenService.getTokenString(
                token as AccessToken
              )}`
            : '',
        }
      }).filter(thumbnail => {
        //  基本的にはあまりないと思うが、表示済みのサムネイルの場合は、除外する。
        //  同時にイベントが実行された際等の保険コード
        return !state.thumbnails.find(show => show.value === thumbnail.value)
      }).map(thumbnail => {
        //  ここで、reactive監視しているサムネイルの一覧に追加して、伝播させる。
        state.thumbnails.push(thumbnail)
      })
      state.showThumbnails = true

      if (isActiveBundle && isFirstView) {
        //  DOMの描画を待ってから、スクロール制御を実施する
        await nextTick(currentContentScrollTo)
      }

      //  DOMの描画が完了したら、スクロール検知用のIntersect領域を表示する。
      await nextTick(() => {
        if (isFirstView) {
          state.showTntersectTop = true
          state.showIntersectBottom = true
        } else {
          if (bottomFecth) {
            state.showIntersectBottom = true
          } else {
            state.showTntersectTop = true
          }
        }
      })
    }

    onMounted(async () => {
      await fecthThumbnailFunc(INIT_MAX_COUNT, true)
    })

    const intersectTop = async (isIntersecting: boolean) => {
      if (isIntersecting) {
        //  イベント検知コンポーネントを非表示にして、DOMの描画を待ち、その後、サムネイルのFecth処理
        state.showTntersectTop = false
        await nextTick()
        await fecthThumbnailFunc(ADD_LOADING_COUNT, false)
      }
    }

    const intersectBottom = async (isIntersecting: boolean) => {
      if (isIntersecting) {
        //  イベント検知コンポーネントを非表示にして、DOMの描画を待ち、その後、サムネイルのFecth処理
        state.showIntersectBottom = false
        await nextTick()
        await fecthThumbnailFunc(ADD_LOADING_COUNT, true)
      }
    }

    const selectItem = (contentsIndex: number) => {
      selectedItem.value = contentsIndex
      routerExtention.replaceOrPush('/' + makeUpdatedPidString(props.item.pid) + '/' + (props.contentsBundleId + 1) + '/' + (contentsIndex + 1))
      context.emit('updateViewer')
    }

    const playingItem = computed(
      () => stateModule.videoViewerInformation.playingContentIndex
    )
    const isNowPlaying = computed(
      () => store.getters.videoViewerInformation.playingFlag
    )
    const indexes = computed(() => {
      return store.getters.item.contentsBundles?.[props.contentsBundleId]?.directoryIndexTree
    })

    const currentFileDirectoryId = computed(() => store.getters.currentFileViewerDirectory)
    // ファイルビューアで特定のディレクトリを表示させる
    const changeFileViewerDirectory = (directoryId: string) => {
      if (Number(route.params.bundleIndex) !== props.contentsBundleId + 1) {
        routerExtention.replaceOrPush('/' + makeUpdatedPidString(props.item.pid) + '/' + (props.contentsBundleId + 1))
        updateViewer()
      }
      store.commit('changeFileViewerDirectory', directoryId)
    }

    const updateViewer = () => {
      context.emit('updateViewer', props.contentsBundleId)
    }

    // 自身のルールが未設定の場合は、上位を参照
    const bundlePermissionRule = computed(() => props.contentsBundle?.permission?.rule ? props.contentsBundle.permission.rule : props.item.permission.rule)
    const bundlePermissionType = computed(() => ruleToType(bundlePermissionRule.value))
    const isBundlePermission = computed(() => getPermission(bundlePermissionType.value))

    // 自身のルールが未設定の場合は、上位を参照
    const contentPermissionType = (content: any) => {
      const rule = content?.permission?.rule ? content.permission.rule : bundlePermissionRule.value
      return ruleToType(rule)
    }
    const isContentPermission = (content: any) => getPermission(contentPermissionType(content))

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join('\r\n') : ''
    }

    const contentsBundleMessage = computed(() => metaArrayProcessing(props.contentsBundle.meta?.['0601Dt']))

    const pid = computed(() => props.item.pid.split('/').pop())

    return {
      isKn,
      isRekion,
      state,
      sortThumbnails,
      stateModule,
      selectItem,
      selectedItem,
      playingItem,
      isNowPlaying,
      indexes,
      changeFileViewerDirectory,
      currentFileDirectoryId,
      updateViewer,
      contents: props.contentsBundle.contents,
      lang,
      intersectTop,
      intersectBottom,
      isBundlePermission,
      contentPermissionType,
      isContentPermission,
      contentsBundleMessage,
      pid,
    }
  },
})
