import { Statistics, StatisticsLogService } from '@/store/modules/StatisticsLog'
import { store } from '@/store/index'
import { postData } from '@/helpers/util/webApiUtil'

export const getCookieValue = (name: string): string => Array.from(document.cookie.split(';')).filter((c: string) => c.trim().startsWith(`${name}=`)).map((c: string) => c.split('=')[1])[0]

const BASE_URL = process.env.VUE_APP_API_BASE_URL

// eslint-disable-next-line
const AWS = require('aws-sdk')
AWS.config.region = 'ap-northeast-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_IDENTITY,
})

// cognito認証は60分で有効期限が切れるため、50分ごとにリフレッシュする
setInterval(() => {
  AWS.config.credentials.refresh((err: any) => {
    if (err) {
      postData(`${BASE_URL}/trace/frontLog`, {
        records: ['credential refresh error'],
        errorInfo: JSON.stringify(err),
      })
    }
  })
}, 50 * 60 * 1000)

const kinesis = new AWS.Kinesis({
  apiVersion: '2013-12-02',
})

/**
 * シングルトンパターンで作成
 */
class ClientTracker {
  public uid = ''
  public ipaddress = ''
  public userAgent = ''
  public logs: Statistics[] = []

  private async putLogs (logs: Statistics[]): Promise<void> {
    // upload data to Amazon Kinesis
    const Records = Array.from(logs).map((log) => ({
      Data: JSON.stringify({
        ...log,
      }),
      PartitionKey: '1',
    }))

    kinesis.putRecords({
      Records,
      StreamName: process.env.VUE_APP_STATISTICS_LOGS_STREAMS_NAME,
    }, async function (err: any, data: any) {
      console.log(data)
      if (err || data.FailedRecordCount > 0) {
        console.error(data, err)
        const logTraceSendId = localStorage.getItem('logTraceSendId') || '0'
        const records = Records.map(r => {
          const d = JSON.parse(r.Data)
          if (d?.meta?.cardId) d.meta.cardId = '**********' // cardIdはサーバーに送信しない
          return JSON.stringify(d)
        }).filter(r => {
          const d = JSON.parse(r)
          return d.id > Number(logTraceSendId) // 前回Trace用APIに送信したログは再度送信しない
        })
        if (records.length) {
          postData(`${BASE_URL}/trace/frontLog`, {
            records,
            errorInfo: JSON.stringify(err),
          })
          localStorage.setItem('logTraceSendId', `${logs[logs.length - 1].id}`)
        }
      } else {
        const lastIndex = Array.from(logs).sort((a, b) => ((b.id as number) - (a.id as number)))[0].id as number
        await StatisticsLogService.clean(lastIndex)
      }
    })
  }

  constructor () {
    this.uid = getCookieValue('uid')
    this.ipaddress = getCookieValue('fromIpAddress')
    this.userAgent = navigator.userAgent

    const kinesis = new AWS.Kinesis({
      apiVersion: '2013-12-02',
    })

    // upload data to Amazon Kinesis every second if data exists
    setInterval(async () => {
      const logs = await StatisticsLogService.findAll()

      if (logs.length) {
        // upload data to Amazon Kinesis
        await this.putLogs(logs)
      } else {
        console.log('No logs to send ...')
      }
    }, 5000)

    // Send a log when leaving the page
    window.addEventListener('beforeunload', async () => {
      let logs: Statistics[] = await StatisticsLogService.findAll()

      while (logs.length) {
        // upload data to Amazon Kinesis
        await this.putLogs(logs)

        logs = await StatisticsLogService.findAll()
      }
    })
  }

  push (params: any) {
    const log: Statistics = {
      type: params.key,
      uid: this.uid,
      ipaddress: this.ipaddress,
      referrer: document.referrer,
      userAgent: this.userAgent,
      meta: params,
      timestamp: Date.now(),
      cookieAllowLevel: store.getters.cookieAllowLevel,
    }
    if (process.env.VUE_APP_ENV !== 'local') {
      StatisticsLogService.add(log)
    }
  }
}

const tracker = new ClientTracker()
export default tracker
