import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00c2009a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-viewer-toolbar has-background-contents" }
const _hoisted_2 = { class: "video-viewer-toolbar-row level" }
const _hoisted_3 = { class: "video-viewer-toolbar-title level-left is-mobile" }
const _hoisted_4 = { class: "level has-text-contents-title has-text-normal" }
const _hoisted_5 = { class: "video-viewer-toolbar-play-buttons level-center is-mobile" }
const _hoisted_6 = { class: "video-viewer-toolbar-row level" }
const _hoisted_7 = { class: "video-viewer-toolbar-time level-right is-mobile" }
const _hoisted_8 = { id: "videoviewer-toolbar-current-time" }
const _hoisted_9 = { class: "has-text-modest" }
const _hoisted_10 = { class: "video-viewer-toolbar-row level is-mobile" }
const _hoisted_11 = { class: "video-viewer-toolbar-volume level is-hidden-mobile" }
const _hoisted_12 = { class: "video-viewer-toolbar-frame-buttons level is-mobile" }
const _hoisted_13 = {
  key: 1,
  class: "level is-mobile"
}
const _hoisted_14 = { class: "video-viewer-toolbar-help is-hidden-mobile level" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookMarkButton = _resolveComponent("BookMarkButton")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppInputRange = _resolveComponent("AppInputRange")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isKn && !_ctx.isRekion)
          ? (_openBlock(), _createBlock(_component_BookMarkButton, {
              key: 0,
              pid: _ctx.itemPid,
              bid: _ctx.currentBid,
              cid: _ctx.currentCid,
              id: "videoviewer-toolbar-bookmark",
              class: "video-viewer-toolbar-bookmark"
            }, null, 8, ["pid", "bid", "cid"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.metaArrayProcessing(_ctx.meta['0001Dtct'])), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ButtonIcon, {
          id: "videoviewer-toolbar-backward-btn",
          onClick: _ctx.backward,
          onKeydown: _withKeys(_withModifiers(_ctx.backward, ["prevent"]), ["space","enter"]),
          icon: "backward-end",
          styled: "is-none",
          "aria-label": _ctx.$t('label.previous')
        }, null, 8, ["onClick", "onKeydown", "aria-label"]),
        _createVNode(_component_ButtonIcon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stateModule.playing ? _ctx.pause() : _ctx.play())),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.stateModule.playing ? _ctx.pause() : _ctx.play()), ["prevent"]), ["space","enter"])),
          icon: _ctx.stateModule.playing ? 'pause' : 'play',
          styled: "is-none",
          "aria-label": _ctx.stateModule.playing ? _ctx.$t('label.pause') : _ctx.$t('label.playback')
        }, null, 8, ["icon", "aria-label"]),
        _createVNode(_component_ButtonIcon, {
          id: "videoviewer-toolbar-forward-btn",
          onClick: _ctx.forward,
          onKeydown: _withKeys(_withModifiers(_ctx.forward, ["prevent"]), ["space","enter"]),
          icon: "forward-end",
          styled: "is-none",
          "aria-label": _ctx.$t('label.next')
        }, null, 8, ["onClick", "onKeydown", "aria-label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_AppInputRange, {
        modelValue: _ctx.stateModule.currentSeconds,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stateModule.currentSeconds) = $event)),
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.seekbar($event.target.value))),
        min: 0,
        max: _ctx.stateModule.durationSeconds,
        step: 0.01,
        fillProgress: true,
        label: _ctx.$t('label.playTime')
      }, null, 8, ["modelValue", "max", "step", "label"]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.stateModule.currentDisplayTime), 1),
        _createElementVNode("span", _hoisted_9, "/" + _toDisplayString(_ctx.stateModule.durationDisplayTime), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ButtonIcon, {
          id: "videoviewer-toolbar-volume-btn",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.stateModule.volume === 0 ? _ctx.unmute() : _ctx.mute())),
          onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.stateModule.volume === 0 ? _ctx.unmute() : _ctx.mute()), ["prevent"]), ["space","enter"])),
          icon: _ctx.volumeIcon,
          styled: "is-none",
          "aria-label": _ctx.stateModule.volume === 0 ? _ctx.$t('label.unmute') : _ctx.$t('label.mute')
        }, null, 8, ["icon", "aria-label"]),
        _createVNode(_component_AppInputRange, {
          modelValue: _ctx.stateModule.volume,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.stateModule.volume) = $event)),
          min: 0,
          max: 100,
          step: 1,
          fillProgress: true,
          label: _ctx.$t('label.theVolume')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_AppSelect, {
          id: "videoviewer-toolbar-select-speed",
          modelValue: _ctx.stateModule.speed,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stateModule.speed) = $event)),
          onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.speed($event.target.value))),
          options: _ctx.speedList
        }, null, 8, ["modelValue", "options"]),
        (!_ctx.isRekion)
          ? (_openBlock(), _createBlock(_component_AppSelect, {
              key: 0,
              id: "videoviewer-toolbar-select-continuous",
              modelValue: _ctx.stateModule.continuous,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.stateModule.continuous) = $event)),
              onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.toggleContinuous($event.target.value))),
              options: _ctx.continuousList
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true),
        (_ctx.media === 'video')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_ButtonIcon, {
                id: "videoviewer-toolbar-backward-frame",
                onClick: _ctx.prev,
                onKeydown: _withKeys(_withModifiers(_ctx.prev, ["prevent"]), ["space","enter"]),
                icon: "frame-advance-prev",
                styled: "is-none",
                "aria-label": _ctx.$t('label.frameReturn')
              }, null, 8, ["onClick", "onKeydown", "aria-label"]),
              _createVNode(_component_ButtonIcon, {
                id: "videoviewer-toolbar-forward-frame",
                onClick: _ctx.next,
                onKeydown: _withKeys(_withModifiers(_ctx.next, ["prevent"]), ["space","enter"]),
                icon: "frame-advance-next",
                styled: "is-none",
                "aria-label": _ctx.$t('label.frameFeed')
              }, null, 8, ["onClick", "onKeydown", "aria-label"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.fullscreenEnabled)
          ? (_openBlock(), _createBlock(_component_ButtonIcon, {
              key: 0,
              onClick: _ctx.toggleFullScreen,
              onKeydown: _withKeys(_withModifiers(_ctx.toggleFullScreen, ["prevent"]), ["space","enter"]),
              icon: _ctx.fullScreenIcon,
              "aria-label": _ctx.fullScreenAriaLabel,
              styled: "is-none",
              class: "has-form-label"
            }, null, 8, ["onClick", "onKeydown", "icon", "aria-label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_TextLinkWithArrow, {
          to: !_ctx.isKn && { name: 'Help' },
          href: _ctx.isKn && `${_ctx.KN_APPLICATION_LINK}/static/help?language=${_ctx.lang}`,
          class: "has-text-normal",
          icon: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ButtonIcon, {
              icon: "circle-questionmark",
              styled: "is-none",
              tabindex: "-1",
              class: "has-form-label",
              "aria-label": _ctx.$t('header.help')
            }, null, 8, ["aria-label"])
          ]),
          _: 1
        }, 8, ["to", "href"])
      ])
    ])
  ]))
}