import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-347ef3e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "file-component",
  class: "file-viewer"
}
const _hoisted_2 = { class: "file-viewer-header" }
const _hoisted_3 = {
  class: "file-viewer-body",
  "aria-live": "polite"
}
const _hoisted_4 = { class: "file-viewer-total level is-mobile" }
const _hoisted_5 = { class: "is-sr-only" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "level is-mobile" }
const _hoisted_8 = {
  key: 0,
  class: "file-viewer-list shadow has-background-contents"
}
const _hoisted_9 = ["onClick", "onKeydown"]
const _hoisted_10 = { class: "file-viewer-item-index has-text-modest" }
const _hoisted_11 = { class: "has-text-link" }
const _hoisted_12 = {
  key: 1,
  class: "file-viewer-item-file flex-grow level-left is-mobile"
}
const _hoisted_13 = { class: "file-viewer-item-index has-text-modest" }
const _hoisted_14 = ["href", "onClick", "onKeydown"]
const _hoisted_15 = { class: "has-text-modest has-text-table-item-content" }
const _hoisted_16 = { class: "has-text-modest has-text-table-item-content" }
const _hoisted_17 = { class: "has-text-modest has-text-table-item-content" }
const _hoisted_18 = {
  key: 2,
  class: "file-viewer-item-file flex-grow level-left is-mobile"
}
const _hoisted_19 = { class: "file-viewer-item-index has-text-modest" }
const _hoisted_20 = {
  key: 0,
  class: "has-text-normal has-text-table-item-content"
}
const _hoisted_21 = {
  key: 1,
  class: "has-text-normal has-text-table-item-content"
}
const _hoisted_22 = {
  key: 2,
  class: "has-text-normal has-text-table-item-content"
}
const _hoisted_23 = {
  key: 3,
  class: "has-text-normal has-text-table-item-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileViewerBreadcrumbs = _resolveComponent("FileViewerBreadcrumbs")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IconLoading = _resolveComponent("IconLoading")!
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_PackageGuideModal = _resolveComponent("PackageGuideModal")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FileViewerBreadcrumbs, {
          breadcrumbs: _ctx.breadcrumbs,
          onChangeDirectory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeDirectory($event, true)))
        }, null, 8, ["breadcrumbs"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.currentDirectory.title), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.currentDirectory.children?.length) + " " + _toDisplayString(_ctx.$t('parts.numberOfFiles')), 1),
          _createElementVNode("span", _hoisted_7, [
            (_ctx.files?.length && !_ctx.isRekion && _ctx.isAllowedDownload)
              ? (_openBlock(), _createBlock(_component_AppButton, {
                  key: 0,
                  onClick: _ctx.downloadOthers,
                  onKeydown: _withKeys(_withModifiers(_ctx.downloadOthers, ["prevent"]), ["space","enter"]),
                  styled: "is-accent",
                  disabled: _ctx.downloadingFlag,
                  id: "download-others-button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('parts.bulkDownload')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "onKeydown", "disabled"]))
              : _createCommentVNode("", true),
            (_ctx.downloadingFlag)
              ? (_openBlock(), _createBlock(_component_IconLoading, {
                  key: 1,
                  class: "download-icon-loading"
                }))
              : _createCommentVNode("", true),
            (_ctx.hasStreamingPackage && _ctx.isPackageUser && !_ctx.isProduction)
              ? (_openBlock(), _createBlock(_component_AppButton, {
                  key: 2,
                  onClick: _ctx.openPackageGuideModal,
                  onKeydown: _withKeys(_withModifiers(_ctx.openPackageGuideModal, ["prevent"]), ["space","enter"]),
                  styled: "is-accent",
                  class: "open-package-guide-modal-button",
                  "data-cy": "package-button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('packageModal.openModal')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "onKeydown"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", null, [
          (_ctx.state.loading === false && _ctx.files?.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    class: _normalizeClass(["file-viewer-item has-text-table-cell-title level-left is-mobile", { current: _ctx.state.currentContentId === item.id }])
                  }, [
                    (item.type === 'directory')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: ($event: any) => (_ctx.openDirectory(item.id)),
                          onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.openDirectory(item.id)), ["prevent"]), ["space","enter"]),
                          tabindex: "0",
                          role: "button",
                          class: "file-viewer-item-directory flex-grow level-left is-mobile"
                        }, [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(index + 1), 1),
                          _createVNode(_component_DssIcon, {
                            icon: "folder-fill",
                            class: "has-text-link"
                          }),
                          _createElementVNode("span", _hoisted_11, _toDisplayString(item.title), 1)
                        ], 40, _hoisted_9))
                      : (item.enable && _ctx.isContentPermission(item))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(index + 1), 1),
                            _createElementVNode("h2", null, [
                              _createElementVNode("a", {
                                href: _ctx.generateFilePath(item.publicPath),
                                onClick: _withModifiers(($event: any) => (_ctx.downloadFile(item.downloadPath, item.originalFileName, _ctx.contentPermissionRule(item))), ["prevent"]),
                                onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.downloadFile(item.downloadPath, item.originalFileName, _ctx.contentPermissionRule(item))), ["prevent"]), ["space","enter"])
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(`file-viewer-content-${item.id}`)
                                }, _toDisplayString(item.name?.ja && item.name?.ja !=='' ?  item.name.ja : item.originalFileName), 3)
                              ], 40, _hoisted_14)
                            ]),
                            (item.name?.ja && item.name?.ja !=='')
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: _normalizeClass(["has-text-normal has-text-table-item-content", `file-viewer-content-${item.id}`])
                                }, _toDisplayString(item.originalFileName), 3))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_15, _toDisplayString(item.meta?.['0741Dt'] && item.meta?.['0741Dt'][0] !== '' ?  item.meta['0741Dt'][0] + _ctx.$t("label.pages") : null), 1),
                            _createElementVNode("span", _hoisted_16, _toDisplayString(item.meta?.['0710Dt'] && item.meta?.['0710Dt'][0] !== '' ?  _ctx.calculationPrefixFileSize(item.meta['0710Dt'][0]) : null), 1),
                            _createElementVNode("span", _hoisted_17, _toDisplayString(item.meta?.['0740Dk'] && item.meta?.['0740Dk'][0] !== '' ?  _ctx.convertDateFormat(item.meta['0740Dk'][0], _ctx.$t("label.fileViewerUpdated")) : null), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createElementVNode("span", _hoisted_19, _toDisplayString(index + 1), 1),
                            _createElementVNode("div", null, [
                              _createElementVNode("span", {
                                class: _normalizeClass(["has-text-normal has-text-table-item-content", `file-viewer-content-${item.id}`])
                              }, _toDisplayString(item.originalFileName), 3),
                              (_ctx.contentRestrictionMessage(item))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.$t('restrictionMessage.fileViewer.displayMetaData')) + " : " + _toDisplayString(_ctx.contentRestrictionMessage(item).join(_ctx.newLineInRestrictionMessage)), 1))
                                : (_ctx.contentPermissionType(item) === 'inlibrary')
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.$t('restrictionMessage.fileViewer.inLibrary')), 1))
                                  : (_ctx.contentPermissionType(item) === 'ooc')
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_ctx.$t('restrictionMessage.fileViewer.ooc')), 1))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.$t('restrictionMessage.fileViewer.outOfService')), 1))
                            ])
                          ]))
                  ], 2))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.isPackageGuideModalVisible)
          ? (_openBlock(), _createBlock(_component_ModalWindow, {
              key: 0,
              heading: _ctx.$t('packageModal.title'),
              onClose: _ctx.closePackageGuideModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PackageGuideModal, {
                  onClose: _ctx.closePackageGuideModal,
                  pid: _ctx.item.itemId,
                  packageTitle: _ctx.state.packageTitle,
                  currentBundle: _ctx.state.currentBundle
                }, null, 8, ["onClose", "pid", "packageTitle", "currentBundle"])
              ]),
              _: 1
            }, 8, ["heading", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}