
import { computed, defineComponent, ref } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ViewerPoster from './ViewerPoster.vue'
import TheFileViewer from './FileViewerMobile.vue'
import TheImageViewer from './ImageViewerMobile.vue'
import TheVideoViewer from './VideoViewerMobile.vue'
import NoContentViewer from '@/components/NoContentViewer.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ButtonIcon,
    ViewerPoster,
    TheFileViewer,
    TheImageViewer,
    TheVideoViewer,
    NoContentViewer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
    bundleIndex: {
      type: String,
      required: false,
    },
    tabViewFlag: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    const route = useRoute()
    const mobileViewerFullscreenFlag = ref(false)
    const startIcon = computed(() => {
      if (props.viewerType === 'NoContentViewer') {
        return
      }
      if (props.viewerType === 'TheVideoViewer') {
        return 'play'
      } else {
        return 'arrow-to-outside'
      }
    })

    // SP版のビューア表示からポスター表示に切り替えたときに指定されたコンテンツを表示させるため
    const currentContentIndex = ref()
    const ischangedToViewerPoster = ref()

    const start = () => {
      if (props.viewerType === 'NoContentViewer') {
        return
      }
      // 全画面表示のときブラウザバックでポスター表示に変えるため
      // router.pushではブラウザバックの挙動の検知が出来なかったため
      window.history.pushState(null, '', route.fullPath)
      ischangedToViewerPoster.value = false
      mobileViewerFullscreenFlag.value = true
    }
    const close = () => {
      currentContentIndex.value = route.params.contentIndex
      ischangedToViewerPoster.value = true
      mobileViewerFullscreenFlag.value = false
    }

    return {
      startIcon,
      start,
      close,
      mobileViewerFullscreenFlag,
      currentContentIndex,
      ischangedToViewerPoster,
    }
  },
})
