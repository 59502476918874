
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import AppToggleSwitch from '@/components/atoms/AppToggleSwitch.vue'
import { ImageViewer, ViewMode } from '@/helpers/imageviewer/ImageViewer'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    AppToggleSwitch,
  },
  setup () {
    const store = useStore()
    const currentProcessingData = computed(() => store.getters.processingData)

    // ローカルストレージに保持している値を設定
    iv.changeCoverPageMode(currentProcessingData.value.addCoverPageFlag ? 0 : 1)
    // 2in1時の表紙ページの表示切替
    const addCoverPageFlag = computed({
      get: () => currentProcessingData.value.addCoverPageFlag,
      set: (val: boolean) => {
        store.commit('updateAddCoverPageFlag', val)
        if (val === true) {
          // 表紙のコマを独立したページとして表示する
          iv.changeCoverPageMode(0)
        } else {
          // 表紙のコマを2in1ページとして表示する
          iv.changeCoverPageMode(1)
        }
      },
    })

    // 2in1表示しているかの判定
    const isTwoInOneMode = computed(() => {
      return store.getters.imageviewerViewMode === ViewMode.TOW_IN_ONE
    })

    return {
      addCoverPageFlag,
      isTwoInOneMode,
    }
  },
})
