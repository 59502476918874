type State = {
  leftPane: {
    show: boolean
  },
  rightPane: {
    show: boolean
  },
  imageViewer: {
    fullScreen: boolean
  }
}

const state: State = {
  leftPane: {
    show: true,
  },
  rightPane: {
    show: true,
  },
  imageViewer: {
    fullScreen: false,
  },
}
const mutations = {
  toggleLeftPane (state: any): void {
    state.leftPane.show = !state.leftPane.show
  },
  toggleRightPane (state: any): void {
    state.rightPane.show = !state.rightPane.show
  },
  toggleFullScreen (state: State): void {
    state.imageViewer.fullScreen = !state.imageViewer.fullScreen
  },
}
const getters = {
  leftPane: (state: any): boolean => {
    return state.leftPane.show
  },
  rightPane: (state: any): boolean => {
    return state.rightPane.show
  },
  fullScreen: (state: any): boolean => {
    return state.imageViewer.fullScreen
  },
}

export default {
  state,
  mutations,
  getters,
}
