
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watchEffect,
  computed,
  watch,
  onBeforeUnmount,
} from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import VideoViewerToolbar from './organisms/ItemContentViewer/VideoViewerToolbar.vue'
import md from '@/helpers/util/MobileDetect'
import AppImg from '@/components/atoms/AppImg.vue'
import PlaceholderImage from '@/components/atoms/PlaceholderImage.vue'
import { generetePublicPath } from '@/domain/item/itemViewer/publicPath'
import { AccessTokenService, AccessToken } from '@/store/modules/AccessToken'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

export default defineComponent({
  components: {
    VideoViewerToolbar,
    AppImg,
    PlaceholderImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const isMobile = md.phone()
    // TODO 動画ビューア周りの状態管理の見直し
    const state = reactive({
      volume: 50,
      speed: 1,
      continuous: 'continuous-on',
      currentSeconds: 0,
      durationSeconds: 1000,
      playing: false,
      currentDisplayTime: '0:00',
      durationDisplayTime: '0:00',
      beforeVolume: 0,
      playingContents: [],
      holdPlaybackStateFlag: false,
      lastContentFlag: false,
      looping: false,
      showToolbar: true,
      timeoutId: 0,
    })
    const token = reactive<{ value: AccessToken | undefined }>({
      value: undefined,
    })
    if (
      props.viewerType !== 'TheVideoViewer' &&
      props.viewerType !== 'TheImageViewer'
    ) {
      return
    }
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isRekion = computed(() => route.meta.isRekion)
    const contents = computed(() => store.getters.contents)
    const currentBundleIndex = computed(() => store.getters.bundleNumber)
    const currentContentIndex = computed(() => store.getters.contentNumber)
    const currentContentId = computed(() => store.getters.contentId)
    const isServer = typeof window === 'undefined'
    const currentBundleType = computed(
      () => props.item?.contentsBundles[currentBundleIndex.value].type
    )
    const contentsBundleThumbnail = computed(
      () => props.item?.contentsBundles[currentBundleIndex.value].thumbnail
    )
    const stateModule = computed(() => store.getters.videoViewerInformation).value
    const currentContentPublicPath = computed(() => store.getters.publicPath)

    watchEffect(() => {
      store.commit('updatePlayingFlag', state.playing)
    })

    const meta: { [metaname: string]: string } = {}
    Object.keys(props.item.meta).forEach((key: string) => {
      meta[key] = props.item.meta[key]
    })

    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
    let player: any

    onMounted(async () => {
      const value = await AccessTokenService.get(currentContentId.value)
      token.value = value
      player = playerInit()
    })
    watch(
      () => currentContentId.value,
      async (cid, _) => {
        if (cid) {
          console.log(`content changed. = ${cid}`)
          AccessTokenService.setAccessToken(`info:ndljp/pid/${route.params.pid}`)
          const value = await AccessTokenService.get(cid)
          token.value = value
        }
        await setSourceToPlayer(currentContentPublicPath.value, player, null)
        // 再生中のコンテンツ情報をセット
        if (currentBundleType.value !== 'movie' && currentBundleType.value !== 'audio') return
        state.playingContents = contents.value
        store.commit('setPlayingBundleIndex', currentBundleIndex.value)
        store.commit('setPlayingContentIndex', currentContentIndex.value)
      }
    )

    // playerにコンテンツのURLをセット
    const setSourceToPlayer = (publicPath: string, player: any, backgroundContentToken: any) => {
      state.currentDisplayTime = '0:00'
      if (!publicPath?.match(/\.m3u8$/)) return
      const contentUrl = generetePublicPath(publicPath)
      const setContentToken = backgroundContentToken || token.value
      const param =
        setContentToken?.timestamp &&
        AccessTokenService.getTokenString(setContentToken as AccessToken)
      const url = `${contentUrl}?token=${param}`
      document.cookie = `token=${param}; domain=dl.ndl.go.jp`
      if (BASE_URL + contentUrl === String(player.src).split('?token=')[0]) return
      player.source = {
        sources: BASE_URL + url,
      }
    }

    const playerInit = () => {
      const libraryLocation = process.env.VUE_APP_THEOPLAYER_LIBRARY_LOCATION
      const licenseCode = process.env.VUE_APP_THEOPLAYER_LICENSE
      const element = document.querySelector('.theoplayer-container')
      const player = new (window as any).THEOplayer.ChromelessPlayer(element, {
        libraryLocation: libraryLocation,
        license: licenseCode,
      })

      setSourceToPlayer(currentContentPublicPath.value, player, null)
      // 再生中のコンテンツ情報をセット
      state.playingContents = contents.value
      store.commit('setPlayingBundleIndex', currentBundleIndex.value)
      store.commit('setPlayingContentIndex', currentContentIndex.value)

      watchEffect(() => {
        player.muted = false
        player.volume = state.volume / 100
      })
      player.preload = 'auto'
      // リピート再生：無効
      player.loop = false

      const registerControlBarComponents = () => {
        player.addEventListener('play', () => {
          state.playing = true
        })
        player.addEventListener('pause', () => {
          state.playing = false
        })
        var isSliding = false

        /**
         * Playhead position
         */
        player.addEventListener('timeupdate', () => {
          var isLive = player.duration === Infinity
          if (!isLive) {
            state.currentDisplayTime = formatSecondsToHHMMSS(
              player.currentTime
            )
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
          } else if (player.currentProgramDateTime) {
            state.currentDisplayTime = formatDate(
              player.currentProgramDateTime
            )
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
            state.durationDisplayTime = formatDate(
              calculateLiveDuration(
                player.currentTime,
                player.currentProgramDateTime,
                player.seekable.end(player.seekable.length - 1)
              )
            )
          } else {
            state.currentDisplayTime = formatDate(
              calculateLivePlayheadPosition(
                player.currentTime,
                player.seekable.end(player.seekable.length - 1)
              )
            )
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
            state.durationDisplayTime = formatDate(new Date())
          }
          if (!isSliding) {
          }
        })

        /**
         * 連続再生モード
         */
        player.addEventListener('ended', async () => {
          const continuousFlag = state.continuous === 'continuous-on'
          if (continuousFlag && !state.lastContentFlag) {
            state.currentDisplayTime = '0:00'
            if (!state.holdPlaybackStateFlag) {
              // フォアグラウンド再生中の場合
              store.commit('updateCurrentContentIndex', {
                currentContentIndex: currentContentIndex.value + 1,
              })
              // アイテム内の遷移のためヒストリーに残さずに遷移する
              router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (currentBundleIndex.value + 1) + '/' + (currentContentIndex.value + 1))
              const nextContentPublicPath = props.item.contentsBundles[stateModule.playingBundleIndex].contents[stateModule.playingContentIndex].publicPath
              await setSourceToPlayer(nextContentPublicPath, player, null)
            } else {
              // バックグラウンド再生中の場合
              const nextContent = props.item.contentsBundles[stateModule.playingBundleIndex].contents[stateModule.playingContentIndex + 1]
              store.commit('setPlayingContentIndex', stateModule.playingContentIndex + 1)
              const nextContentPublicPath = nextContent.publicPath
              AccessTokenService.setAccessToken(`info:ndljp/pid/${route.params.pid}`)
              const nextContentToken = await AccessTokenService.get(nextContent.id)
              await setSourceToPlayer(nextContentPublicPath, player, nextContentToken)
            }
            player.autoplay = true
            player.play()
          }
        })

        /**
         * Duration
         */

        player.addEventListener('durationchange', () => {
          player.playbackRate = state.speed
          var isLive = player.duration === Infinity
          if (!isLive) {
            state.durationDisplayTime = formatSecondsToHHMMSS(player.duration)
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
          } else if (player.currentProgramDateTime) {
            state.durationDisplayTime = formatDate(
              calculateLiveDuration(
                player.currentTime,
                player.currentProgramDateTime,
                player.seekable.end(player.seekable.length - 1)
              )
            )
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
          } else {
            state.durationDisplayTime = player.duration
            state.currentSeconds = player.currentTime
            state.durationSeconds = player.duration
          }
        })

        const formatDate = (date: any) => {
          var seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
          var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
          var hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
          return hour + ':' + minutes + ':' + seconds
        }

        const formatSecondsToHHMMSS = (seconds: number) => {
          var hrs = Math.floor(seconds / 3600)
          var min = Math.floor((seconds - hrs * 3600) / 60)
          var sec = seconds - hrs * 3600 - min * 60
          sec = Math.round(Math.round(sec * 100) / 100)
          var result = hrs === 0 ? '' : (hrs < 10 ? '0' + hrs : hrs) + ':'
          result += (min < 10 ? '0' + min : min) + ':'
          result += sec < 10 ? '0' + sec : sec
          return result
        }

        const calculateLiveDuration = (
          currentTime: number,
          currentProgramDateTime: any,
          seekableEnd: any
        ) => {
          var toAdd = seekableEnd - currentTime
          var date = new Date(currentProgramDateTime)
          date.setSeconds(date.getSeconds() + toAdd)
          return date
        }

        const calculateLivePlayheadPosition = (
          currentTime: number,
          seekableEnd: any
        ) => {
          var toDeduct = seekableEnd - currentTime
          var date = new Date()
          date.setSeconds(date.getSeconds() - toDeduct)
          return date
        }
      }
      registerControlBarComponents()
      return player
    }

    const play = () => {
      player.playbackRate = state.speed
      player.play()
    }

    const pause = () => {
      player.pause()
    }

    const changeContent = (bundleIndex: number, contentIndex: number) => {
      store.commit('updateCurrentBundleIndex', {
        currentBundleIndex: bundleIndex,
      })
      store.commit('updateCurrentContentIndex', {
        currentContentIndex: contentIndex,
      })
      // アイテム内の遷移のためヒストリーに残さずに遷移する
      router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (bundleIndex + 1) + '/' + (contentIndex + 1))
    }
    // 画像・動画間のバンドル移動でエラーになるため
    if (
      props.viewerType !== 'TheVideoViewer' &&
      props.viewerType !== 'TheImageViewer'
    ) {
      return
    }
    const movieOrAudioBundles = props.item.contentsBundles
      .map((v: any, i: number) => {
        if (v.type === 'movie' || v.type === 'audio') return i
        return null
      })
      .filter((v: any) => v !== null)
    /**
     * 後送り機能
     */
    // 動画系のバンドル間で移動を行う
    const lastBundleIndex = movieOrAudioBundles[movieOrAudioBundles.length - 1]
    const lastBundlelastContentIndex =
      props.item.contentsBundles[lastBundleIndex]?.contents.length - 1
    const backward = () => {
      if (currentBundleIndex.value === 0 && currentContentIndex.value === 0) {
        // 最初のバンドルの最初のコンテンツの次は、同一アイテムの最後の動画系バンドルの最後のコンテンツへ
        changeContent(lastBundleIndex, lastBundlelastContentIndex)
      } else if (
        currentBundleIndex.value !== 0 &&
        currentContentIndex.value === 0
      ) {
        const prevBundlelastContentNumber =
          props.item.contentsBundles[currentBundleIndex.value - 1].contents
            .length - 1
        changeContent(
          currentBundleIndex.value - 1,
          prevBundlelastContentNumber
        )
      } else {
        changeContent(currentBundleIndex.value, currentContentIndex.value - 1)
      }
    }
    /**
     * 前送り機能
     */
    // 動画系のバンドル間で移動を行う
    const forward = () => {
      if (
        currentBundleIndex.value === lastBundleIndex &&
        currentContentIndex.value === contents.value?.length - 1
      ) {
        // 最後のバンドルの最後のコンテンツの次は、同一アイテムの最初の動画系バンドルの最初のコンテンツへ
        changeContent(movieOrAudioBundles[0], 0)
      } else if (
        currentBundleIndex.value !== lastBundleIndex &&
        currentContentIndex.value === contents.value?.length - 1
      ) {
        changeContent(currentBundleIndex.value + 1, 0)
      } else {
        changeContent(currentBundleIndex.value, currentContentIndex.value + 1)
      }
    }

    const prev = () => {
      const sec = player.currentTime
      player.currentTime = sec - 1 / 60
      player.pause()
    }

    const next = () => {
      const sec = player.currentTime
      player.currentTime = sec + 1 / 60
      player.pause()
    }

    const changeSpeed = (val: number) => {
      player.playbackRate = val
      state.speed = val
    }
    /**
     * ミュート機能
     */
    const unmute = () => {
      state.volume = state.beforeVolume
      player.muted = false
    }
    const mute = () => {
      player.muted = true
      state.beforeVolume = state.volume
      state.volume = 0
    }

    const bookmarked = ref(false)

    const bookmarkItem = () => {
      bookmarked.value = !bookmarked.value
    }

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join() : ''
    }

    const seekbar = () => {
      if (player.seekable.length > 0) {
        const pos = state.currentSeconds / player.duration
        const start = player.seekable.start(0)
        const end = player.seekable.end(player.seekable.length - 1)
        const range = end - start
        let playheadPosition = start + range * pos
        if (playheadPosition > player.duration) {
          playheadPosition = player.duration
        }
        player.currentTime = playheadPosition
      } else {
        player.currentTime = 0
      }
    }
    const toggleContinuous = (flag: string) => {
      state.continuous = flag
    }

    // 再生状態を保持し他バンドルに移動しているかの確認
    watchEffect(() => {
      if (stateModule.playingBundleIndex !== currentBundleIndex.value) {
        state.holdPlaybackStateFlag = true
        state.lastContentFlag =
          stateModule.playingContentIndex === state.playingContents.length - 1
      } else {
        state.holdPlaybackStateFlag = false
        state.lastContentFlag =
          currentContentIndex.value === contents.value.length - 1
      }
    })

    const changeVolume = (val: number) => () => {
      const afterChangeVolume = state.volume + val
      if (afterChangeVolume < 0 || afterChangeVolume > 100) return
      state.volume += val
    }

    const downSpeed = () => {
      switch (state.speed) {
        case 0.25:
          return
        case 2.5:
          changeSpeed(2.0)
          break
        case 3.0:
          changeSpeed(2.5)
          break
        default:
          changeSpeed(state.speed - 0.25)
      }
    }

    const upSpeed = () => {
      switch (state.speed) {
        case 3.0:
          return
        case 2.5:
          changeSpeed(3.0)
          break
        case 2.0:
          changeSpeed(2.5)
          break
        default:
          changeSpeed(state.speed + 0.25)
      }
    }

    const changeCurrentSeconds = (sec: number) => () => {
      player.currentTime = sec
    }

    const changeLoop = () => {
      // 有効 → 無効、無効 → 有効 の切り替え
      player.loop = !player.loop
    }

    const toggleFullScreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        const itemViewerRef = document.getElementById('full-screen-div')
        if (itemViewerRef) {
          itemViewerRef.requestFullscreen()
        }
      }
    }

    /**
     * ショートカットキー
     */
    const preventStandardHotKeyActions = (event: any) => {
      event.stopPropagation()
      event.preventDefault()
    }

    const getPressedKey = (event: any) => {
      if (isServer) return
      const activeElement: any = document.activeElement
      // 共通ヘッダーの検索フォーム入力時はショートカットを無効化
      if (activeElement?.classList?.contains('keyword-search-input-form')) return
      // テキストフィールドの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input')) return
      // ラジオボタンの入力時はショートカットを無効化
      if (activeElement?.type === 'radio') return
      // レンジスライダーの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input-range')) return
      // モーダル表示時はショートカットを無効化
      if (document.getElementsByClassName('modal-window-container')?.length > 0) return

      if (props.viewerType !== 'TheVideoViewer') return
      // ブラウザのショートカットと競合するため
      if (event.ctrlKey || event.altKey) return
      const pressedKey = event.key
      let action: any
      switch (pressedKey) {
        case ' ':
        case 'k':
          if (event.shiftKey) return
          action = state.playing ? pause : play
          break
        case ',':
          action = prev
          break
        case '.':
          action = next
          break
        case 'm':
          action = state.volume === 0 ? unmute : mute
          break
        case 'ArrowUp':
          if (event.shiftKey) return
          action = changeVolume(5)
          break
        case 'ArrowDown':
          if (event.shiftKey) return
          action = changeVolume(-5)
          break
        case '<':
          action = downSpeed
          break
        case '>':
          action = upSpeed
          break
        case 'ArrowLeft':
          if (event.shiftKey) return
          action = changeCurrentSeconds(player.currentTime - 5)
          break
        case 'ArrowRight':
          if (event.shiftKey) return
          action = changeCurrentSeconds(player.currentTime + 5)
          break
        case 'l':
          action = changeCurrentSeconds(player.currentTime + 10)
          break
        case 'j':
          action = changeCurrentSeconds(player.currentTime - 10)
          break
        case '0':
          action = changeCurrentSeconds(player.duration * 0.0)
          break
        case '1':
          action = changeCurrentSeconds(player.duration * 0.1)
          break
        case '2':
          action = changeCurrentSeconds(player.duration * 0.2)
          break
        case '3':
          action = changeCurrentSeconds(player.duration * 0.3)
          break
        case '4':
          action = changeCurrentSeconds(player.duration * 0.4)
          break
        case '5':
          action = changeCurrentSeconds(player.duration * 0.5)
          break
        case '6':
          action = changeCurrentSeconds(player.duration * 0.6)
          break
        case '7':
          action = changeCurrentSeconds(player.duration * 0.7)
          break
        case '8':
          action = changeCurrentSeconds(player.duration * 0.8)
          break
        case '9':
          action = changeCurrentSeconds(player.duration * 0.9)
          break
        case 'N':
          action = forward
          break
        case 'P':
          action = backward
          break
        case 'Home':
          if (event.shiftKey) return
          action = changeCurrentSeconds(0)
          break
        case 'End':
          if (event.shiftKey) return
          action = changeCurrentSeconds(player.duration)
          break
        case 'r':
          action = changeLoop
          break
        case 'f':
          action = toggleFullScreen
          break
      }
      if (
        action &&
        pressedKey !== 'Control' &&
        pressedKey !== 'Alt' &&
        pressedKey !== 'Shift'
      ) {
        action()
        preventStandardHotKeyActions(event)
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', getPressedKey)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', getPressedKey)
    })

    const timeoutDelay = 3000
    const stopTimeout = () => {
      clearTimeout(state.timeoutId)
    }
    const renewTimeout = () => {
      if (state.playing) {
        stopTimeout()
        state.timeoutId = setTimeout(() => {
          state.showToolbar = false
        }, timeoutDelay)
      }
    }
    watch(
      () => state.showToolbar,
      (value) => {
        if (value) {
          renewTimeout()
        }
      },
      { immediate: true }
    )
    watch(
      () => state.playing,
      (value) => {
        if (value) {
          renewTimeout()
        } else {
          stopTimeout()
          state.showToolbar = true
        }
      }
    )

    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight)
    // 初期化
    setFillHeight()

    return {
      metaArrayProcessing,
      meta,
      state,
      play,
      pause,
      prev,
      next,
      bookmarked,
      bookmarkItem,
      seekbar,
      backward,
      forward,
      changeSpeed,
      toggleContinuous,
      unmute,
      mute,
      stopTimeout,
      renewTimeout,
      isMobile,
      currentContentId,
      currentBundleType,
      BASE_URL,
      contentsBundleThumbnail,
    }
  },
})
