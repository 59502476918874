import axios, { AxiosAdapter } from 'axios'
import axiosRetry from 'axios-retry'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { store } from '@/store/index'
import router from '@/router/index'

const retryClient = axios.create()
axiosRetry(retryClient, { retries: 3 })

const BASIC_AUTH_USERNAME = process.env.VUE_APP_BASIC_AUTH_USERNAME
const BASIC_AUTH_PASSWORD = process.env.VUE_APP_BASIC_AUTH_PASSWORD

const sessionTimeOutMsg = () => [{
  id: '001',
  type: 'success',
  text: 'sessionTimeoutMessage',
}]

const getApi = axios.create({
  baseURL: '',
  headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter)),
})

const cacheGet = async (path: string) => {
  if (window && window.location.hostname === 'localhost') { // local実行時にCORSのエラーが出るため
    return getData(path)
  }
  try {
    const { data } = await getApi.get(`${path}`)
    return data
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    throw new Error(response.statusText || response.data.message)
  }
}

const getData = async (url: string, status400Message = '') => {
  try {
    const response = await axios(url)
    return response.data
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (response.status === 400 && status400Message) throw new Error(status400Message)
    if (response.status >= 400) throw new Error(response.statusText || response.data.message)
  }
}
const getBlob = async (url: string, status400Message = '') => {
  try {
    const response = await axios(url, { responseType: 'blob' })
    return new Blob([response.data], {
      type: response.data.type,
    })
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (response.status === 400 && status400Message) throw new Error(status400Message)
    if (response.status >= 400) throw new Error(response.statusText || response.data.message)
  }
}

const postData = async (url: string, data: any = null, status400Message = '') => {
  const request: any = {
    method: 'post',
  }
  if (data) {
    request.headers = {
      'Content-Type': 'application/json',
    }
    request.data = data
  }
  try {
    const response = await axios(url, request)
    return response.data
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (response.status === 400 && status400Message) throw new Error(status400Message)
    // TODO 検証環境でAWSを通すことにより、
    // response.statusText および response.data.message が空で返却されるため、処理を変更
    // 暫定対応であるため、コードを見直す必要あり
    if (response.status >= 400) throw new Error(response.statusText || response.data.message || (response.status === 401 ? 'Unauthorized' : response.status === 500 ? 'Internal Server Error' : ''))
  }
}
const deleteData = async (url: string, status400Message = '') => {
  const request: any = {
    method: 'delete',
  }
  try {
    const response = await axios(url, request)
    return response.data
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (response.status === 400 && status400Message) throw new Error(status400Message)
    if (response.status >= 400) throw new Error(response.statusText || response.data.message)
  }
}

const getDataCredential = async (url: string, status400Message = '') => {
  try {
    await store.dispatch('setLoading', true)
    const response = await axios(url, { withCredentials: true })
    console.log(response)
    await store.dispatch('setLoading', false)
    return response
  } catch (error: any) {
    const response = error.response
    console.log(response)
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (location.pathname.startsWith('/dms') && response?.status === 401) {
      sessionStorage.removeItem('vuex')
      await router.push('/dms/online/login')
      await store.dispatch('setBannerMessage', sessionTimeOutMsg)
    }
    await store.dispatch('setLoading', false)
    return response
  }
}

const postDataCredential = async (url: string, data: any = null, isLoading = true) => {
  const request: any = {
    method: 'post',
  }
  if (data) {
    request.headers = {
      'Content-Type': 'application/json',
    }
    // ダウンロードLambdaをALBから直接呼び出すため、本番環境以外は拠点外からはBasic認証が必要
    if (url.includes('download-lambda-alb') && BASIC_AUTH_USERNAME && BASIC_AUTH_PASSWORD) {
      request.auth = {
        username: BASIC_AUTH_USERNAME,
        password: BASIC_AUTH_PASSWORD,
      }
    }
    request.withCredentials = true
    request.data = data
  }
  try {
    if (isLoading) await store.dispatch('setLoading', true)
    const response = await axios(url, request)
    console.log(response)
    if (isLoading) await store.dispatch('setLoading', false)
    return response
  } catch (error: any) {
    const response = error.response
    console.log(response)
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (location.pathname.startsWith('/dms') && response?.status === 401) {
      sessionStorage.removeItem('vuex')
      await router.push('/dms/online/login')
      await store.dispatch('setBannerMessage', sessionTimeOutMsg)
    }
    if (isLoading) await store.dispatch('setLoading', false)
    return response
  }
}
const putDataCredential = async (url: string, data: any = null, status400Message = '') => {
  const request: any = {
    method: 'put',
  }
  if (data) {
    request.headers = {
      'Content-Type': 'application/json',
    }
    request.withCredentials = true
    request.data = data
  }
  try {
    await store.dispatch('setLoading', true)
    const response = await axios(url, request)
    console.log(response)
    await store.dispatch('setLoading', false)
    return response
  } catch (error: any) {
    const response = error.response
    console.log(response)
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (location.pathname.startsWith('/dms') && response?.status === 401) {
      sessionStorage.removeItem('vuex')
      await router.push('/dms/online/login')
      await store.dispatch('setBannerMessage', sessionTimeOutMsg)
    }
    await store.dispatch('setLoading', false)
    return response
  }
}
const postDataBlob = async (url: string, data: any = null, status400Message = '') => {
  const request: any = {
    method: 'post',
  }
  if (data) {
    request.headers = {
      'Content-Type': 'application/json',
    }
    request.withCredentials = true
    request.data = data
    request.responseType = 'blob'
  }
  try {
    await store.dispatch('setLoading', true)
    const response = await axios(url, request)
    console.log(response)
    await store.dispatch('setLoading', false)
    return response
  } catch (error: any) {
    const response = error.response
    console.log(response)
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (location.pathname.startsWith('/dms') && response?.status === 401) {
      sessionStorage.removeItem('vuex')
      await router.push('/dms/online/login')
      await store.dispatch('setBannerMessage', sessionTimeOutMsg)
    }
    await store.dispatch('setLoading', false)
    return response
  }
}

const getDataWithRetry = async (url: string, status400Message = '') => {
  try {
    const response = await retryClient.get(url)
    return response.data
  } catch (error: any) {
    const response = error.response
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    if (response.status === 400 && status400Message) throw new Error(status400Message)
    if (response.status >= 400) throw new Error(response.statusText || response.data.message)
  }
}

const getDataBlob = async (url: string, status400Message = '') => {
  const request: any = {
    method: 'get',
  }
  request.withCredentials = true
  request.responseType = 'blob'
  try {
    await store.dispatch('setLoading', true)
    const response = await axios(url, request)
    console.log(response)
    await store.dispatch('setLoading', false)
    return response
  } catch (error: any) {
    const response = error.response
    console.log(response)
    if (response.status === 503) {
      router.push('/error/503')
      return
    }
    await store.dispatch('setLoading', false)
    return response
  }
}

const getDataBackground = async (url: string): Promise<void> => {
  await axios(url, { withCredentials: true })
}

export {
  getData,
  getBlob,
  postData,
  getDataCredential,
  postDataCredential,
  putDataCredential,
  postDataBlob,
  getDataWithRetry,
  getDataBlob,
  deleteData,
  cacheGet,
  getDataBackground,
}
