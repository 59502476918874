/**
 * ImageViewerエラー
 */
const IVError = {
  /**
   * エラーなし
   */
  NONE: 0,

  /**
   * コンポーネントの初期化に失敗した
   */
  FAILED_TO_INIT_COMPONENT: 1,

  /**
   * コンテンツが指定されていない
   */
  CONTENT_UNSPECIFIED: 2,

  /**
   * 不正なコンテンツが指定された
   */
  INVALID_CONTENT: 3,

  /**
   * コンテンツのダウンロードに失敗した
   */
  FAILED_TO_LOAD_CONTENT: 4,

  /**
   * リソースのダウンロードに失敗した
   */
  FAILED_TO_LOAD_RESOURCE: 5,

  /**
   * 指定されたページインデックスが無効
   */
  INVALID_PAGE_INDEX: 6,

} as const

type IVError = typeof IVError[keyof typeof IVError];

export { IVError }
