
import { defineComponent, reactive, computed, ref, watch, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { DownloadOthersRequest } from '@/data/@types/DownloadOthersRequest'
import AppButton from '@/components/atoms/AppButton.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import FileViewerBreadcrumbs from '@/components/organisms/ItemContentViewer/FileViewerBreadcrumbs.vue'
import { generetePublicPath } from '@/domain/item/itemViewer/publicPath'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { AccessToken, AccessTokenService } from '@/store/modules/AccessToken'
import { DirectoryIndex } from '@/data/@types/DirectoryIndex'
import { getItemCopyright } from '@/domain/item/itemViewer/itemCopyright'
import { ruleToType, getPermissionText } from '@/data/Permission'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { Content } from '@/data/@types/Content'
import { titleProcessing } from '@/domain/item/ItemListView/titleLabel'
import PackageGuideModal from '@/components/static/PackageGuideModal.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import { sessionCheck } from '@/domain/session/sessionCheck'

const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

export default defineComponent({
  components: {
    AppButton,
    DssIcon,
    FileViewerBreadcrumbs,
    IconLoading,
    PackageGuideModal,
    ModalWindow,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const router = useRouter()
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const isProduction = process.env.VUE_APP_ENV === 'production'
    const currentBundle = reactive<ContentsBundle>(store.getters.currentContentsBundle)
    const changedDirectoryId = computed(() => store.getters.changeFileViewerDirectory)
    const pid = props.item.pid.split('/').slice(-1).toString()
    const permissionRule = ref(props.item.permission.rule) // アイテムの公開範囲
    const currentDirectoryId = computed(() => store.getters.currentFileViewerDirectory)
    const indexes = computed(() => {
      return store.getters.currentContentsBundle.directoryIndex
    })
    const isAllowedDownload = computed(() => store.getters.isAllowedDownload)
    const downloadingFlag = computed(() => store.getters.othersDownloadProcessingStatus === 'downloading')
    const isPackageUser = computed(() => store.getters.isPackageUser)

    const state = reactive<{ currentId: string, checkedItems: any, tokens: AccessToken[], loading: boolean, currentContentId: string, packageTitle: string, currentBundle: ContentsBundle }>({
      currentId: 'root',
      checkedItems: [],
      tokens: [],
      loading: false,
      currentContentId: '',
      packageTitle: '',
      currentBundle: currentBundle,
    })

    const newLineInRestrictionMessage = '\r\n' + i18n.t('restrictionMessage.fileViewer.displayMetaData') + ' : '

    const currentDirectory = computed(() => {
      return indexes.value?.find((index: { id: string }) => index.id === state.currentId) || {}
    })
    const files = computed(() => {
      return currentDirectory.value.children?.map((content: any) => {
        if (content.type === 'directory') {
          return content
        } else {
          const token = state.tokens.find((token) => token.cid === content.id)
          const enable = token && Date.now() < token.timestamp
          const path = BASE_URL + generetePublicPath(content.publicPath)
          return {
            ...content,
            enable,
            downloadPath: enable
              ? `${path}?token=${AccessTokenService.getTokenString(
                  token as AccessToken
                )}`
              : '',
          }
        }
      })
    })

    const breadcrumbs = ref([
      {
        id: props.item.pid,
        title: titleProcessing(props.item.meta, props.item.collections[0]),
        type: 'leaf',
      },
      {
        id: currentDirectory.value.id,
        title: currentDirectory.value.title,
        type: 'directory',
      },
    ])

    const openDirectory = (id: string) => {
      state.currentContentId = ''
      state.currentId = id
      breadcrumbs.value.push({
        id: currentDirectory.value.id,
        title: currentDirectory.value.title,
        type: 'directory',
      })
      changeDirectory(id, false)
    }

    const changeDirectory = (id: string, fromBreadCrumbs: boolean) => {
      if (paramsContentIndex.value && fromBreadCrumbs) {
        // バンくずリストからの移動時のみ
        router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (store.getters.bundleNumber + 1))
      }
      state.currentId = id
      let flg = true
      breadcrumbs.value = breadcrumbs.value.filter((item) => {
        if (item.id === id) {
          flg = false
        }
        return item.id === id || flg
      })
      store.commit('updateFileViewerDirectory', id)
    }

    // ESのpublicPathからS3のkeyを生成する
    // publicPath: contents/{pid}/{contentBundleId}/{contentId}.pdf
    // S3 key: contents/{pid}/{contentBundleId}/{contentId}/{contentId}.pdf
    const generateFilePath = (publicPath: string) => BASE_URL + generetePublicPath(publicPath)

    /**
     * @param currentDirectoryPath 指定されたディレクトリのパス
     * @param contentsParentPath コンテンツのparentPath
     * @returns ダウンロード時のディレクトリ構成(parentPath)
     * ディレクトリが指定された状態でのダウンロード時のディレクトリ構成(parentPath)を作成
     */
    const getDownloadParentPath = (currentDirectoryPath: string, contentsParentPath: string) => {
      if (!currentDirectoryPath) return contentsParentPath
      return contentsParentPath.slice(currentDirectoryPath.length - 1)
    }

    /**
     * 4桁の連番を作成
     */
    const addZeroPadding = (num: number) => {
      return ('000' + num).slice(-4)
    }

    // S3からコンテンツファイルのダウンロード
    const downloadXML = (url: string, originalFileName: string) => {
      var xhr = new XMLHttpRequest()
      var a = document.createElement('a'); var file

      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        file = new Blob([this.response], { type: 'application/octet-stream' })
        if (this.response.type !== 'application/pdf') {
          // PDF以外の場合はリネームしてダウンロードする
          a.href = window.URL.createObjectURL(file)
        } else {
          // PDFの場合は別タブで開く
          a.href = url
        }
        // ファイル名のリネーム処理
        a.download = `digidepo_${pid}_${store.getters.isAllowedPrintInFileViewer ? 'po' : 'pn'}_${originalFileName}`
        a.target = '_blank'
        a.rel = 'noopener'
        a.click()
      }
      xhr.send()
    }

    const downloadFile = async (filePath: string, originalFileName: string, contentPermissionRule: string) => {
      store.commit('DOWNLOAD_OTHER_FILE', { contentMeta: store.getters.item })
      await store.dispatch('checkPrintInFileViewer', { type: contentPermissionRule, action: 'printKss' })
      const isServer = typeof window === 'undefined'
      if (!isServer) {
        downloadXML(filePath, originalFileName)
      }
    }

    /**
     * 一括ダウンロード
     */
    const downloadOthers = async () => {
      if (!currentBundle.contents?.length) return
      const cancelDownload = () => {
        store.commit('UPDATE_OTHERS_DOWNLOAD_STATUS', {
          status: 'error',
          fixedText: i18n.t('header.toaster.bulk_download_error'),
        })
      }
      if (!await sessionCheck(route.fullPath, cancelDownload)) return

      const itemName = store.getters.item.meta['0001Dtct'][0]
      const name: StringKeyObject = currentBundle.name
      const bundleName = name?.[lang.value]

      let contents
      let fileName
      let downloadOthersRequest: DownloadOthersRequest
      if (currentDirectoryId.value !== 'root') {
        // ディレクトリが指定されている場合の一括DL
        const currentDirectoryTitle = indexes.value.find((v: DirectoryIndex) => v.id === currentDirectoryId.value).title
        const parentDirectoryPath = getParentPath(currentDirectoryId.value, []).map((v: DirectoryIndex) => v.title).join('/')
        const currentDirectoryParentPath = `/${parentDirectoryPath ? parentDirectoryPath + '/' : ''}${currentDirectoryTitle}/`
        contents = currentBundle.contents
          .filter(content => content.parentPath?.includes(currentDirectoryParentPath))
          .map((content, i) => {
            return {
              cid: content.id,
              // ダウンロード時のファイル名  = digidepo_{PIDの数字部分}_{po又はpn}_{オリジナルファイル名}
              contentName: content.originalFileName || addZeroPadding(i + 1), // 0オリジンのため
              parentPath: removeInvalidCharacters(getDownloadParentPath(currentDirectoryParentPath, content.parentPath) || ''),
            }
          })
        // ファイル名の長さを150バイト以内にするため
        fileName = cutAtSpecifiedBytes(`${itemName}_${bundleName || '0001'}_${currentDirectoryTitle}`) + '.zip'
        downloadOthersRequest = {
          pid: pid,
          bid: currentBundle.id,
          contents: contents,
          fileName: fileName,
          // ディレクトリが選択されてた状態で一括ダウンロードを行った場合はmetadataファイルは作成しない
        }
      } else {
        // ディレクトリの指定がない場合の一括DL
        contents = currentBundle.contents.map((content, i) => {
          return {
            cid: content.id,
            // ダウンロード時のファイル名  = {オリジナルファイル名} | 連番
            contentName: content.originalFileName || addZeroPadding(i + 1), // 0オリジンのため
            parentPath: removeInvalidCharacters(content.parentPath),
          }
        })
        // ファイル名の長さを150バイト以内にするため
        fileName = cutAtSpecifiedBytes(`${itemName}_${bundleName || '0001'}`) + '.zip'
        downloadOthersRequest = {
          pid: pid,
          bid: currentBundle.id,
          contents: contents,
          fileName: fileName,
          accessRights: getItemCopyright(props.item),
        }
      }

      await store.dispatch('getDownloadOthersUrl', downloadOthersRequest)
      const downloadUrl = store.getters.downloadOthersUrl

      if (downloadUrl && Object.keys(downloadUrl).length) {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = downloadUrl
        a.click()
        a.remove()
      }
    }

    // 引き渡された文字列を 150 byte までで切り抜いて返却
    const cutAtSpecifiedBytes = (str: string) => {
      let byteLength = 0
      let convertStr = ''
      for (const character of Array.from(str)) {
        if (byteLength >= 150) break
        byteLength += (new Blob([character])).size
        convertStr += character
      }
      return convertStr
    }

    const currentContents: Array<Content> | undefined = store.getters.contents
    const paramsContentIndex = computed(() => route.params.contentIndex)
    const toCurrentContentDirectory = () => {
      if (paramsContentIndex.value) {
        // コンテンツの指定がある場合
        if (!currentContents?.[Number(paramsContentIndex.value) - 1]) {
          // バンドルをまたぐ移動をした際にエラーとなるために回避する
          // FIXME バンドルをまたいだ場合は本処理を実行する前にTheFileViewerコンポーネントを非表示にする
          return
        }
        const currentContentParentPath = currentContents?.[Number(paramsContentIndex.value) - 1].parentPath
        if (currentContentParentPath) {
          changeDirectory('root', false)
          if (currentContentParentPath !== '/') {
            const splitPath = currentContentParentPath.split('/').slice(1, -1)
            for (let depth = 0; depth < splitPath.length; depth++) {
              changeDirectory(`d${depth}` + splitPath[depth], false)
              openDirectory(`d${depth}` + splitPath[depth])
            }
          }
        }
        state.currentContentId = currentContents?.[Number(paramsContentIndex.value) - 1].id
      } else if (changedDirectoryId.value) {
        // ディレクトリの指定がある場合
        changeDirectory('root', false)
        if (changedDirectoryId.value === 'root') {
          state.currentContentId = ''
          return
        }
        const parentDirectory = getParentPath(changedDirectoryId.value, [])
        parentDirectory.forEach(v => {
          openDirectory(v.id)
        })
        openDirectory(changedDirectoryId.value)
        // 表示しているディレクトリを更新
        changeDirectory(changedDirectoryId.value, true)
      } else {
        state.currentContentId = ''
      }
    }

    // URLでcontentIndexが指定されたとき、そのコンテンツを含むディレクトリをビューア内に表示させる
    onMounted(async () => {
      state.loading = true
      const tokens = await AccessTokenService.findAll()
      state.tokens = tokens
      toCurrentContentDirectory()
      state.loading = false
    })

    watch(paramsContentIndex, () => {
      toCurrentContentDirectory()
    })

    // コンテンツタブでディレクトリを指定したときに指定されたディレクトリを表示させる
    watch(changedDirectoryId, () => {
      if (currentDirectory.value.id !== changedDirectoryId.value) {
        // パンくずリストを更新
        changeDirectory('root', false)
        if (changedDirectoryId.value === 'root') return
        const parentDirectory = getParentPath(changedDirectoryId.value, [])
        parentDirectory.forEach(v => {
          openDirectory(v.id)
        })
        openDirectory(changedDirectoryId.value)
        // 表示しているディレクトリを更新
        changeDirectory(changedDirectoryId.value, true)
      }
    })

    // ファイルビューアのコンテンツ表示位置までスクロールさせる
    watchEffect(() => {
      const currentContentIndex = store.getters.contentNumber
      const currentContentId = currentContents?.[currentContentIndex]?.id
      setTimeout(() => {
        const referencePositionContent = document.querySelector(
          '.file-viewer-header'
        )
        const selectedContent = document.querySelector(`.file-viewer-content-${currentContentId}`)
        if (!selectedContent) return
        const element = document.querySelector('.file-viewer')
        const referencePosition =
          referencePositionContent?.getBoundingClientRect()
        const contentPosition = selectedContent?.getBoundingClientRect()
        if (!element) return
        element.scrollTo(0, contentPosition!.top - referencePosition!.top)
      }, 100)
    })

    // 親のディレクトリを配列で取得する
    const getParentPath = (currentDirectory: string, parentList: Array<DirectoryIndex>) => {
      indexes.value.forEach((v: DirectoryIndex) => {
        const hasParentDirectory = v.children.some(child => child.id === currentDirectory)
        if (hasParentDirectory) {
          if (v.id === 'root') return
          parentList.unshift(v)
          getParentPath(v.id, parentList)
        }
      })
      return parentList
    }

    const removeInvalidCharacters = (parentPath: string) => {
      if (!parentPath) return ''
      let path = '/'
      const pathList = parentPath.split('/').slice(1, -1)
      pathList.forEach(v => {
        const directoryName = v.replace(/[*?"<>:\\\s|]/g, '')
        path += (directoryName + '/')
      })
      return path
    }

    const contentRestrictionMessage = (content: any) => {
      return content?.meta?.[store.getters.MetadataObjectById(742)?.field]
    }
    const bundlePermissionRule = computed(() => currentBundle?.permission?.rule ? currentBundle.permission.rule : permissionRule.value)
    const contentPermissionRule = (content: any) => {
      return content.permission?.rule ? content.permission.rule : bundlePermissionRule.value
    }
    const contentPermissionType = (content: any) => ruleToType(contentPermissionRule(content))
    const isContentPermission = (content: any) => {
      const tokens = state.tokens.find((token) => token.cid === content.id)
      return !!tokens
    }

    // 図書付録再生機能関連
    const isPackageGuideModalVisible = computed(() => store.getters.showPackageGuideModal)

    const openPackageGuideModal = () => {
      state.packageTitle = titleProcessing(props.item.meta, props.item.collections[0])
      store.dispatch('setShowPackageGuideModal')
    }

    const closePackageGuideModal = () => {
      store.dispatch('closePackageGuideModal')
    }

    const hasStreamingPackage = computed(() => !!currentBundle?.packageEnv)

    return {
      state,
      currentDirectory,
      files,
      breadcrumbs,
      openDirectory,
      changeDirectory,
      generateFilePath,
      downloadFile,
      downloadOthers,
      contentPermissionRule,
      contentPermissionType,
      isContentPermission,
      getPermissionText,
      contentRestrictionMessage,
      isRekion,
      isProduction,
      newLineInRestrictionMessage,
      isAllowedDownload,
      downloadingFlag,
      isPackageUser,
      isPackageGuideModalVisible,
      openPackageGuideModal,
      closePackageGuideModal,
      hasStreamingPackage,
    }
  },
  methods: {
    convertDateFormat (date: string, locale: string) {
      return locale + ': ' + date.split(' ')[0]
    },
    calculationPrefixFileSize (value: number) {
      if (value < 1024) return `${Number.parseInt(String(value))} bytes`
      value /= 1024
      if (value < 1024) return `${Number.parseInt(String(value))} KB`
      value /= 1024
      if (value < 1024) return `${Number.parseInt(String(value))} MB`
      value /= 1024
      return `${Number.parseInt(String(value))} GB`
    },
  },
})
