import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b6801d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "window-context" }
const _hoisted_2 = { class: "window-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!
  const _component_TheLoginForm = _resolveComponent("TheLoginForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSessionTimeoutModalShow)
      ? (_openBlock(), _createBlock(_component_ModalWindow, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClose())),
          class: "window-modal window-annotation window-modal-login-annotation-modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('sessionCheck.message', { newLine: '\r\n' })), 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppButton, {
                styled: "is-accent",
                class: "display-block",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpenOfLoginForm())),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.onOpenOfLoginForm()), ["prevent"]), ["space","enter"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('sessionCheck.login')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLoginModalShow)
      ? (_openBlock(), _createBlock(_component_ModalWindow, {
          key: 1,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClose())),
          heading: _ctx.$t('parts.login'),
          class: "window-modal window-login-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TheLoginForm, {
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onLogin()))
            })
          ]),
          _: 1
        }, 8, ["heading"]))
      : _createCommentVNode("", true)
  ], 64))
}