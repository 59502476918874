import { JCSPage } from './ContentStructure'
import { KomaElement, KomaLevel } from './KomaElement'
import {
  BoundingBox,
  Circle,
  deg2rad,
  mm2px,
  PhysicalPosition,
  PhysicalRect,
  PhysicalSize,
  PolygonRect2D,
  RectF,
  RectI,
  RectMM,
  Rotation,
  transform2D,
  transform2DArray,
  Vec2,
} from './ImageViewerCommon'
import { DLProgress, TileDrawer } from './TileDrawer'
import { DrawResult, PagePostioning, VisualElement } from './VisualElement'

const polygonBoolean = require('2d-polygon-boolean') // eslint-disable-line

class PageElement extends VisualElement {
  /**
   * ページID
   */
  private pageID_ = -1;

  /**
   * このページが参照している画像要素
   */
  private komaElement_?: KomaElement = undefined;

  /**
   * （片）ページの傾き補正用回転情報
   * 片ページのときのみ使用する。
   * 回転は分割後ページの中心
   */
  private rotation_ = new Rotation();

  /**
   * 自動余白削除機能で使用する、メタ情報がから渡される生の切り抜き範囲情報
   * 原点 (rawCropping_.left, rawCropping_.top) = (0 , 0) は、rotation_ で回転した後のバウンディングボックスの左上とする。
   * 画像ビューア内部では開店前の左上を原点としているので、オフセットを取る必要がある。
   */
  private rawCropping_?: RectF = undefined;

  /**
   * （rawCropping_の）回転後原点を（ビューア内部で使用する）回転前原点に変換した値
   */
  private convertedCropping_ = new RectF();

  public constructor () {
    super()
    this.pageID_ = -1
  }

  /**
   * 初期化する
   * @param jsonPage
   * @param komas
   * @returns
   */
  public init (jsonPage: JCSPage, komasMap: Map<number, KomaElement>): boolean {
    this.komaElement_ = komasMap.get(jsonPage.Koma)

    if (!this.komaElement_) return false

    this.pageID_ = jsonPage.PageID

    this.usingKomaArea_.left = jsonPage.Area.Left
    this.usingKomaArea_.top = jsonPage.Area.Top
    this.usingKomaArea_.right = jsonPage.Area.Right
    this.usingKomaArea_.bottom = jsonPage.Area.Bottom

    if (this.usingKomaArea_.left <= 0 && this.usingKomaArea_.right >= 1.0) {
      this.pagePositioning = PagePostioning.FullPage
    } else if (this.usingKomaArea_.left <= 0) {
      this.pagePositioning = PagePostioning.LeftPage
    } else {
      this.pagePositioning = PagePostioning.RightPage
    }

    return true
  }

  public get clippingArea (): PolygonRect2D | undefined {
    if (!this.clippingArea_) {
      return this.komaElement_?.clippingArea
    }
    this.clippingAreaBuf_.copy(this.clippingArea_)
    return this.clippingAreaBuf_
  }

  /**
   * ダミーとして初期化する
   * @param jsonPage
   * @param komas
   * @returns
   */
  public initDammy (koma: KomaElement): boolean {
    this.komaElement_ = koma
    this.pageID_ = koma.komaID

    this.usingKomaArea_.left = 0.0
    this.usingKomaArea_.top = 0.0
    this.usingKomaArea_.right = 1.0
    this.usingKomaArea_.bottom = 1.0

    this.antiTiltAngle_ = koma.antiTiltAngle
    if (koma.clippingArea) {
      this.clippingArea_ = koma.clippingArea.clone()
    }

    return true
  }

  public get pageID (): number {
    return this.pageID_
  }

  public get koma (): KomaElement | undefined {
    return this.komaElement_
  }

  /**
   * ページを描画可能な状態にする
   * 最小解像度画像をDLする
   * @param onLoadedPerEachTiles 各タイルのDL完了毎に実行するコールバック関数
   * @returns
   */
  public activate (
    dummyKomaResolutionUpdate: () => PhysicalSize,
    onLoadedPerEachTiles?: (success: boolean, tileDrawer: TileDrawer) => void
  ): boolean {
    if (this.komaElement_) {
      this.komaElement_.getKoma(dummyKomaResolutionUpdate)
        .then(() => {
          if (this.komaElement_) {
            this.komaElement_.loadTiles(-1, onLoadedPerEachTiles)
          }
        })
      return true
    }
    return false
  }

  /**
   * 指定した拡大率でこのページを描画した場合のピクセルサイズを返す
   * 回転が考慮されない
   * @param scale
   * @param cropping 切り抜き範囲
   * @returns
   */
  public getElementSize (scale = 1.0, cropping?: RectMM): BoundingBox {
    const res = new BoundingBox()

    if (this.komaElement_) {
      const levelInfo = this.komaElement_.getKomaLevel(0)
      if (levelInfo) {
        res.width =
          levelInfo.originWidth *
          (this.usingKomaArea_.right - this.usingKomaArea_.left) *
          scale
        res.height =
          levelInfo.originHeight *
          (this.usingKomaArea_.bottom - this.usingKomaArea_.top) *
          scale

        if (cropping && cropping.width > 0 && cropping.height > 0) {
          const cropRate = new RectF()
          const originWidth = res.width
          const originHeight = res.height
          cropRate.left = mm2px(cropping.left, this.komaElement_.dpi) * scale / originWidth
          cropRate.top = mm2px(cropping.top, this.komaElement_.dpi) * scale / originHeight
          cropRate.right = mm2px(cropping.right, this.komaElement_.dpi) * scale / originWidth// + cropRate.left
          cropRate.bottom = mm2px(cropping.bottom, this.komaElement_.dpi) * scale / originHeight// + cropRate.top

          /*
          if (cropRate.left < 0) {
            cropRate.left = 0
          }
          if (cropRate.top < 0) {
            cropRate.top = 0
          }
          if (cropRate.right > 1) {
            cropRate.right = 1
          }
          if (cropRate.bottom > 1) {
            cropRate.bottom = 1
          }
          */

          const croppedWidth =
            originWidth *
            cropRate.width //*
            // scale

          const croppedHeight =
            originHeight *
            cropRate.height //*
            // scale

          // const croppingRect = new PhysicalRect()
          res.left = (originWidth * cropRate.left)
          res.top = (originHeight * cropRate.top)
          // res.right = (res.width * cropRate.right)
          // res.bottom = (res.height * cropRate.bottom)
          res.width = croppedWidth
          res.height = croppedHeight

          res.offset.left = res.left
          res.offset.top = res.top
          res.offset.right = originWidth * -(1.0 - cropRate.right)
          res.offset.bottom = originHeight * -(1.0 - cropRate.bottom)
        }
      }
    }

    return res
  }

  public getPageWHRatio (cropping: boolean): number {
    if (!this.komaElement_) return 1

    const level = this.komaElement_.getKomaLevel(0)
    if (!level) return 1

    if (cropping) {
      const w =
        level.originWidth -
        level.originWidth * this.usingKomaArea_.left -
        level.originWidth * (1.0 - this.usingKomaArea_.right)
      const h =
        level.originHeight -
        level.originHeight * this.usingKomaArea_.top -
        level.originHeight * (1.0 - this.usingKomaArea_.bottom)
      return w / h
    }

    return level.originWidth / level.originHeight
  }

  /**
   * このページを指定したコンテキストに描画する
   * @param targetLevel 描画する解像度レベル 0未満で適切な解像度を自動判定
   * @param context
   * @param bbContext バックバッファキャンバス
   * @param left canvas描画座標 左
   * @param top canvas描画座標 上
   * @param scale 画像のオリジナルサイズに対する描画スケール
   * @param drawBG 下地を描画するか否か
   * @param delayDrawFunc 未ダウンロードタイルがダウンロード完了したときに実行する関数
   * @param rotation 回転情報 ユーザーによる回転の角度。傾き補正値はここで渡さない
   * @param clipping 自動余白削除を使用するか否か
   * @param cropping 印刷切り抜き範囲
   * @param blitPerfect タイルがすべて描画されない限りメインキャンバスへ転送しない
   */
  public draw (
    targetLevel: number,
    context: CanvasRenderingContext2D,
    bbCanvas: HTMLCanvasElement,
    originalCanvasSize: PhysicalSize,
    left: number,
    top: number,
    offsetX: number,
    offsetY: number,
    scale: number,
    drawBG: boolean,
    delayDrawFunc: (() => void) | undefined,
    rotation: Rotation,
    clipping: boolean,
    cropping: RectMM | undefined,
    clippingOffsetLeft: number,
    is2In1: boolean,
    transformedOffsetY: number,
    blitPerfect = true
  ): DrawResult {
    if (!this.komaElement_) {
      return DrawResult.Failed
    }

    // 最大解像度レベル情報
    const originLevelInfo = this.komaElement_.getKomaLevel(0)

    // 描画対象の解像度レベル
    let level = targetLevel
    if (level < 0) { level = this.calcDecentLevel(this.getElementSize(scale)) }
    const levelInfo = this.komaElement_.getKomaLevel(level)
    if (!levelInfo || !originLevelInfo) {
      return DrawResult.Failed
    }

    if (levelInfo.isDummy) {
      return DrawResult.Retry
    }

    left += offsetX
    top += offsetY

    // 最大解像度をベースとするscaleを、描画対象の解像度の拡大率に調整したスケール値
    const ajustedScale =
      (scale * originLevelInfo.originWidth) / levelInfo.originWidth

    const scaledOriginSize = new PhysicalSize(
      originLevelInfo.originWidth * scale,
      originLevelInfo.originHeight * scale
    )

    const pageDrawSize = new PhysicalSize(
      scaledOriginSize.width *
      (this.usingKomaArea_.right - this.usingKomaArea_.left),
      scaledOriginSize.height *
      (this.usingKomaArea_.bottom - this.usingKomaArea_.top)
    )

    // タイル余白と有効画像範囲の補正係数
    const croppingAreaAdjust = new Vec2(
      levelInfo.originWidth / levelInfo.fullWidth,
      levelInfo.originHeight / levelInfo.fullHeight
    )
    // タイル余白を考慮（含めた）した有効範囲割合
    const adjustedCroppingArea = new RectF(
      this.usingKomaArea_.left * croppingAreaAdjust.x,
      this.usingKomaArea_.top * croppingAreaAdjust.y,
      this.usingKomaArea_.right * croppingAreaAdjust.x,
      this.usingKomaArea_.bottom * croppingAreaAdjust.y
    )

    let destOffsetX = 0
    const destOffsetY = new Array<number>(levelInfo.tileY)
    destOffsetY[0] = 0
    const tileCount = this.komaElement_.getTileCount(level)

    const bbContext = bbCanvas.getContext('2d')
    if (!bbContext) {
      return DrawResult.Failed
    }

    bbContext.save()

    let croppingPolygon: Array<Array<number>> | undefined
    let clippingPolygon: Array<Array<number>> | undefined

    // crop による切り抜き処理
    if (cropping && cropping.width > 0 && cropping.height > 0) {
      // 余白は「コマ画像をcanvasに描画した際の領域」をもとに求める
      // このページの回転前の左辺座標からコマ全体の左辺を求める
      const komaLeft = left - offsetX
      const komaTop = top - offsetY

      const croppingRect = new PhysicalRect()

      const cropRate = new RectF()
      cropRate.left = mm2px(cropping.left, this.komaElement_.dpi) * scale / pageDrawSize.width
      cropRate.top = mm2px(cropping.top, this.komaElement_.dpi) * scale / pageDrawSize.height
      cropRate.right = mm2px(cropping.right, this.komaElement_.dpi) * scale / pageDrawSize.width
      cropRate.bottom = mm2px(cropping.bottom, this.komaElement_.dpi) * scale / pageDrawSize.height

      if (is2In1 && offsetX !== 0) {
        // 右ページの場合 左ページ分のオフセットが必要
        croppingRect.left = (komaLeft + pageDrawSize.width * cropRate.left)// + offsetX
        croppingRect.right = (komaLeft + pageDrawSize.width * cropRate.right)// + offsetX
      } else {
        croppingRect.left = (komaLeft + pageDrawSize.width * cropRate.left) - offsetX
        croppingRect.right = (komaLeft + pageDrawSize.width * cropRate.right) - offsetX
      }
      croppingRect.top = (komaTop + pageDrawSize.height * cropRate.top)
      croppingRect.bottom = (komaTop + pageDrawSize.height * cropRate.bottom)

      croppingPolygon = new Array<Array<number>>()
      croppingPolygon.push([
        croppingRect.left,
        croppingRect.top,
      ])
      croppingPolygon.push([
        croppingRect.right,
        croppingRect.top,
      ])
      croppingPolygon.push([
        croppingRect.right,
        croppingRect.bottom,
      ])
      croppingPolygon.push([
        croppingRect.left,
        croppingRect.bottom,
      ])
    }

    // 回転 余白自動削除と同時に傾き補正が行われる
    const rotMat = new DOMMatrix()

    const clippedDrawSize = new PhysicalSize(pageDrawSize.width, pageDrawSize.height)
    let clippedOffsetX = 0
    let clippedOffsetY = 0

    if (clipping && this.clippingArea && this.clippingArea.width > 0 && this.clippingArea.height > 0) {
      // if(this.pagePositioning === PagePostioning.FullPage){
      clippedDrawSize.width = (this.clippingArea.width * scale)
      clippedDrawSize.height = (this.clippingArea.height * scale)
      clippedOffsetX = this.clippingArea.vertices[0].x * scale
      clippedOffsetY = this.clippingArea.vertices[0].y * scale
      // }

      if (this.pagePositioning !== PagePostioning.FullPage) {
        // 片ページ

        const clipAdjust = new PhysicalRect()

        if ((this.usingKomaArea_.left * originLevelInfo.originWidth) > this.clippingArea.vertices[0].x) {
          clipAdjust.left = (this.usingKomaArea_.left * originLevelInfo.originWidth)
        } else {
          clipAdjust.left = this.clippingArea.vertices[0].x
        }
        if ((this.usingKomaArea_.right * originLevelInfo.originWidth) < this.clippingArea.vertices[1].x) {
          clipAdjust.right = (this.usingKomaArea_.right * originLevelInfo.originWidth)
        } else {
          clipAdjust.right = this.clippingArea.vertices[1].x
        }

        clippedDrawSize.width = clipAdjust.width * scale

        // clippedDrawSize.height *= 0.5

        if (this.pagePositioning === PagePostioning.RightPage) {
          clippedOffsetX = 0
        }
      }
    }

    /*
    const _debugDrawPivot = new PhysicalPosition()
    let _debugLeft = 0
    let _debugAngle = 0
    */
    if (clipping || rotation.angle !== 0) {
      // 傾き補正用にCanvasを回転させる
      const pivotTransfer = new PhysicalPosition()
      // 描画されたときの回転ピボットがcanvas上のどの座標にあるか算出する
      pivotTransfer.x = clippedDrawSize.width * rotation.pivot.x
      // 左右ページで共通のY座標が必要
      pivotTransfer.y = clippedDrawSize.height * rotation.pivot.y
      // コマの回転ピボットがCanvasのどの座標に位置するか調べる
      // コマ上の座標をページ上の座標に変換しないといけない
      pivotTransfer.x += left
      pivotTransfer.y += top

      /*
      _debugDrawPivot.copy(pivotTransfer)
      _debugLeft = left
      */
      // 回転ピボット座標を左上原点に移動させる
      bbContext.translate(pivotTransfer.x, pivotTransfer.y)
      rotMat.translateSelf(pivotTransfer.x, pivotTransfer.y)

      // キャンバスを回転させる
      let angle = rotation.angle
      // 2in1表示では antiTiltAngle_ を使用しない
      if (clipping && !is2In1) {
        angle += this.antiTiltAngle_
      }
      bbContext.rotate(deg2rad(angle))
      rotMat.rotateSelf(angle)

      // _debugAngle = angle

      // 回転ピボット分移動させた位置を戻す
      bbContext.translate(-pivotTransfer.x, -pivotTransfer.y)
      rotMat.translateSelf(-pivotTransfer.x, -pivotTransfer.y)
    }

    // 2in1 の高さ揃え
    bbContext.translate(0, transformedOffsetY)
    rotMat.translateSelf(0, transformedOffsetY)

    // clippingによる自動余白削除
    const clippingArea = this.clippingArea
    if (clipping && clippingArea && clippingArea.width > 0 && clippingArea.height > 0) {
      left -= clippedOffsetX
      top -= clippedOffsetY

      clippingPolygon = new Array<Array<number>>()

      let clipOffsetX = 0
      if (this.pagePositioning === PagePostioning.RightPage) {
        clipOffsetX = pageDrawSize.width - scaledOriginSize.width

        // LT
        clippingPolygon.push([
          left,
          top + clippingArea.vertices[0].y * scale,
        ])

        // RT
        clippingPolygon.push([
          left + clippingArea.vertices[1].x * scale + clipOffsetX,
          top + clippingArea.vertices[1].y * scale,
        ])

        // RB
        clippingPolygon.push([
          left + clippingArea.vertices[2].x * scale + clipOffsetX,
          top + clippingArea.vertices[2].y * scale,
        ])

        // LB
        clippingPolygon.push([
          left,
          top + clippingArea.vertices[3].y * scale,
        ])
      } else if (this.pagePositioning === PagePostioning.LeftPage) {
        clipOffsetX = pageDrawSize.width

        // LT
        clippingPolygon.push([
          left + clippingArea.vertices[0].x * scale,
          top + clippingArea.vertices[0].y * scale,
        ])

        // RT
        clippingPolygon.push([
          left + clipOffsetX,
          top + clippingArea.vertices[1].y * scale,
        ])

        // RB
        clippingPolygon.push([
          left + clipOffsetX,
          top + clippingArea.vertices[2].y * scale,
        ])

        // LB
        clippingPolygon.push([
          left + clippingArea.vertices[3].x * scale,
          top + clippingArea.vertices[3].y * scale,
        ])
      } else {
        // LT
        clippingPolygon.push([
          left + clippingArea.vertices[0].x * scale,
          top + clippingArea.vertices[0].y * scale,
        ])

        // RT
        clippingPolygon.push([
          left + clippingArea.vertices[1].x * scale,
          top + clippingArea.vertices[1].y * scale,
        ])

        // RB
        clippingPolygon.push([
          left + clippingArea.vertices[2].x * scale,
          top + clippingArea.vertices[2].y * scale,
        ])

        // LB
        clippingPolygon.push([
          left + clippingArea.vertices[3].x * scale,
          top + clippingArea.vertices[3].y * scale,
        ])
      }
    }

    // 印刷範囲切り抜きは回転後にclipする必要がある=回転に影響されない
    // 余白自動削除は回転前にclipする必要がある=回転に影響する（Clip範囲も回転する）
    //
    // croppingPolygon の座標は回転後の逆行列を掛けておく必要がある
    if (!rotMat.isIdentity && croppingPolygon) {
      const iRotMat = rotMat.inverse()
      for (let i = 0; i < croppingPolygon.length; ++i) {
        const p = transform2DArray(croppingPolygon[i], iRotMat)
        croppingPolygon[i][0] = p[0]
        croppingPolygon[i][1] = p[1]
      }
    }

    if (clippingPolygon) { // 余白自動削除
      bbContext.beginPath()
      bbContext.moveTo(clippingPolygon[0][0], clippingPolygon[0][1])
      for (let i = 1; i < clippingPolygon.length; ++i) {
        bbContext.lineTo(clippingPolygon[i][0], clippingPolygon[i][1])
      }
      bbContext.closePath()
      bbContext.clip()
      // bbContext.stroke()
    } else if (croppingPolygon) { // 印刷範囲切り抜き
      bbContext.beginPath()
      bbContext.lineTo(croppingPolygon[0][0], croppingPolygon[0][1])
      for (let i = 1; i < croppingPolygon.length; ++i) {
        bbContext.lineTo(croppingPolygon[i][0], croppingPolygon[i][1])
      }
      bbContext.closePath()
      bbContext.clip()
    }

    let drawnCount = 0
    let needRetry = false

    // Canvasの外接円
    const canvasLongerEdge = (originalCanvasSize.width > originalCanvasSize.height ? originalCanvasSize.width : originalCanvasSize.height)
    const canvasCircle = new Circle()
    canvasCircle.center = new Vec2(originalCanvasSize.width * 0.5, originalCanvasSize.height * 0.5)
    canvasCircle.radius = canvasLongerEdge * Math.SQRT1_2

    let blit = true
    for (let i = 0; i < tileCount; ++i) {
      const tX = i % ~~levelInfo.tileX
      const tY = ~~Math.floor(i / levelInfo.tileX)

      const tile = this.komaElement_.getTileDrawer(level, i)
      if (!tile) continue

      const tileSize = levelInfo.tileSize

      if (tX === 0) {
        destOffsetX = 0
      }

      // このタイルがページ境界をまたいでいるか否か

      const aTileRange = new Vec2(1 / levelInfo.tileX, 1 / levelInfo.tileY)
      // このタイルの画像内位置割合
      const tileRangeInKoma = new RectF()
      tileRangeInKoma.left = aTileRange.x * tX
      tileRangeInKoma.right = tileRangeInKoma.left + aTileRange.x
      tileRangeInKoma.top = aTileRange.y * tY
      tileRangeInKoma.bottom = tileRangeInKoma.top + aTileRange.y

      const tileUsingArea = new RectF(0, 0, 1, 1)
      const drawTilePortion = new RectI(0, 0, tileSize, tileSize)

      // 描画範囲にない場合
      if (tileRangeInKoma.right < adjustedCroppingArea.left) {
        continue
      }
      if (adjustedCroppingArea.right < tileRangeInKoma.left) {
        continue
      }
      if (tileRangeInKoma.bottom < adjustedCroppingArea.top) {
        continue
      }
      if (adjustedCroppingArea.bottom < tileRangeInKoma.top) {
        continue
      }
      // ページ境界にまたがっている場合
      if (tileRangeInKoma.left <= adjustedCroppingArea.left) {
        // 左側の不要領域を削除する
        // 余白を含む画像全体に対する割合
        tileUsingArea.left = adjustedCroppingArea.left - tileRangeInKoma.left
        // このタイルに対する割合に直す
        tileUsingArea.left *= levelInfo.tileX
        if (tileUsingArea.left < 0) {
          continue
        } else if (tileUsingArea.left > 1.0) {
          continue
        }
      }
      // 右側のタイル余白を削除する
      if (adjustedCroppingArea.right <= tileRangeInKoma.right) {
        // 余白の不要領域を削除する
        tileUsingArea.right =
          tileRangeInKoma.right - adjustedCroppingArea.right
        // このタイルに対する割合に直す
        tileUsingArea.right *= levelInfo.tileX
        tileUsingArea.right = 1.0 - tileUsingArea.right
        if (tileUsingArea.right < 0) {
          continue
        } else if (tileUsingArea.right > 1.0) {
          continue
        }
      }

      drawTilePortion.left = Math.ceil(tileSize * tileUsingArea.left)
      drawTilePortion.top = Math.ceil(tileSize * tileUsingArea.top)
      drawTilePortion.right = Math.floor(tileSize * tileUsingArea.right)
      drawTilePortion.bottom = Math.floor(tileSize * tileUsingArea.bottom)

      // このタイルが描画されるcanvas上の座標
      const letfPxOnCanvas = left + destOffsetX
      const topPxOnCanvas = top + destOffsetY[tY]
      const widthPxOnCanvas = drawTilePortion.width * ajustedScale
      const heightPxOnCanvas = drawTilePortion.height * ajustedScale

      let isOutside = false
      // タイルがダウンロード完了していない場合
      switch (tile.dlProgress) {
        case DLProgress.Failed:
          blit = false
          isOutside = true
          needRetry = false
          break
        case DLProgress.InProgress:
          // DL完了時に再描画されるのでここでは何もしない
          blit = false
          isOutside = true
          needRetry = true
          break
        case DLProgress.NotStarted:
          // DL完了時に再描画する処理を登録する
          tile.loadTile(level !== (this.komaElement_.levelCount - 1), this.koma?.blockInfo).then(() => {
            if (delayDrawFunc) { delayDrawFunc() }
          })
          blit = false
          isOutside = true
          needRetry = true
          break
      }

      if (isOutside) {
        destOffsetX += drawTilePortion.width * ajustedScale - Math.max(0, 1.0 - scale)
        destOffsetY[tY + 1] =
          destOffsetY[tY] +
          drawTilePortion.top +
          drawTilePortion.height * ajustedScale
        continue
      }

      // タイルがcanvasの描画範囲内に存在するか否か

      isOutside = false

      // 横着せずに行列変換する
      const matrix = bbContext.getTransform()

      // Canvasの外接円と座標返還後のタイルの外接円との交差を求める
      // 実装難易度は高くないが精度は下がる

      // タイルの外接円
      // タイルは正方形
      const tileCenterPos = new Vec2()
      const longerTileEdgeLength = widthPxOnCanvas > heightPxOnCanvas ? widthPxOnCanvas : heightPxOnCanvas
      tileCenterPos.x = letfPxOnCanvas + longerTileEdgeLength * 0.5
      tileCenterPos.y = topPxOnCanvas + longerTileEdgeLength * 0.5
      const tileCircle = new Circle()
      tileCircle.center = transform2D(tileCenterPos, matrix)
      tileCircle.radius = longerTileEdgeLength * Math.SQRT1_2

      // タイル円とcanvas円の交差判定
      const distC2T = Vec2.distance(canvasCircle.center, tileCircle.center)
      if (distC2T > (canvasCircle.radius + tileCircle.radius)) {
        isOutside = true
      }

      destOffsetX += drawTilePortion.width * ajustedScale
      destOffsetY[tY + 1] =
        destOffsetY[tY] +
        drawTilePortion.top +
        drawTilePortion.height * ajustedScale

      if (isOutside) { continue }

      // タイルのダウンロードが完了している場合
      tile.drawPortion(
        bbContext,
        drawTilePortion.left,
        drawTilePortion.top,
        drawTilePortion.width,
        drawTilePortion.height,
        letfPxOnCanvas,
        topPxOnCanvas,
        widthPxOnCanvas,
        drawBG
      )

      drawnCount++
    }

    /* {
      if(croppingPolygon){
        bbContext.strokeStyle = "red"
        bbContext.lineWidth = 3
        bbContext.strokeRect(croppingPolygon[0][0], croppingPolygon[0][1], croppingPolygon[1][0]-croppingPolygon[0][0], croppingPolygon[2][1]-croppingPolygon[0][1])
      }
    } */

    // Canvasの回転を戻す
    bbContext.setTransform()

    // メインCanvasへ転送する
    if (!blitPerfect || blit) {
      context.imageSmoothingEnabled = true
      context.drawImage(bbCanvas, 0, 0)

      bbContext.restore()

      if (drawnCount > 0) {
        if (needRetry) {
          return DrawResult.Retry
        } else {
          return DrawResult.Successed
        }
      } else {
        if (needRetry) {
          return DrawResult.Retry
        } else {
          return DrawResult.Successed
        }
      }
    }

    bbContext.restore()
    if (needRetry) {
      return DrawResult.Retry
    }
    return DrawResult.Failed
  }

  /**
   * 現在のページ描画画像解像度から描画に最適な解像度レベルを算出する
   * @param targetPageSize 描画される予定のページ画像のサイズ
   * @returns
   */
  public calcDecentLevel (targetPageSize: PhysicalSize): number {
    if (!this.komaElement_) return 0

    let level = this.komaElement_.levelCount - 1

    for (let i = 0; i < this.komaElement_.levelCount; ++i) {
      const komaLevel = this.komaElement_.getKomaLevel(i)
      if (!komaLevel) { continue }

      if (komaLevel.originWidth <= targetPageSize.width || komaLevel.originHeight <= targetPageSize.height) {
        level = i - 1
        break
      }
    }

    if (level < 0) { level = 0 }

    return level
  }
}

export { PageElement }
