
import { defineComponent, ref } from 'vue'
import { StyleName, styleNames } from '../../data/def'

export default defineComponent({
  name: 'AppInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    list: {
      type: String,
      required: false,
    },
    /** CypressでID指定するため */
    inputId: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue', 'remove'],
  setup (props, context) {
    const input = ref()
    const focus = () => {
      input.value.focus()
    }
    const blur = () => {
      input.value.blur()
    }

    /**
     * changeは以下のタイミングで発生
     * - フォーカスを外す
     * - リストから選択する
     */
    const change = () => {
      if (props.type !== 'number') return
      /* 最小値より下回る場合は、最小値に置き換え */
      if (props.min && props.min > input.value.value) {
        input.value.value = props.min
      }
      /* 最大値より上回る場合は、最大値に置き換え */
      if (props.max && props.max < input.value.value) {
        input.value.value = props.max
      }
    }

    return {
      input,
      focus,
      blur,
      change,
      keydownDelete: () => {
        if (!props.modelValue) {
          context.emit('remove')
        }
      },
    }
  },
})
