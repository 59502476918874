
import { defineComponent, reactive } from 'vue'
import CloserController from '../../molecules/CloserController.vue'
import { useStore } from 'vuex'
import { Content } from '@/data/@types/Content'
import { useRoute, useRouter } from 'vue-router'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { useRouterExtention } from '@/helpers/hooks/useRouterExtention'

export default defineComponent({
  name: 'DirectoryIndexTree',
  components: {
    CloserController,
  },
  props: {
    index: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: 'li',
    },
    n: {
      type: Number,
      default: 1,
    },
    hasDirectory: {
      type: Boolean,
      default: false,
    },
    pid: {
      type: String,
      required: true,
    },
    contentsBundle: {
      type: Object,
      required: true,
    },
    contentsBundleId: {
      type: Number,
      required: true,
    },
    currentFileDirectoryId: {
      type: String,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const route = useRoute()
    const router = useRouter()
    const routerExtention = useRouterExtention(route, router)
    const store = useStore()
    const state = reactive<{ show: boolean }>({
      show: true,
    })

    // ファイルビューアで特定のディレクトリを表示させる
    const changeDirectory = () => {
      if (Number(route.params.bundleIndex) !== props.contentsBundleId + 1) {
        routerExtention.replaceOrPush('/' + makeUpdatedPidString(props.pid) + '/' + (props.contentsBundleId + 1))
        updateViewer()
        updateBundle()
        updateContent()
      }
      store.commit('changeFileViewerDirectory', props.index.id)
    }

    const getContentIndex = () => {
      const contents: Array<Content> = props.contentsBundle?.contents || []
      return contents.findIndex(content => content.id === props.index.id)
    }

    const updateContent = () => {
      store.commit('updateCurrentContentIndex', {
        currentContentIndex: getContentIndex(),
      })
    }

    const updateBundle = () => {
      store.commit('updateCurrentBundleIndex', {
        currentBundleIndex: props.contentsBundleId,
      })
    }

    const updateViewer = () => {
      context.emit('updateViewer', props.contentsBundleId)
    }

    const changeSelectContent = (path: string) => {
      routerExtention.replaceOrPush(path)
      updateViewer()
      updateBundle()
      updateContent()
    }

    const generatePath = () =>
      '/' + makeUpdatedPidString(props.pid) + '/' + (props.contentsBundleId + 1) + '/' + (getContentIndex() + 1)

    return {
      state,
      changeDirectory,
      updateViewer,
      changeSelectContent,
      generatePath,
    }
  },
})
