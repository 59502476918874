import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f65c524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container has-text-normal has-text-contents-normal has-background-page" }
const _hoisted_2 = {
  id: "announcer",
  "aria-live": "assertive",
  class: "is-sr-only"
}
const _hoisted_3 = { class: "app-contents" }
const _hoisted_4 = { "aria-live": "assertive" }
const _hoisted_5 = { "aria-live": "assertive" }
const _hoisted_6 = { class: "modal-notification--context" }
const _hoisted_7 = { class: "modal-notification--footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncTheDmsHeader = _resolveComponent("AsyncTheDmsHeader")!
  const _component_TheGlobalHeader = _resolveComponent("TheGlobalHeader")!
  const _component_TheLoginForm = _resolveComponent("TheLoginForm")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!
  const _component_TheNotificationArea = _resolveComponent("TheNotificationArea")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_SessionExpiredModalWindow = _resolveComponent("SessionExpiredModalWindow")!
  const _component_TheNotificationBottomArea = _resolveComponent("TheNotificationBottomArea")!
  const _component_TheKnFooter = _resolveComponent("TheKnFooter")!
  const _component_TheGlobalFooter = _resolveComponent("TheGlobalFooter")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TheGlobalPrivacyNotification = _resolveComponent("TheGlobalPrivacyNotification")!
  const _component_DmsLoading = _resolveComponent("DmsLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.pageTitle), 1),
      (_ctx.isDms)
        ? (_openBlock(), _createBlock(_component_AsyncTheDmsHeader, { key: 0 }))
        : (_openBlock(), _createBlock(_component_TheGlobalHeader, {
            key: 1,
            isRekion: _ctx.isRekion,
            isKn: _ctx.isKn,
            headerType: _ctx.headerType
          }, null, 8, ["isRekion", "isKn", "headerType"])),
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          (_ctx.$store.getters.showLoginModal)
            ? (_openBlock(), _createBlock(_component_ModalWindow, {
                key: 0,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('SET_SHOW_LOGIN_MODAL', false))),
                heading: _ctx.$t('label.login'),
                class: "the-global-header-login-window"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TheLoginForm, {
                    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('SET_SHOW_LOGIN_MODAL', false)))
                  })
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TheNotificationArea, {
            sticky: true,
            messages: _ctx.notificationMessages,
            onRemove: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeNotificationMessage($event)))
          }, null, 8, ["messages"]),
          _createVNode(_component_TheNotificationArea, {
            sticky: true,
            messages: _ctx.downloadNotificationToasts,
            onResetDownload: _ctx.removeDownloadNotificationToast,
            onRetryDownload: _ctx.retryDownload,
            onCancelDownload: _ctx.cancelDownload
          }, null, 8, ["messages", "onResetDownload", "onRetryDownload", "onCancelDownload"])
        ]),
        (_ctx.isMasterFetched || _ctx.isTopPage)
          ? (_openBlock(), _createBlock(_component_RouterView, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_SessionExpiredModalWindow),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_TheNotificationBottomArea, {
            messages: _ctx.notificationToasts,
            onRemove: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteNotificationToast($event)))
          }, null, 8, ["messages"])
        ])
      ]),
      (_ctx.isKn)
        ? (_openBlock(), _createBlock(_component_TheKnFooter, { key: 2 }))
        : (_openBlock(), _createBlock(_component_TheGlobalFooter, { key: 3 })),
      (_ctx.notificationNotLoggedIn)
        ? (_openBlock(), _createBlock(_component_ModalWindow, {
            key: 4,
            onClose: _ctx.onCloseNotificationNotLoggedIn,
            class: "window-modal modal-notification"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('mycollection.pleaseLogin')), 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AppButton, {
                  styled: "is-accent modal-notification--button",
                  onClick: _ctx.onCloseNotificationNotLoggedIn,
                  onKeydown: _withKeys(_withModifiers(_ctx.onCloseNotificationNotLoggedIn, ["prevent"]), ["space","enter"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('mycollection.OK')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "onKeydown"])
              ])
            ]),
            _: 1
          }, 8, ["onClose"]))
        : _createCommentVNode("", true),
      _createVNode(_component_TheGlobalPrivacyNotification)
    ]),
    _createVNode(_component_DmsLoading)
  ], 64))
}