
import { defineComponent } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'

export default defineComponent({
  components: {
    AppButton,
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    abstract: {
      type: String,
      required: false,
    },
  },
})
