import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46930f5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "addon-view-processing-others"
}
const _hoisted_2 = { class: "level is-mobile" }
const _hoisted_3 = { class: "level is-mobile" }
const _hoisted_4 = { class: "has-text-table-cell-title" }
const _hoisted_5 = { class: "image-viewer-controller-cover-page" }
const _hoisted_6 = { class: "level-left is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToggleSwitch = _resolveComponent("AppToggleSwitch")!

  return (_ctx.isTwoInOneMode)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('rightPane.other')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AppToggleSwitch, {
              modelValue: _ctx.addCoverPageFlag,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addCoverPageFlag) = $event)),
              id: "image-viewer-controller-cover-page-toggle",
              "aria-labelledby": "image-viewer-controller-cover-page-toggle"
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addCoverPageFlag = !_ctx.addCoverPageFlag)),
              onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.addCoverPageFlag = !_ctx.addCoverPageFlag), ["prevent"]), ["space","enter"])),
              tabindex: "-1",
              role: "button",
              id: "image-viewer-controller-cover-page-label"
            }, _toDisplayString(_ctx.$t('rightPane.twoInOneCover')), 33)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}